<template>
  <div class="input-group" :class="{'input-group-invalid': isInvalid, 'input-group-disabled': isDisabled}">
    <label>
      <span class="label" :class="{'required': isRequired && !woLabel}"><slot>{{ label }}</slot></span>
      <span class="btn-box">
        <button
            type="button"
            class="btn"
            :class="inputValue === true ? 't-on' : 't-off'"
            @click="() => this.setValue(true)"
            :disabled="isDisabled"
        >Online (Participação no evento de forma 100% digital, transmitido por meio de plataforma exclusiva. A participação é gratuita.)</button>
        <button
            type="button"
            class="btn "
            :class="inputValue === false ? 't-on' : 't-off'"
            @click="() => this.setValue(false)"
            :disabled="true"
        >Participação no Congresso Potencialize de forma presencial no Rio de Janeiro e acesso online as Cerimônias: Destaques de Venda e Galo de Ouro. Valor do ingresso: R$200 <span class="offline">(ENCERRADAS)</span></button>
      </span>
    </label>
  </div>
</template>

<script>
import {defaultProps} from "./defaultProps";

export default {
  name: "i-online-presencial",
  props: {
    ...defaultProps,
    woLabel: Boolean,
  },
  data: () => ({
    inputValue: null,
    isInvalid: false,
    hasTouched: false,
  }),
  methods: {
    inputHandle() {
      this.hasTouched = true;
      this.$emit('input', this.inputValue);
    },
    validate() {
      this.isInvalid = (this.isRequired && this.inputValue === null);
    },
    clean() {
      this.inputValue = null;
      this.isInvalid = false;
      this.hasTouched = false;
    },
    setValue(value) {
      this.inputValue = value;
      this.validate();
      this.inputHandle();
    },
    isValid() {
      this.validate();
      return !this.isInvalid;
    },
  },
  watch: {
    value(v) {
      this.setValue(v);
    },
  },
  mounted() {
    this.inputValue = this.value;
  },
}
</script>

<style scoped lang="scss">
@import "../../styles/color";

.btn-box {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 1rem;

  .btn {
    flex-grow: 1;
  }
}

.input-group-invalid {
  color: $red2;
}


.btn {
  &.t-on {
    --cor1: #4BC49061;
    --cor2: #4BC4908A;
    --cor3: #151212;
  }

  &.t-off {
    --cor1: transparent;
    --cor2: #D7D7D8;
    --cor3: #4F4F4FB8;
  }

  background: var(--cor1);
  border: 1.5px solid var(--cor2);
  color: var(--cor3);
}
.offline {
  color: rgb(204, 1, 1);
  font-weight: bold;
}
</style>
