export const FORM_1 = Symbol('form1');
export const FORM_2 = Symbol('form2');
export const FORM_3 = Symbol('form3');
export const FORM_4 = Symbol('form4');
export const FORM_5 = Symbol('form5');
// export const FORM_6 = Symbol('form6');
// export const FORM_7 = Symbol('form7');
// export const FORM_8 = Symbol('form8');
// export const FORM_9 = Symbol('form9');
// export const FORM_10 = Symbol('form10');
// export const FORM_11 = Symbol('form11');
// export const FORM_12 = Symbol('form12');
// export const FORM_13 = Symbol('form13');

export const TYPES_FORM = {
    FORM_1,
    FORM_2,
    FORM_3,
    FORM_4,
    FORM_5,
    // FORM_6,
    // FORM_7,
    // FORM_8,
    // FORM_9,
    // FORM_10,
    // FORM_11,
    // FORM_12,
    // FORM_13,
};
