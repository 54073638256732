async function buscaViaCEP(cep) {
    const cepBusca = (cep + '').replace(/[^\d]/g, '').slice(0, 8);
    const resp = await fetch(`https://viacep.com.br/ws/${cepBusca}/json/`);
    if (!resp.ok) {
        throw {cod: '27GI4NHV'};
    }
    const respBody = await resp.json();

    if (respBody.erro) {
        throw {cod: 'ZJN554BB'};
    }

    return {
        state: respBody.uf,
        city: respBody.localidade,
        address: `${[respBody.logradouro, respBody.complemento].filter(Boolean).join(', ')} - ${respBody.bairro}`,
    };
}

export const consultaCEP = (data) => buscaViaCEP(data);
