<template>
  <div>
    <form @submit.prevent="() => dispatchSubmit()">
      <div class="row">
        <IText
            is-disabled
            v-model="formData.name" ref="name" class="col-lg" label="Name"/>
        <IText
          is-disabled
          v-model="formData.last_name" ref="last_name" class="col-lg" label="Last Name"/>
        <IText
            :is-disabled="formIsDisabled"
            v-model="formData.badge_name" ref="badge_name" class="col-lg" label="Name and Surname for badge"/>
      </div>
      <div class="row">
        <ITelCcE
            :is-disabled="formIsDisabled"
            v-model="formData.phone" ref="phone" class="col-lg-4" label="Phone number" :is-required="false"
            @country-code="updateCoutryCode"
        />
        <IEmail
            is-disabled
            v-model="formData.email" ref="email" class="col-lg" label="E-mail"/>
      </div>
      <div class="row">
        <IGenderEN
          :is-disabled="formIsDisabled"
          v-model="formData.gender" ref="gender" class="col-lg-3" label="Gender"/>
        <ICountryEN
          :is-disabled="formIsDisabled"
          v-model="formData.nationality" ref="nationality" class="col-lg" label="Nationality"/>
      </div>
      <div class="row">
        <IText
          :is-disabled="formIsDisabled"
          v-model="formData.passport_doc" ref="passport_doc" class="col-lg-3" label="Passport number"/>
        <IDateEN
          :is-disabled="formIsDisabled"
          v-model="formData.doc_emission" ref="doc_emission" class="col-lg-3" label="Date of issue" placeholder="DD/MM/YYYY"/>
        <IDateEN
            :is-disabled="formIsDisabled"
            v-model="formData.doc_validity" ref="doc_validity" class="col-lg-3" label="Expiration Date" placeholder="DD/MM/YYYY" :validate-current-date="true"/>
        <IDateEN
          :is-disabled="formIsDisabled"
          v-model="formData.birth_date" ref="birth_date" class="col-lg-3" label="Birth date"
          placeholder="DD/MM/YYYY" :min-age="18"
        />
      </div>
      <div class="row">
        <ICountryEN
          :is-disabled="formIsDisabled"
          v-model="formData.doc_country" ref="doc_country" class="col-lg" label="Country of issuing the document"/>
        <IText
          :is-disabled="formIsDisabled"
          v-model="formData.birth_city" ref="birth_city" class="col-lg" label="City of Birth"/>
      </div>

      <div class="row">
        <IText
            :is-disabled="formIsDisabled"
            v-model="formData.zip_code" ref="zip_code" class="col-lg-3" label="Zip Code"
        />
        <IText
          :is-disabled="formIsDisabled"
          v-model="formData.address" ref="address" class="col-lg" label="Full address"/>
        <IText
            :is-disabled="formIsDisabled"
            v-model="formData.city" ref="city" class="col-lg-4" label="City of residence"/>
      </div>
      <div class="row">
        <IText
          :is-disabled="formIsDisabled"
          v-model="formData.state" ref="state" class="col-lg" label="State"/>
        <ICountryEN
          :is-disabled="formIsDisabled"
          v-model="formData.country" ref="country" class="col-lg" label="Country"/>
      </div>
      <div class="row">
        <IConfirm
            :is-disabled="formIsDisabled"
            v-model="formData.has_food_restriction" ref="has_food_restriction" class="col-lg-12"
            label="Restrictions on medication, food, substances or something we need to be aware of:"/>
        <IText v-show="formData.has_food_restriction"
               :is-disabled="formIsDisabled"
               v-model="formData.food_restriction" ref="food_restriction"
               class="col-lg" label="Which your restrictions"/>
      </div>

      <div class="flex" style="display: flex;align-items: center;">
        <div class="c-s text-label">CLOTHING</div>
          <img src="@/assets/330426.png" style="margin: 20px 0 0 10px" width="30" height="30">
      </div>
      <div class="row">
        <ITshirtEN
          :is-disabled="formIsDisabled"
          v-model="formData.polo_tshirt" ref="polo_tshirt" class="col-lg" label="Polo t-shirt size"/>
        <IShoeEN
          :is-disabled="formIsDisabled"
          v-model="formData.shoe_size" ref="shoe_size" class="col-lg" label="Shoes size"/>
      </div>
      <div>
        <div class="row">
          <div class="col-lg-6">
            <div class="text-label">DOCUMENTS</div>
            <IFile
                :is-disabled="formIsDisabled"
                v-model="formData.passport_file" ref="passport_file" class="i-file"
            >
              Attach a copy of your <span class="font-bold">passport <br />(pages 1,2 and 3)</span> here
            </IFile>
          </div>
        </div>
      </div>
      <div class="row c-s">
        <div class="col-lg-7">
          <IConfirm label="Will you need plane tickets to get to the event?" v-model="enableFly" ref="enableFly" :is-disabled="formIsDisabled"/>
        </div>
      </div>
      <div class="row" v-show="enableFly">
        <div class="text-label">AIR TRANSPORT</div>
        <div class="text-label text-label-small">
          Please pay attention to the information provided so that tickets can be issued correctly. Tickets will not be reissued.<br />
        </div>
        <IDateTranspEN
            :is-disabled="formIsDisabled"
            v-model="formData.boarding_date" ref="boarding_date" class="col-lg-4" label="Date of arrival in Rio de Janeiro"  placeholder="DD/MM/YYYY"/>
        <IText
            class="col-lg-8"
            label="Boarding airport"
            :is-disabled="formIsDisabled"
            ref="departure_airport"
            v-model="formData.departure_airport"
        />
        <IDateTranspEN
            :is-disabled="formIsDisabled"
            :returnDate="formData.boarding_date"
            v-model="formData.return_date" ref="return_date" class="col-lg-4" label="Return date to your city of origin"  placeholder="DD/MM/YYYY"/>
        <IText
            class="col-lg-8"
            label="Return airport"
            :is-disabled="formIsDisabled"
            ref="return_airport"
            v-model="formData.return_airport"
        />
      </div>
      <div class="c-s text-label">EMERGENCY CONTACT</div>
      <div class="text-label text-label-small">
        We kindly ask for the details of an emergency contact. <span class="font-bold">Important: this person must not be travelling with you.</span>
      </div>
      <div class="row">
        <IText
            :is-disabled="formIsDisabled"
            v-model="formData.contact.name" ref="contact_name" class="col-lg" label="Full name"/>
        <ITelCcE
            :is-disabled="formIsDisabled"
            v-model="formData.contact.phone" ref="contact_phone" class="col-lg-4" label="Phone number"
            @country-code="updateECoutryCode"
        />
        <IText
            :is-disabled="formIsDisabled"
            v-model="formData.contact.type" ref="contact_type" class="col-lg-3" label="Relation"/>
      </div>
      <div class="row">
        <ITextarea
            :is-disabled="formIsDisabled"
            v-model="formData.obs"
            ref="obs"
            class="col-lg"
            label="Additional comments"
            placeholder="Use this space for special requests, doubts, and others." :is-required="false"
        />
      </div>
      <div class="c-s row" v-if="showPregnancyWarning">
        <div class="col-lg">
          <div class="text-label text-label-small disclaimer-gestante">
            <div class="text-label">IMPORTANT</div>
            Pregnant women who complete 23 weeks of pregnancy by the end of the cruise (January 11, 2025) must present a medical certificate of fitness to travel. For
health and safety reasons, the Carrier may not transport Passengers who are 24 weeks pregnant or more on the date of boarding.
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import IText from "../inputs/i-text";
import IEmail from "../inputs/i-email";
// import ICpf from "../inputs/i-cpf";
import IDateEN from "../inputs/i-dateen";
import IDateTranspEN from "../inputs/i-datetranspen";
import IConfirm from "../inputs/i-confirm";
import IFile from "../inputs/i-file";
import ITextarea from "../inputs/i-textarea";
import ITelCcE from "../inputs/i-tel-cce";
import ITshirtEN from "../inputs/i-shirt-en";
import IShoeEN from "../inputs/i-shoe-en";
import ICountryEN from "../inputs/i-country-en";
import IGenderEN from "../inputs/i-gender-en";
// import UiDisclaim from "../ui-disclaim";

export default {
  name: "form-f5-e1",
  components: {ITelCcE, ITextarea, IConfirm, IDateEN, IDateTranspEN, IEmail, IText, ITshirtEN, IShoeEN, ICountryEN, IGenderEN, IFile},
  data: () => ({
    formData: {
      name: null,
      last_name: null,
      badge_name: null,
      nationality: null,
      gender: null,
      phone: null,
      email: null,
      doc_emission: null,
      doc_validity: null,
      doc_country: null,
      birth_city: null,
      birth_date: null,
      passport_doc: null,
      zip_code: null,
      state: null,
      city: null,
      address: null,
      country: null,
      has_food_restriction: null,
      food_restriction: null,
      passport_file: null,
      polo_tshirt: null,
      shoe_size: null,
      boarding_date: null,
      return_date: null,
      departure_airport: null,
      return_airport: null,
      contact: {
        type: null,
        name: null,
        phone: null,
      },
      obs: null,
    },
    coutryCode: null,
    ecoutryCode: null,
    enableFly: null,
    formIsDisabled: false,
  }),
  methods: {
    getValue() {
      const shallowCopy = {...this.formData, contact: {...this.formData.contact}};
      delete shallowCopy.has_food_restriction;
      if (!this.formData.has_food_restriction) {
        delete shallowCopy.food_restriction;
      }
      shallowCopy.phone = `+${this.coutryCode}${this.formData.phone}`;
      shallowCopy.contact.phone = `+${this.ecoutryCode}${this.formData.contact.phone}`;
      shallowCopy.enableFly = this.enableFly;
      return shallowCopy;
    },
    isValid() {
      const normalRef = this.getNormalRef();

      if (this.formData.has_food_restriction) {
        normalRef.push(this.$refs.food_restriction);
      }

      if (this.enableFly) {
        normalRef.push(
            this.$refs.return_date,
            this.$refs.boarding_date,
            this.$refs.departure_airport,
            this.$refs.return_airport,
        );
      }

      return normalRef.reduce((last, ref) => ref?.isValid() && last, true);

    },
    setNameEmail(name, last_name, email) {
      this.formData.name = name;
      this.formData.last_name = last_name;
      this.formData.email = email;
    },
    setDisabled(isDisabled) {
      this.formIsDisabled = isDisabled;
    },
    getNormalRef() {
      return [
        this.$refs.name,
        this.$refs.last_name,
        this.$refs.badge_name,
        this.$refs.gender,
        this.$refs.nationality,
        this.$refs.phone,
        this.$refs.email,
        this.$refs.birth_date,
        this.$refs.passport_doc,
        this.$refs.doc_emission,
        this.$refs.doc_validity,
        this.$refs.doc_country,
        this.$refs.birth_city,
        this.$refs.zip_code,
        this.$refs.city,
        this.$refs.state,
        this.$refs.address,
        this.$refs.country,
        this.$refs.has_food_restriction,
        this.$refs.passport_file,
        this.$refs.polo_tshirt,
        this.$refs.shoe_size,
        // this.$refs.covid_file,
        this.$refs.contact_name,
        this.$refs.contact_phone,
        this.$refs.contact_type,
        this.$refs.enableFly,
        this.$refs.obs,
      ];
    },
    dispatchSubmit() {
      if (this.isValid()) {
        this.$emit('submit');
      }
    },
    updateCoutryCode(code) {
      this.coutryCode = code
    },
    updateECoutryCode(code) {
      this.ecoutryCode = code
    }
  },
  computed: {
    age() {
      if (!this.formData.birth_date) return null;
      const birthDate = new Date(this.formData.birth_date);
      const ageDiff = Date.now() - birthDate.getTime();
      const ageDate = new Date(ageDiff);
      return Math.abs(ageDate.getUTCFullYear() - 1970);
    },
    showPregnancyWarning() {
      return this.formData.gender === 'Woman' && this.age !== null && this.age < 50;
    }
  },
}
</script>

<style scoped>
.i-file {
  margin-bottom: 1rem;
  max-width: 540px;
}

.c-s {
  margin-top: 2.5rem;
}
.disclaimer-gestante {
  background: #ff00001f;
  padding: 0.75rem;
  border-radius: 5px;
  color: #000;
}
.text-red-alert {
  color: #f00;
  margin-top: 10px;
}
</style>
