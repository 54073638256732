import {TYPES_FORM} from "./types-form";
import fetchLogin from "./fetch/fetchLogin";

function failResp(message) {
    return {
        ok: false,
        message
    };
}

function successResp(typeForm, contactId, name, last_name, email, resp) {
    return {
        ok: true,
        typeForm,
        contactId,
        name,
        last_name,
        email,
        resp,
    };
}

async function apiFetch(email) {
    const formData = new FormData();
    formData.append('email', email);

    const resp = await fetchLogin(formData);
    if (!resp.ok) {
        let errRespBody;
        try {
            errRespBody = await resp.json();
        } catch {
            throw {cod: 'HPV7FLW6'};
        }
        if (resp.status === 401 && errRespBody.message) {
            throw {cod: 'TJTFUAQ3', errMsg: errRespBody.message};
        }
        // return successResp(TYPES_FORM.FORM_11, null, null, null, email, null);
    }
    const respBody = await resp.json();
    const typeForm = {
        1: TYPES_FORM.FORM_1,
        2: TYPES_FORM.FORM_2,
        3: TYPES_FORM.FORM_3,
        4: TYPES_FORM.FORM_4,
        5: TYPES_FORM.FORM_5,
        // 6: TYPES_FORM.FORM_6,
        // 7: TYPES_FORM.FORM_7,
        // 8: TYPES_FORM.FORM_8,
        // 9: TYPES_FORM.FORM_9,
        // 10: TYPES_FORM.FORM_10,
        // 11: TYPES_FORM.FORM_11,
        // 12: TYPES_FORM.FORM_12,
        // 13: TYPES_FORM.FORM_13,
    }[respBody.type];

    if (typeForm) {
        return successResp(typeForm, respBody.id, respBody.name, respBody.last_name, respBody.email, respBody);
    }
    throw 'ERR';
}

export async function identification(email) {
    if (!email) {
        return failResp();
    }

    try {
        return await apiFetch(email);
    } catch ({errMsg}) {
        return failResp(errMsg);
    }
}
