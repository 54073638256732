<template>
  <form @submit.prevent="() => dispatchSubmit()">
    <div>
      <div class="row">
        <div class="col-lg-12">
          <div class="alt-label label required">
            Olá será um prazer para nós receber você no MAGNEXT 2024.
            Para iniciarmos, escolha a modalidade que você participará do nosso evento:
          </div>
        </div>
        <div class="col-lg-12 a-f">
          <IOnlinePresencial
              :is-disabled="formIsDisabled"
              v-model="formData.isOnline"
              wo-label
              @input="v => $emit('change-online-presencial', v)"
          />
        </div>
      </div>
      <div class="row">
        <IText
            :is-disabled="!!originalName || formIsDisabled"
            v-model="formData.name" ref="name" class="col-lg" label="Nome completo"/>
        <IText
            :is-disabled="formIsDisabled"
            v-if="formData.isOnline === false"
            v-model="formData.badge_name" ref="badge_name" class="col-lg" label="Nome para crachá"/>
      </div>
      <div class="row">
        <ITel
            :is-disabled="formIsDisabled"
            v-model="formData.phone" ref="phone" class="col-lg-4" label="Telefone"/>
        <IEmail
            :is-disabled="formIsDisabled"
            v-model="formData.email" ref="email" class="col-lg" label="E-mail"/>
      </div>
      <div class="row">
        <ICpf
            :is-disabled="formIsDisabled"
            v-model="formData.cpf" ref="cpf" class="col-lg-4" label="CPF"/>
        <IDate
            :is-disabled="formIsDisabled"
            v-model="formData.birth_date" ref="birth_date" class="col-lg-3" label="Data de Nascimento"/>
        <IText
            :is-disabled="formIsDisabled"
            v-model="formData.identification_doc" ref="identification_doc" class="col-lg-5" label="Número de Identidade"/>
      </div>
      <div class="row">
        <ICep
            :is-disabled="formIsDisabled"
            v-model="formData.zip_code" ref="zip_code" class="col-lg-2" label="CEP"
            @update-address="(data) => updateAddress(data)"
        />
        <IUf
            :is-disabled="formIsDisabled"
            v-model="formData.state" ref="state" class="custom-col-uf" label="UF"/>
        <IText
            :is-disabled="formIsDisabled"
            v-model="formData.city" ref="city" class="col-lg-4" label="Cidade"/>
        <IText
            :is-disabled="formIsDisabled"
            v-model="formData.address" ref="address" class="col-lg" label="Endereço"/>
      </div>
      <div class="row">
        <IText
            :is-disabled="formIsDisabled"
            v-model="formData.address_complement" ref="address_complement" class="col-lg" label="Complemento"/>
      </div>
      <div class="row">
        <IText
            :is-disabled="formIsDisabled"
            v-model="formData.number_susep" ref="number_susep" class="col-3" label="Número da SUSEP" :is-required="false"/>
        <IText
            :is-disabled="formIsDisabled"
            v-model="formData.corretora" ref="corretora" class="col-lg" label="Corretora" :is-required="false"/>
      </div>
      <div v-if="formData.isOnline === false">
        <div class="row">
          <IConfirm
              :is-disabled="formIsDisabled"
              v-model="formData.has_food_restriction" ref="has_food_restriction" class="col-lg-4"
              label="Restrição alimentar?"/>
          <IText v-show="formData.has_food_restriction"
                :is-disabled="formIsDisabled"
                v-model="formData.food_restriction" ref="food_restriction"
                class="col-lg" label="Especifique sua restrição"/>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <div class="text-label">DOCUMENTOS</div>
            <IFile
                :is-disabled="formIsDisabled"
                v-model="formData.rg_file" ref="rg_file" class="i-file col-lg"
            >
              Anexe aqui uma cópia do seu
              <span class="font-bold">documento de identificação com foto</span>
            </IFile>
          </div>
        </div>

        <div class="c-s text-label">CONTATO DE EMERGÊNCIA</div>
        <div class="text-label text-label-small">
          Forneça a seguir informações do seu contato de emergência.
        </div>
        <div class="row">
          <IText
              :is-disabled="formIsDisabled"
              v-model="formData.contact.name" ref="contact_name" class="col-lg" label="Nome completo"/>
          <ITel
              :is-disabled="formIsDisabled"
              v-model="formData.contact.phone" ref="contact_phone" class="custom-col-tel" label="Telefone"/>
          <IText
              :is-disabled="formIsDisabled"
              v-model="formData.contact.type" ref="contact_type" class="col-lg-3" label="Grau de parentesco"/>
        </div>
        <div class="row">
          <ITextarea
              :is-disabled="formIsDisabled"
              v-model="formData.obs"
              ref="obs"
              class="col-lg"
              label="Comentários adicionais"
              placeholder="Utilize este espaço para pedidos, dúvidas, entre outros." :is-required="false"
          />
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import IText from "../inputs/i-text";
import ITel from "../inputs/i-tel";
import IEmail from "../inputs/i-email";
import ICpf from "../inputs/i-cpf";
import IDate from "../inputs/i-date";
import ICep from "../inputs/i-cep";
import IUf from "../inputs/i-uf";
import IOnlinePresencial from "../inputs/i-online-presencial";
import ITextarea from "../inputs/i-textarea";
import IFile from "../inputs/i-file";
import IConfirm from "../inputs/i-confirm";


export default {
  name: "form-f11",
  components: {IUf, ICep, IDate, ICpf, IEmail, ITel, IText, IOnlinePresencial, ITextarea, IFile, IConfirm},
  data: () => ({
    originalName: null,
    formData: {
      name: null,
      phone: null,
      email: null,
      cpf: null,
      birth_date: null,
      identification_doc: null,
      zip_code: null,
      state: null,
      city: null,
      address: null,
      address_complement: null,
      food_restriction: null,
      has_food_restriction: null,
      number_susep: null,
      corretora: null,
      isOnline: null,
      rg_file: null,
      contact: {
        type: null,
        name: null,
        phone: null,
      },
      obs: null,
    },
    formIsDisabled: false,
  }),
  methods: {
    getValue() {
      let shallowCopy;
      shallowCopy = {...this.formData};

      return shallowCopy;
    },
    isValid() {
      const normalRef = this.getNormalRef();
      if (!this.formData.isOnline) { 
        const normalRefP = [];
        normalRefP.push(
            this.$refs.name,
            this.$refs.badge_name,
            this.$refs.phone,
            this.$refs.email,
            this.$refs.cpf,
            this.$refs.birth_date,
            this.$refs.identification_doc,
            this.$refs.zip_code,
            this.$refs.state,
            this.$refs.city,
            this.$refs.address,
            this.$refs.address_complement,
            this.$refs.number_susep,
            this.$refs.corretora,
            this.$refs.rg_file,
            this.$refs.has_food_restriction,
            this.$refs.contact_name,
            this.$refs.contact_phone,
            this.$refs.contact_type,
            this.$refs.obs,
        );
        if (this.formData.has_food_restriction) {
          normalRefP.push(this.$refs.food_restriction);
        }
        return normalRefP.reduce((last, ref) => ref?.isValid() && last, true);
      }
      return normalRef.reduce((last, ref) => ref?.isValid() && last, true);
    },
    // eslint-disable-next-line no-unused-vars
    setNameEmail(name, email) {
      this.formData.name = name;
      // this.formData.cpf = email;
      this.originalName = name;
    },
    setDisabled(isDisabled) {
      this.formIsDisabled = isDisabled;
    },
    getNormalRef() {
      return [
        this.$refs.name,
        this.$refs.phone,
        this.$refs.email,
        this.$refs.cpf,
        this.$refs.birth_date,
        this.$refs.identification_doc,
        this.$refs.zip_code,
        this.$refs.state,
        this.$refs.city,
        this.$refs.address,
        this.$refs.address_complement,
        this.$refs.number_susep,
        this.$refs.corretora,
      ];
    },
    dispatchSubmit() {
      if (this.isValid()) {
        this.$emit('submit');
      }
    },
    updateAddress(addressData) {
      this.formData.state = addressData.state;
      this.formData.city = addressData.city;
      this.formData.address = addressData.address;
    },
  },
}
</script>

<style scoped>
.i-file {
  margin-bottom: 1rem;
  max-width: 520px;
}

.c-s {
  margin-top: 2.5rem;
}
</style>
