import Vue from 'vue'
import VTooltip from 'v-tooltip'

import App from './App.vue'
import router from './router'
import i18n from './i18n'

import 'v-tooltip/dist/v-tooltip.css'
import 'bootstrap';

Vue.config.productionTip = false
Vue.use(VTooltip);

window.__app_version = '1.0.0-JIMLN5K4';

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
