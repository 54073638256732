<template>
  <div class="input-group-file" :class="{'input-group-invalid': isInvalid, 'input-group-disabled': isDisabled}">
    <div class="label-box">
      <label class="icon-btn">
        <img src="../../assets/icon-upload.png" alt="upload">
        {{ $t('Procurar') }}
        <input
            type="file"
            accept="application/pdf,image/jpeg,image/jpg,image/gif,image/png"
            :disabled="isDisabled"
            :placeholder="placeholder"
            @blur="() => validate()"
            @change="() => inputHandle()"
            ref="input"
        >
      </label>
      <span class="label" :class="{'required': isRequired}"><slot>{{ label }}</slot></span>
    </div>
    <div class="upload-show" v-show="hasUpload">
      <div class="name">{{ uploadName }}</div>
      <button type="button" class="btn t-remover" @click="() => clearUpload()" :disabled="isDisabled">Remover &cross;</button>
    </div>
  </div>
</template>

<script>
import {defaultProps} from "./defaultProps";
import {validFile} from "../../services/validation/validFile";

export default {
  name: "i-file",
  props: {...defaultProps},
  data: () => ({
    inputValue: null,
    isInvalid: false,
    hasTouched: false,
  }),
  methods: {
    inputHandle() {
      const file = this.$refs.input.files[0];
      this.setValue(file);
      this.$emit('input', this.inputValue);
    },
    validate() {
      this.isInvalid = (this.hasTouched && this.inputValue === null)
          || (this.isRequired && !this.inputValue);
    },
    setValue(file) {
      if (file && validFile(file)) {
        this.inputValue = file;
      } else {
        this.inputValue = null;
      }
      this.validate();
    },
    clearUpload() {
      this.$refs.input.value = '';
      this.setValue(null);
    },
    isValid() {
      this.validate();
      return !this.isInvalid;
    },
  },
  computed: {
    hasUpload() {
      return !!this.inputValue;
    },

    uploadName() {
      if (!this.inputValue) {
        return '';
      }
      return this.inputValue.name;
    },
  },
  watch: {
    value(v) {
      this.setValue(v);
    },
  },
  mounted() {
    this.inputValue = this.value;
  },
}
</script>

<style scoped lang="scss">
@import "../../styles/color";

input {
  visibility: hidden;
  flex: 0 0 0;
  width: 0;
  padding: 0;
}

.label-box {
  display: flex;

  .input-group-invalid & {
    color: $red2;
  }

  .icon-btn {
    $width: 78px;
    width: $width;
    flex: 0 0 $width;
    margin-right: 11px;
    border: 1.5px solid $gray2;
    box-sizing: border-box;
    border-radius: 5px;
    font-size: 11px;
    height: 56px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    cursor: pointer;

    .input-group-disabled & {
      cursor: not-allowed;
    }

    img {
      $size: 27px;
      width: $size;
      height: $size;
      object-fit: cover;
    }
  }

  .label {
    flex-grow: 1;

    &.required::after {
      content: " *";
    }
  }
}

.upload-show {
  display: flex;
  font-size: 12px;

  .name {
    color: $green1;
  }

  .btn.t-remover {
    background-color: transparent;
    padding: 0;
    color: $red3;
    margin-left: 1em;
  }
}
</style>
