import {TYPES_FORM} from "./types-form";
import {makeRegisterForm1} from "./makeFormData/form1";
import {makeRegisterForm2} from "./makeFormData/form2";
import {makeRegisterForm3} from "./makeFormData/form3";
import {makeRegisterForm4} from "./makeFormData/form4";
import {makeRegisterForm5} from "./makeFormData/form5";
// import {makeRegisterForm6} from "./makeFormData/form6";
// import {makeRegisterForm7} from "./makeFormData/form7";
// import {makeRegisterForm8} from "./makeFormData/form8";
// import {makeRegisterForm9} from "./makeFormData/form9";
// import {makeRegisterForm10} from "./makeFormData/form10";
// import {makeRegisterForm11} from "./makeFormData/form11";
// import {makeRegisterForm12} from "./makeFormData/form12";
// import {makeRegisterForm13} from "./makeFormData/form13";
import fetchRegister from "./fetch/fetchRegister";
import fetchNewRegister from "./fetch/fetchNewRegister";

async function apiFetch(formType, id, form1Data, form2Data) {
    let formData

    switch (formType) {
        case TYPES_FORM.FORM_1:
            formData = makeRegisterForm1(id, form1Data);
            break;
        case TYPES_FORM.FORM_2:
            formData = makeRegisterForm2(id, form1Data, form2Data);
            break;
        case TYPES_FORM.FORM_3:
            formData = makeRegisterForm3(id, form1Data);
            break;
        case TYPES_FORM.FORM_4:
            formData = makeRegisterForm4(id, form1Data, form2Data);
            break;
        case TYPES_FORM.FORM_5:
            formData = makeRegisterForm5(id, form1Data, form2Data);
            break;
        // case TYPES_FORM.FORM_6:
        //     formData = makeRegisterForm6(id, form1Data, form2Data);
        //     break;
        // case TYPES_FORM.FORM_7:
        //     formData = makeRegisterForm7(id, form1Data, form2Data);
        //     break;
        // case TYPES_FORM.FORM_8:
        //     formData = makeRegisterForm8(id, form1Data, form2Data);
        //     break;
        // case TYPES_FORM.FORM_9:
        //     formData = makeRegisterForm9(id, form1Data);
        //     break;
        // case TYPES_FORM.FORM_10:
        //     formData = makeRegisterForm10(id, form1Data);
        //     break;
        // case TYPES_FORM.FORM_11:
        //     formData = makeRegisterForm11(id, form1Data);
        //     break;
        // case TYPES_FORM.FORM_12:
        //     formData = makeRegisterForm12(id, form1Data);
        //     break;
        // case TYPES_FORM.FORM_13:
        //     formData = makeRegisterForm13(id, form1Data);
        //     break;
    }
    if (!formData) {
        throw {cod: 'VPN7D2VT'};
    }
    let resp;
    if (formType === TYPES_FORM.FORM_11) {
        resp = await fetchNewRegister(formData);
    } else {
        resp = await fetchRegister(formData);
    }


    if (!resp.ok) {
        let errRespBody;
        try {
            errRespBody = await resp.json();
        } catch {
            throw {cod: 'DY6YRVUV', errMsg: errRespBody.message};
        }
        if (errRespBody.errors && errRespBody.errors.email[0] === "O campo email já está sendo utilizado.") {
            throw {cod: 'EUE65DJC', errMsg: "O e-mail cadastrado já encontra-se em uso, confira sua caixa de e-mails"};
        }
        if (errRespBody.errors && errRespBody.errors.cpf[0] === "O campo CPF já está sendo utilizado.") {
            throw {cod: 'EUE65DJC', errMsg: "O CPF cadastrado já encontra-se em uso"};
        }
        throw {cod: 'EUE65DJC', errMsg: errRespBody.message};
    }

    const respBody = await resp.json();


    if (!respBody.data && !respBody.user) {
        throw {cod: '5KJM357A'};
    }
    return respBody.data;
    // return formData.get('name');
}


export async function register(formType, id, form1Data, form2Data) {
    if (!form1Data) {
        throw '5KJM357A';
    }

    return await apiFetch(formType, id, form1Data, form2Data);
}
