import fetchTicket from "./fetch/fetchTicket";

function failResp(message) {
    return {
        ok: false,
        message
    };
}

function successResp(resp) {
    return {
        ok: true,
        resp,
    };
}

async function apiFetch(type) {
    const resp = await fetchTicket(type);
    if (!resp.ok) {
        let errRespBody;
        try {
            errRespBody = await resp.json();
        } catch {
            throw {cod: 'HPV7FLW6'};
        }
        if (resp.status === 401 && errRespBody.message) {
            throw {cod: 'TJTFUAQ3', errMsg: errRespBody.message};
        }
        throw {cod: '12AUVU58'};
    }
    const respBody = await resp.json();

    if (respBody) {
        return successResp(respBody);
    }
    throw 'ERR';
}

export async function getTicket(type) {
    if (!['0', '1'].includes(type)) {
        return failResp();
    }

    try {
        return await apiFetch(type);
    } catch ({errMsg}) {
        return failResp(errMsg);
    }
}
