<template>
  <BaseLayout/>
</template>

<script>
import BaseLayout from "@/components/base-layout";

export default {
  name: 'App',
  components: {
    BaseLayout
  },
}
</script>

<style lang="scss">
@import "styles/reset.css";
@import "styles/global.scss";
</style>
