<template>
  <div class="input-group" :class="{'input-group-invalid': isInvalid, 'input-group-disabled': isDisabled}">
    <label>
      <span class="label" :class="{'required': isRequired}"><slot>{{ label }}</slot></span>
      <textarea
          v-model="inputValue"
          :rows="rows"
          :disabled="isDisabled"
          :placeholder="placeholder"
          @blur="() => validate()"
          @input="() => inputHandle()"
      ></textarea>
    </label>
  </div>
</template>

<script>
import {defaultProps} from "./defaultProps";

export default {
  name: "i-textarea",
  props: {
    ...defaultProps,
    rows: {
      type: Number,
      default: 3
    }
  },
  data: () => ({
    inputValue: null,
    isInvalid: false,
    hasTouched: false,
  }),
  methods: {
    inputHandle() {
      this.hasTouched = true;
      this.$emit('input', this.inputValue);
    },
    validate() {
      this.isInvalid = (this.hasTouched && this.inputValue === null)
          || (this.isRequired && !this.inputValue);
    },
    clean() {
      this.inputValue = null;
      this.isInvalid = false;
      this.hasTouched = false;
    },
    isValid() {
      this.validate();
      return !this.isInvalid;
    },
  },
  watch: {
    value: {
      handler(v) {
        this.inputValue = v;
      },
      immediate: true,
    },
  },
}
</script>

<style scoped>

</style>
