<template>
  <div class="input-group" :class="{'input-group-invalid': isInvalid, 'input-group-disabled': isDisabled}">
    <label>
      <span class="label" :class="{'required': isRequired}"><slot>{{ label }}</slot></span>
      <input
          v-mask="mask"
          type="tel"
          :disabled="isDisabled"
          :placeholder="placeholder"
          v-model="inputValue"
          @blur="() => validate()"
          @input="() => inputHandle()"
      >
    </label>
  </div>
</template>

<script>
import {VueMaskDirective} from 'v-mask'
import {defaultProps} from "./defaultProps";
import {validCEP} from "../../services/validation/validCEP";
import {consultaCEP} from "../../services/api/consultaCEP";

export default {
  name: "i-cep",
  props: {...defaultProps},
  data: () => ({
    mask: [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/],
    inputValue: null,
    isInvalid: false,
    hasTouched: false,
  }),
  directives: {
    mask: VueMaskDirective
  },
  methods: {
    inputHandle() {
      this.hasTouched = true;
      this.$emit('input', this.inputValue);
    },
    validate() {
      this.isInvalid = (!validCEP(this.inputValue) && this.hasTouched)
          || (this.hasTouched && this.inputValue === null)
          || (this.isRequired && !this.inputValue);
    },
    clean() {
      this.inputValue = null;
      this.isInvalid = false;
      this.hasTouched = false;
    },
    isValid() {
      this.validate();
      return !this.isInvalid;
    },
    async dispatchAddress() {
      const addressData = await consultaCEP(this.inputValue);
      console.log(addressData)
      this.$emit('update-address', addressData);
    }
  },
  watch: {
    value: {
      handler(v) {
        this.inputValue = v;
        if (this.inputValue?.length === 9) {
          this.dispatchAddress();
        }
      },
      immediate: true,
    },
  },
}
</script>

<style scoped>

</style>
