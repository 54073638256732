var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isStepCONCLUSAO && !_vm.isStepDECLINADO,
      expression: "!isStepCONCLUSAO && !isStepDECLINADO"
    }],
    staticClass: "container"
  }, [_c('div', {
    staticClass: "nav"
  }, [_c('div', {
    staticClass: "nav-item",
    class: {
      'active': _vm.isStepINITIAL,
      'hidden': !_vm.hasIdentificacao
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('Identificação')) + " "), _c('span', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isStepINITIAL,
      expression: "isStepINITIAL"
    }],
    staticClass: "legenda-l1"
  }, [_vm._v("/ Identification")])]), _c('div', {
    staticClass: "nav-item",
    class: {
      'hidden': _vm.isStepINITIAL || _vm.isStepPAGAMENTO,
      'active': _vm.isStepFORM1
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('Dados pessoais')) + " ")]), _c('div', {
    staticClass: "nav-item",
    class: {
      'hidden': _vm.isStepINITIAL || !_vm.hasFORM2,
      'active': _vm.isStepFORM2
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('Acompanhante')) + " ")]), _c('div', {
    staticClass: "nav-item",
    class: {
      'hidden': _vm.isStepINITIAL || _vm.isStepPAGAMENTO,
      'active': _vm.isStepCONFIRMACAO || _vm.isStepDECLINE
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('Confirmação')) + " ")]), _c('div', {
    staticClass: "nav-item",
    class: {
      'hidden': _vm.isStepINITIAL || !_vm.hasPAGAMENTO,
      'active': _vm.isStepPAGAMENTO
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('Pagamento')) + " ")])]), _c('div', {
    staticClass: "content"
  }, [_c('FIdentificacao', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isStepINITIAL,
      expression: "isStepINITIAL"
    }],
    ref: "formIdentificacao",
    on: {
      "submit": function submit() {
        return _vm.iniciarClickHandle();
      }
    }
  }), _c('div', {
    staticClass: "forms"
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isStepCONFIRMACAO,
      expression: "isStepCONFIRMACAO"
    }],
    staticClass: "confirmacao-header"
  }, [_vm._m(0), _c('div', {
    staticClass: "text"
  }, [_c('div', {
    staticClass: "l1"
  }, [_vm._v(_vm._s(_vm.$t('concl-1')))]), _c('div', {
    staticClass: "l2"
  }, [_c('span', {
    staticClass: "font-bold"
  }, [_vm._v(_vm._s(_vm.$t('ATENÇÃO')) + ":")]), _vm._v(" " + _vm._s(_vm.$t('concl-2')) + " ")])])]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isStepFORM1 || _vm.isStepCONFIRMACAO || _vm.isStepDECLINE,
      expression: "isStepFORM1 || isStepCONFIRMACAO || isStepDECLINE"
    }],
    staticClass: "form1"
  }, [_c('div', {
    staticClass: "disclaim-f2 mb-disc"
  }, [_c('IConfirm', {
    ref: "confirm_cruise",
    staticClass: "col-lg",
    attrs: {
      "is-disabled": !_vm.isStepFORM1,
      "label": _vm.$t('confirmCruise')
    },
    model: {
      value: _vm.confirmCruise,
      callback: function callback($$v) {
        _vm.confirmCruise = $$v;
      },
      expression: "confirmCruise"
    }
  })], 1), _c('span', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.confirmCruise,
      expression: "confirmCruise"
    }]
  }, [_vm.isFormType1 ? _c('FormF1', {
    ref: "formF1",
    on: {
      "submit": function submit() {
        return _vm.proximoClickHandle();
      }
    }
  }) : _vm._e(), _vm.isFormType2 ? _c('FormF2E1', {
    ref: "formF2E1",
    on: {
      "submit": function submit() {
        return _vm.proximoClickHandle();
      }
    }
  }) : _vm._e(), _vm.isFormType3 ? _c('FormF3', {
    ref: "formF3",
    on: {
      "submit": function submit() {
        return _vm.proximoClickHandle();
      }
    }
  }) : _vm._e(), _vm.isFormType4 ? _c('FormF4E1', {
    ref: "formF4E1",
    on: {
      "submit": function submit() {
        return _vm.proximoClickHandle();
      }
    }
  }) : _vm._e(), _vm.isFormType5 ? _c('FormF5E1', {
    ref: "formF5E1",
    on: {
      "submit": function submit() {
        return _vm.proximoClickHandle();
      }
    }
  }) : _vm._e()], 1), _vm.isFormType6 ? _c('FormF6', {
    ref: "formF6",
    on: {
      "submit": function submit() {
        return _vm.proximoClickHandle();
      }
    }
  }) : _vm._e(), _vm.isFormType7 ? _c('FormF7E1', {
    ref: "formF7E1",
    on: {
      "submit": function submit() {
        return _vm.proximoClickHandle();
      }
    }
  }) : _vm._e(), _vm.isFormType8 ? _c('FormF8', {
    ref: "formF8",
    on: {
      "submit": function submit() {
        return _vm.proximoClickHandle();
      }
    }
  }) : _vm._e(), _vm.isFormType9 ? _c('FormF9', {
    ref: "formF9",
    on: {
      "submit": function submit() {
        return _vm.proximoClickHandle();
      },
      "change-online-presencial": function changeOnlinePresencial(v) {
        return _vm.handleChangeOnlinePresencial(v);
      }
    }
  }) : _vm._e(), _vm.isFormType10 ? _c('FormF10', {
    ref: "formF10",
    on: {
      "submit": function submit() {
        return _vm.proximoClickHandle();
      },
      "change-online-presencial": function changeOnlinePresencial(v) {
        return _vm.handleChangeOnlinePresencial(v);
      }
    }
  }) : _vm._e(), _vm.isFormType11 ? _c('FormF11', {
    ref: "formF11",
    on: {
      "submit": function submit() {
        return _vm.proximoClickHandle();
      },
      "change-online-presencial": function changeOnlinePresencial(v) {
        return _vm.handleChangeOnlinePresencial(v);
      }
    }
  }) : _vm._e(), _vm.isFormType12 ? _c('FormF12', {
    ref: "formF12",
    on: {
      "submit": function submit() {
        return _vm.proximoClickHandle();
      }
    }
  }) : _vm._e(), _vm.isFormType13 ? _c('FormF13', {
    ref: "formF13",
    on: {
      "submit": function submit() {
        return _vm.proximoClickHandle();
      }
    }
  }) : _vm._e()], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isStepFORM2 || _vm.isStepCONFIRMACAO,
      expression: "isStepFORM2 || isStepCONFIRMACAO"
    }],
    staticClass: "form2"
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isStepFORM2,
      expression: "isStepFORM2"
    }],
    staticClass: "disclaim-f2"
  }, [_c('div', {
    staticClass: "l1"
  }, [_vm._v(_vm._s(_vm.$t('disc-form2-1')))]), _c('div', {
    staticClass: "l2"
  }, [_vm._v(_vm._s(_vm.$t('disc-form2-2')))])]), _vm.hasFORM2 ? _c('div', [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isStepCONFIRMACAO,
      expression: "isStepCONFIRMACAO"
    }],
    staticClass: "confirm-3"
  }, [_c('div', {
    staticClass: "accordion",
    attrs: {
      "id": "dados_acompanhante_collapse"
    }
  }, [_c('div', {
    staticClass: "accordion-item"
  }, [_c('h2', {
    staticClass: "accordion-header",
    attrs: {
      "id": "headingOne"
    }
  }, [_c('button', {
    staticClass: "accordion-button collapsed",
    attrs: {
      "type": "button",
      "data-bs-toggle": "collapse",
      "data-bs-target": "#collapseItem",
      "aria-expanded": "false",
      "aria-controls": "collapseItem"
    },
    on: {
      "click": function click() {
        return _vm.toogleMostrarDadosAcompanhante();
      }
    }
  }, [_vm._v(" + " + _vm._s(_vm.$t('disc-form2-1')) + " ")])]), _c('div', {
    staticClass: "accordion-collapse collapse",
    attrs: {
      "id": "collapseItem",
      "data-bs-parent": "#dados_acompanhante_collapse"
    }
  })])])]), _c('div', {
    class: {
      'confirm-dados-acompanhante': _vm.mostrarDadosAcompanhante && _vm.isStepCONFIRMACAO
    },
    attrs: {
      "hidden": !_vm.mostrarDadosAcompanhante && _vm.isStepCONFIRMACAO && !_vm.isStepDECLINE
    }
  }, [_vm.isFormType2 ? _c('FormF2E2', {
    ref: "formF2E2",
    on: {
      "submit": function submit() {
        return _vm.proximoClickHandle();
      }
    }
  }) : _vm._e(), _vm.isFormType4 ? _c('FormF4E2', {
    ref: "formF4E2",
    on: {
      "submit": function submit() {
        return _vm.proximoClickHandle();
      }
    }
  }) : _vm._e(), _vm.isFormType5 ? _c('FormF5E2', {
    ref: "formF5E2",
    on: {
      "submit": function submit() {
        return _vm.proximoClickHandle();
      }
    }
  }) : _vm._e(), _vm.isFormType7 ? _c('FormF7E2', {
    ref: "formF7E2",
    on: {
      "submit": function submit() {
        return _vm.proximoClickHandle();
      }
    }
  }) : _vm._e()], 1)]) : _vm._e()]), _vm.isStepPAGAMENTO ? _c('div', {
    staticClass: "formPagamento"
  }, [_c('FormPagamento', {
    ref: "formPagamento",
    on: {
      "submit": function submit() {
        return _vm.pagamentoSubmitClickHandle();
      }
    }
  })], 1) : _vm._e(), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isStepCONFIRMACAO,
      expression: "isStepCONFIRMACAO"
    }],
    staticClass: "confirmacao-footer"
  }, [_vm.isFormType2 || _vm.isFormType3 || _vm.isFormType4 ? _c('i-checkbox', {
    model: {
      value: _vm.conduct,
      callback: function callback($$v) {
        _vm.conduct = $$v;
      },
      expression: "conduct"
    }
  }, [_c('div', {
    staticClass: "text-label-small",
    staticStyle: {
      "font-size": "14px"
    }
  }, [_vm._v(" Li e concordo com:"), _c('br'), _vm._v(" Queremos ter você conosco em nosso evento especial de 190 anos! Será uma honra ter você nesta celebração especial, que reflete nossa jornada e o fortalecimento das parcerias que construímos ao longo desses anos. Nosso objetivo é proporcionar uma experiência única e agradável a todos os convidados, pautada pelo respeito, cordialidade e colaboração mútua. Contamos com sua presença para tornar esse evento ainda mais memorável. Caso precise de qualquer assistência durante o evento, nossa equipe estará à disposição para garantir o seu conforto e bem-estar. Buscamos promover um ambiente de convivência harmoniosa, livre de preconceitos e discriminações, onde todos possam celebrar juntos este marco. Agradecemos e aguardamos com alegria a sua presença. Atenciosamente, Equipe MAG ")])]) : _vm._e(), _c('i-checkbox', {
    model: {
      value: _vm.authCheck.isTermos,
      callback: function callback($$v) {
        _vm.$set(_vm.authCheck, "isTermos", $$v);
      },
      expression: "authCheck.isTermos"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('Eu_li', {
    "artigo": " os "
  })) + " "), _c('a', {
    attrs: {
      "href": _vm.urlTermosInscricao,
      "target": "_blank"
    }
  }, [_vm._v(_vm._s(_vm.$t('termos de inscrição')))]), _vm._v(". ")]), _c('i-checkbox', {
    model: {
      value: _vm.authCheck.isImagem,
      callback: function callback($$v) {
        _vm.$set(_vm.authCheck, "isImagem", $$v);
      },
      expression: "authCheck.isImagem"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('Eu_li', {
    "artigo": " a "
  })) + " "), _c('a', {
    attrs: {
      "href": _vm.urlAuthUso,
      "target": "_blank"
    }
  }, [_vm._v(_vm._s(_vm.$t('autorização de uso de imagem e voz')))]), _vm._v(". ")])], 1)])], 1), _c('div', {
    staticClass: "footer"
  }, [_c('button', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isStepINITIAL && !_vm.isStepFORM1 && !_vm.isStepPAGAMENTO,
      expression: "!isStepINITIAL && !isStepFORM1 && !isStepPAGAMENTO"
    }],
    staticClass: "btn t2",
    on: {
      "click": function click() {
        return _vm.voltarClickHandle();
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('VOLTAR')) + " ")]), _c('VueButtonSpinner', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isStepINITIAL,
      expression: "isStepINITIAL"
    }],
    staticClass: "btn btn-spin t1",
    attrs: {
      "disabled": _vm.isOnIniciar,
      "is-loading": _vm.isOnIniciar
    },
    nativeOn: {
      "click": function click($event) {
        return function () {
          return _vm.iniciarClickHandle();
        }.apply(null, arguments);
      }
    }
  }, [_vm._v("INICIAR ")]), _c('button', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isStepINITIAL && !_vm.isStepCONFIRMACAO && !_vm.isStepPAGAMENTO && !_vm.isStepDECLINE,
      expression: "!isStepINITIAL && !isStepCONFIRMACAO && !isStepPAGAMENTO && !isStepDECLINE"
    }],
    staticClass: "btn t1",
    attrs: {
      "disabled": _vm.isOnIniciar
    },
    on: {
      "click": function click() {
        return _vm.proximoClickHandle();
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('PRÓXIMO')) + " > ")]), _c('VueButtonSpinner', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isStepCONFIRMACAO,
      expression: "isStepCONFIRMACAO"
    }],
    staticClass: "btn btn-spin t3 font-bold",
    attrs: {
      "disabled": _vm.isOnConcluir,
      "is-loading": _vm.isOnConcluir
    },
    nativeOn: {
      "click": function click($event) {
        return function () {
          return _vm.concluirClickHandle();
        }.apply(null, arguments);
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('CONCLUIR INSCRIÇÃO')) + " ")]), _c('VueButtonSpinner', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isStepDECLINE,
      expression: "isStepDECLINE"
    }],
    staticClass: "btn btn-spin t4 font-bold",
    attrs: {
      "disabled": _vm.isOnConcluir,
      "is-loading": _vm.isOnConcluir
    },
    nativeOn: {
      "click": function click($event) {
        return function () {
          return _vm.declineClickHandle();
        }.apply(null, arguments);
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('DECLINAR INSCRIÇÃO')) + " ")])], 1)]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isStepDECLINADO,
      expression: "isStepDECLINADO"
    }]
  }, [_c('div', {
    staticClass: "conclusao-show"
  }, [_c('div', {
    staticClass: "l1"
  }, [_vm._v(" " + _vm._s(_vm.name) + ", " + _vm._s(_vm.$t('decline-1')) + " ")]), _c('div', {
    staticClass: "l2"
  }, [_vm._v(" " + _vm._s(_vm.$t('decline-2')) + " ")]), _c('img', {
    staticClass: "icon",
    attrs: {
      "src": require("../assets/decline.png"),
      "alt": "Declinado"
    }
  }), _c('div', {
    staticClass: "l3"
  }, [_vm._v(" " + _vm._s(_vm.$t('decline-3')) + " "), _c('br')]), _c('div', {
    staticClass: "l3"
  }, [_c('span', {
    staticClass: "font-bold"
  }, [_vm._v(_vm._s(_vm.$t('decline-4')))])])])]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isStepCONCLUSAO,
      expression: "isStepCONCLUSAO"
    }]
  }, [_vm.isCheckouConclusao ? _c('div', {
    staticClass: "conclusao-show"
  }, [_c('div', {
    staticClass: "l1"
  }), _vm._v(" Obrigado " + _vm._s(_vm.name) + ". Estamos processando o seu pagamento. "), _c('div', {
    staticClass: "l2"
  }, [_vm._v(" Iremos notificar via e-mail sobre o andamento dessa transação. ")]), _c('img', {
    staticClass: "icon",
    attrs: {
      "src": require("../assets/icon-confirm.svg"),
      "alt": "Confirmado"
    }
  }), _vm._m(1)]) : _c('div', {
    staticClass: "conclusao-show"
  }, [_c('div', {
    staticClass: "l1"
  }, [_vm._v(" " + _vm._s(_vm.name) + ", " + _vm._s(_vm.$t('success-1')) + " ")]), _c('div', {
    staticClass: "l2"
  }, [_vm._v(" " + _vm._s(_vm.$t('success-2')) + " ")]), _c('img', {
    staticClass: "icon",
    attrs: {
      "src": require("../assets/icon-confirm.svg"),
      "alt": "Confirmado"
    }
  }), _c('div', {
    staticClass: "l3"
  }, [_c('span', {
    staticClass: "font-bold"
  }, [_vm._v(_vm._s(_vm.$t('Importante')) + ":")]), _vm._v(" " + _vm._s(_vm.$t('success-3')) + " ")])])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "icon"
  }, [_c('img', {
    attrs: {
      "src": require("../assets/icon-warning.png"),
      "alt": "atenção"
    }
  })]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "l3"
  }, [_c('span', {
    staticClass: "font-bold"
  }, [_vm._v("Importante:")]), _vm._v(" Fique de olho em seu e-mail, pois ele será o nosso principal canal de comunicação. ")]);
}]

export { render, staticRenderFns }