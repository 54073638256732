<template>
  <div class="input-group" :class="{'input-group-invalid': isInvalid, 'input-group-disabled': isDisabled}">
    <label>
      <span class="label" :class="{'required': isRequired}"><slot>{{ label }}</slot></span>
      <input
          type="text"
          :disabled="isDisabled"
          :placeholder="placeholder"
          v-model="inputValue"
          @blur="() => validate()"
          @input="() => inputHandle()"
          maxlength="2"
          :list="datalistId"
      >
      <datalist :id="datalistId">
        <option value="36"/>
        <option value="37"/>
        <option value="38"/>
        <option value="39"/>
        <option value="40"/>
        <option value="41"/>
        <option value="42"/>
        <option value="43"/>
        <option value="44"/>
        <option value="45"/>
        <option value="46"/>
      </datalist>
    </label>
  </div>
</template>

<script>
import {defaultProps} from "./defaultProps";
import {validShoeEN} from "../../services/validation/validShoeEN";

export default {
  name: "i-shoe-en",
  props: {...defaultProps},
  data: () => ({
    inputValue: null,
    isInvalid: false,
    hasTouched: false,
    datalistId: 'datalist' + Math.random().toString(36).slice(-6),
  }),
  methods: {
    inputHandle() {
      this.hasTouched = true;
      this.$emit('input', this.inputValue);
    },
    validate() {
      this.isInvalid = (!validShoeEN(this.inputValue) && this.hasTouched)
          || (this.hasTouched && this.inputValue === null)
          || (this.isRequired && !this.inputValue);
    },
    clean() {
      this.inputValue = null;
      this.isInvalid = false;
      this.hasTouched = false;
    },
    isValid() {
      this.validate();
      return !this.isInvalid;
    },
  },
  watch: {
    value: {
      handler(v) {
        this.inputValue = v;
      },
      immediate: true,
    },
  },
}
</script>

<style scoped>

</style>
