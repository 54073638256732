var render = function () {
  var _this = this;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "input-group",
    class: {
      'input-group-invalid': _vm.isInvalid,
      'input-group-disabled': _vm.isDisabled
    }
  }, [_c('label', [_c('span', {
    staticClass: "label",
    class: {
      'required': _vm.isRequired && !_vm.woLabel
    }
  }, [_vm._t("default", function () {
    return [_vm._v(_vm._s(_vm.label))];
  })], 2), _c('span', {
    staticClass: "btn-box"
  }, [_c('button', {
    staticClass: "btn",
    class: _vm.inputValue === true ? 't-on' : 't-off',
    attrs: {
      "type": "button",
      "disabled": _vm.isDisabled
    },
    on: {
      "click": function click() {
        return _this.setValue(true);
      }
    }
  }, [_vm._v("Online (Participação no evento de forma 100% digital, transmitido por meio de plataforma exclusiva. A participação é gratuita.)")]), _c('button', {
    staticClass: "btn ",
    class: _vm.inputValue === false ? 't-on' : 't-off',
    attrs: {
      "type": "button",
      "disabled": true
    },
    on: {
      "click": function click() {
        return _this.setValue(false);
      }
    }
  }, [_vm._v("Participação no Congresso Potencialize de forma presencial no Rio de Janeiro e acesso online as Cerimônias: Destaques de Venda e Galo de Ouro. Valor do ingresso: R$200 "), _c('span', {
    staticClass: "offline"
  }, [_vm._v("(ENCERRADAS)")])])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }