var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return function () {
          return _vm.dispatchSubmit();
        }.apply(null, arguments);
      }
    }
  }, [_c('div', [_c('div', {
    staticClass: "row"
  }, [_c('IText', {
    ref: "name",
    staticClass: "col-lg",
    attrs: {
      "is-disabled": "",
      "label": "Nome"
    },
    model: {
      value: _vm.formData.name,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "name", $$v);
      },
      expression: "formData.name"
    }
  }), _c('IText', {
    ref: "last_name",
    staticClass: "col-lg",
    attrs: {
      "is-disabled": "",
      "label": "Último sobrenome"
    },
    model: {
      value: _vm.formData.last_name,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "last_name", $$v);
      },
      expression: "formData.last_name"
    }
  }), _c('IText', {
    ref: "badge_name",
    staticClass: "col-lg",
    attrs: {
      "is-disabled": _vm.formIsDisabled,
      "label": "Nome e Sobrenome para crachá"
    },
    model: {
      value: _vm.formData.badge_name,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "badge_name", $$v);
      },
      expression: "formData.badge_name"
    }
  })], 1), _c('div', {
    staticClass: "row"
  }, [_c('ITelCc', {
    ref: "phone",
    staticClass: "col-lg-4",
    attrs: {
      "is-disabled": _vm.formIsDisabled,
      "placeholder": "Numero de celular",
      "label": "Número de celular"
    },
    on: {
      "country-code": _vm.updateCoutryCode
    },
    model: {
      value: _vm.formData.phone,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "phone", $$v);
      },
      expression: "formData.phone"
    }
  }), _c('IEmail', {
    ref: "email",
    staticClass: "col-lg",
    attrs: {
      "is-disabled": "",
      "label": "E-mail"
    },
    model: {
      value: _vm.formData.email,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "email", $$v);
      },
      expression: "formData.email"
    }
  })], 1), _c('div', {
    staticClass: "row"
  }, [_c('IGender', {
    ref: "gender",
    staticClass: "col-lg-3",
    attrs: {
      "is-disabled": _vm.formIsDisabled,
      "label": "Sexo"
    },
    model: {
      value: _vm.formData.gender,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "gender", $$v);
      },
      expression: "formData.gender"
    }
  }), _c('ICountry', {
    ref: "nationality",
    staticClass: "col-lg-3",
    attrs: {
      "is-disabled": _vm.formIsDisabled,
      "label": "Nacionalidade"
    },
    model: {
      value: _vm.formData.nationality,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "nationality", $$v);
      },
      expression: "formData.nationality"
    }
  }), _c('ICpf', {
    ref: "cpf",
    staticClass: "col-lg-3",
    attrs: {
      "is-disabled": _vm.formIsDisabled,
      "label": "CPF"
    },
    model: {
      value: _vm.formData.cpf,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "cpf", $$v);
      },
      expression: "formData.cpf"
    }
  }), _c('IText', {
    ref: "passport_doc",
    staticClass: "col-lg-3",
    attrs: {
      "label": "Número do passaporte",
      "is-disabled": _vm.formIsDisabled
    },
    model: {
      value: _vm.formData.passport_doc,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "passport_doc", $$v);
      },
      expression: "formData.passport_doc"
    }
  })], 1), _c('div', {
    staticClass: "row"
  }, [_c('IDate', {
    ref: "birth_date",
    staticClass: "col-lg-3",
    attrs: {
      "is-disabled": _vm.formIsDisabled,
      "label": "Data de Nascimento",
      "placeholder": "dd/mm/aaaa",
      "min-age": 18
    },
    model: {
      value: _vm.formData.birth_date,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "birth_date", $$v);
      },
      expression: "formData.birth_date"
    }
  }), _c('IText', {
    ref: "identification_doc",
    staticClass: "col-lg-3",
    attrs: {
      "is-disabled": _vm.formIsDisabled,
      "label": "Número de Identidade"
    },
    model: {
      value: _vm.formData.identification_doc,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "identification_doc", $$v);
      },
      expression: "formData.identification_doc"
    }
  }), _c('IDate', {
    ref: "doc_emission",
    staticClass: "col-lg-3",
    attrs: {
      "is-disabled": _vm.formIsDisabled,
      "label": "Data de emissão",
      "placeholder": "dd/mm/aaaa"
    },
    model: {
      value: _vm.formData.doc_emission,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "doc_emission", $$v);
      },
      expression: "formData.doc_emission"
    }
  }), _c('IDate', {
    ref: "doc_validity",
    staticClass: "col-lg-3",
    attrs: {
      "is-disabled": _vm.formIsDisabled,
      "label": "Data validade",
      "placeholder": "dd/mm/aaaa",
      "validate-current-date": true
    },
    model: {
      value: _vm.formData.doc_validity,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "doc_validity", $$v);
      },
      expression: "formData.doc_validity"
    }
  })], 1), _c('div', {
    staticClass: "row"
  }, [_c('ICountry', {
    ref: "doc_country",
    staticClass: "col-lg",
    attrs: {
      "is-disabled": _vm.formIsDisabled,
      "label": "País de emissão do documento"
    },
    model: {
      value: _vm.formData.doc_country,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "doc_country", $$v);
      },
      expression: "formData.doc_country"
    }
  }), _c('IText', {
    ref: "birth_city",
    staticClass: "col-lg",
    attrs: {
      "is-disabled": _vm.formIsDisabled,
      "label": "Cidade de Nascimento"
    },
    model: {
      value: _vm.formData.birth_city,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "birth_city", $$v);
      },
      expression: "formData.birth_city"
    }
  })], 1), _c('div', {
    staticClass: "row"
  }, [_c('ICep', {
    ref: "zip_code",
    staticClass: "col-lg-2",
    attrs: {
      "is-disabled": _vm.formIsDisabled,
      "label": "CEP"
    },
    on: {
      "update-address": function updateAddress(data) {
        return _vm.updateAddress(data);
      }
    },
    model: {
      value: _vm.formData.zip_code,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "zip_code", $$v);
      },
      expression: "formData.zip_code"
    }
  }), _c('IText', {
    ref: "address",
    staticClass: "col-lg",
    attrs: {
      "is-disabled": _vm.formIsDisabled,
      "label": "Endereço completo"
    },
    model: {
      value: _vm.formData.address,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "address", $$v);
      },
      expression: "formData.address"
    }
  }), _c('IText', {
    ref: "city",
    staticClass: "col-lg-4",
    attrs: {
      "is-disabled": _vm.formIsDisabled,
      "label": "Cidade de residência"
    },
    model: {
      value: _vm.formData.city,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "city", $$v);
      },
      expression: "formData.city"
    }
  }), _c('IUf', {
    ref: "state",
    staticClass: "custom-col-uf",
    attrs: {
      "is-disabled": _vm.formIsDisabled,
      "label": "UF"
    },
    model: {
      value: _vm.formData.state,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "state", $$v);
      },
      expression: "formData.state"
    }
  })], 1), _c('div', {
    staticClass: "row"
  }, [_c('ICountry', {
    ref: "country",
    staticClass: "col-lg",
    attrs: {
      "is-disabled": _vm.formIsDisabled,
      "label": "País"
    },
    model: {
      value: _vm.formData.country,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "country", $$v);
      },
      expression: "formData.country"
    }
  }), _c('IText', {
    ref: "address_complement",
    staticClass: "col-lg",
    attrs: {
      "is-disabled": _vm.formIsDisabled,
      "label": "Complemento"
    },
    model: {
      value: _vm.formData.address_complement,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "address_complement", $$v);
      },
      expression: "formData.address_complement"
    }
  })], 1), _c('div', {
    staticClass: "row"
  }, [_c('IConfirm', {
    ref: "has_food_restriction",
    staticClass: "col-lg-12",
    attrs: {
      "is-disabled": _vm.formIsDisabled,
      "label": "Restrição medicamentosa, alimentar, substâncias ou algo que precisamos ter ciência:"
    },
    model: {
      value: _vm.formData.has_food_restriction,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "has_food_restriction", $$v);
      },
      expression: "formData.has_food_restriction"
    }
  }), _c('IText', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.formData.has_food_restriction,
      expression: "formData.has_food_restriction"
    }],
    ref: "food_restriction",
    staticClass: "col-lg",
    attrs: {
      "is-disabled": _vm.formIsDisabled,
      "label": "Especifique sua restrição"
    },
    model: {
      value: _vm.formData.food_restriction,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "food_restriction", $$v);
      },
      expression: "formData.food_restriction"
    }
  })], 1), _c('div', {
    staticClass: "c-s text-label"
  }, [_vm._v("VESTUÁRIO")]), _c('div', {
    staticClass: "row"
  }, [_c('ITshirt', {
    ref: "polo_tshirt",
    staticClass: "col-lg",
    attrs: {
      "is-disabled": _vm.formIsDisabled,
      "label": "Tamanho de camisa polo"
    },
    model: {
      value: _vm.formData.polo_tshirt,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "polo_tshirt", $$v);
      },
      expression: "formData.polo_tshirt"
    }
  }), _c('IShoe', {
    ref: "shoe_size",
    staticClass: "col-lg",
    attrs: {
      "is-disabled": _vm.formIsDisabled,
      "label": "Número de chinelo"
    },
    model: {
      value: _vm.formData.shoe_size,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "shoe_size", $$v);
      },
      expression: "formData.shoe_size"
    }
  })], 1), _c('div', [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-6"
  }, [_c('div', {
    staticClass: "text-label"
  }, [_vm._v("DOCUMENTOS")]), _c('IFile', {
    ref: "rg_file",
    staticClass: "i-file",
    attrs: {
      "is-disabled": _vm.formIsDisabled
    },
    model: {
      value: _vm.formData.rg_file,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "rg_file", $$v);
      },
      expression: "formData.rg_file"
    }
  }, [_vm._v(" Anexe aqui uma cópia do seu "), _c('span', {
    staticClass: "font-bold"
  }, [_vm._v("documento de identificação com foto"), _c('small', {
    staticStyle: {
      "font-weight": "bold"
    }
  }, [_vm._v("(RG, CNH, OAB ou RNE)")])])]), _c('IFile', {
    ref: "passport_file",
    staticClass: "i-file",
    attrs: {
      "is-disabled": _vm.formIsDisabled
    },
    model: {
      value: _vm.formData.passport_file,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "passport_file", $$v);
      },
      expression: "formData.passport_file"
    }
  }, [_vm._v(" Anexe aqui uma cópia do seu "), _c('span', {
    staticClass: "font-bold"
  }, [_vm._v("passaporte "), _c('br'), _vm._v("(páginas 1,2 e 3)")])])], 1), _c('div', {
    staticClass: "col-lg"
  }, [_c('div', {
    staticClass: "text-label"
  }, [_vm._v("VACINAÇÃO")]), _c('IFile', {
    ref: "covid_file",
    staticClass: "i-file",
    attrs: {
      "is-disabled": _vm.formIsDisabled
    },
    model: {
      value: _vm.formData.covid_file,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "covid_file", $$v);
      },
      expression: "formData.covid_file"
    }
  }, [_c('b', {
    staticClass: "font-bold"
  }, [_vm._v("Certificado Internacional de Vacinação ou Profilaxia (CIVP) para Febre Amarela")])])], 1), _c('div', {
    staticClass: "text-label text-label-small text-red-alert"
  }, [_vm._v("É obrigatório que o passaporte tenha pelo menos seis meses de validade a partir da data de embarque, ou seja, válido até no mínimo julho de 2025.")])])]), _c('div', {
    staticClass: "row c-s"
  }, [_c('div', {
    staticClass: "col-lg-7"
  }, [_c('IConfirm', {
    ref: "enableFly",
    attrs: {
      "label": "Você precisará de passagens aéreas para chegar até o evento no Rio de Janeiro?",
      "is-disabled": _vm.formIsDisabled
    },
    model: {
      value: _vm.enableFly,
      callback: function callback($$v) {
        _vm.enableFly = $$v;
      },
      expression: "enableFly"
    }
  })], 1)]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.enableFly,
      expression: "enableFly"
    }],
    staticClass: "row"
  }, [_c('div', {
    staticClass: "text-label"
  }, [_vm._v("TRANSPORTE AÉREO")]), _vm._m(0), _c('IDateTransp', {
    ref: "boarding_date",
    staticClass: "col-lg-4",
    attrs: {
      "is-disabled": _vm.formIsDisabled,
      "label": "Data de chegada ao Rio de Janeiro",
      "placeholder": "dd/mm/aaaa"
    },
    model: {
      value: _vm.formData.boarding_date,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "boarding_date", $$v);
      },
      expression: "formData.boarding_date"
    }
  }), _c('IText', {
    ref: "departure_airport",
    staticClass: "col-lg-8",
    attrs: {
      "label": "Aeroporto de embarque",
      "is-disabled": _vm.formIsDisabled
    },
    model: {
      value: _vm.formData.departure_airport,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "departure_airport", $$v);
      },
      expression: "formData.departure_airport"
    }
  }), _c('IDateTransp', {
    ref: "return_date",
    staticClass: "col-lg-4",
    attrs: {
      "is-disabled": _vm.formIsDisabled,
      "returnDate": _vm.formData.boarding_date,
      "label": "Data de retorno para sua cidade de origem",
      "placeholder": "dd/mm/aaaa"
    },
    model: {
      value: _vm.formData.return_date,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "return_date", $$v);
      },
      expression: "formData.return_date"
    }
  }), _c('IText', {
    ref: "return_airport",
    staticClass: "col-lg-8",
    attrs: {
      "label": "Aeroporto de retorno para sua cidade",
      "is-disabled": _vm.formIsDisabled
    },
    model: {
      value: _vm.formData.return_airport,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "return_airport", $$v);
      },
      expression: "formData.return_airport"
    }
  })], 1), _c('div', {
    staticClass: "c-s text-label"
  }, [_vm._v("CONTATO DE EMERGÊNCIA")]), _vm._m(1), _c('div', {
    staticClass: "row"
  }, [_c('IText', {
    ref: "contact_name",
    staticClass: "col-lg",
    attrs: {
      "is-disabled": _vm.formIsDisabled,
      "label": "Nome completo"
    },
    model: {
      value: _vm.formData.contact.name,
      callback: function callback($$v) {
        _vm.$set(_vm.formData.contact, "name", $$v);
      },
      expression: "formData.contact.name"
    }
  }), _c('ITelCc', {
    ref: "contact_phone",
    staticClass: "col-lg-4",
    attrs: {
      "is-disabled": _vm.formIsDisabled,
      "placeholder": "Numero de celular",
      "label": "Número de celular"
    },
    on: {
      "country-code": _vm.updateECoutryCode
    },
    model: {
      value: _vm.formData.contact.phone,
      callback: function callback($$v) {
        _vm.$set(_vm.formData.contact, "phone", $$v);
      },
      expression: "formData.contact.phone"
    }
  }), _c('IText', {
    ref: "contact_type",
    staticClass: "col-lg-3",
    attrs: {
      "is-disabled": _vm.formIsDisabled,
      "label": "Grau de parentesco"
    },
    model: {
      value: _vm.formData.contact.type,
      callback: function callback($$v) {
        _vm.$set(_vm.formData.contact, "type", $$v);
      },
      expression: "formData.contact.type"
    }
  })], 1), _c('div', {
    staticClass: "row"
  }, [_c('ITextarea', {
    ref: "obs",
    staticClass: "col-lg",
    attrs: {
      "is-disabled": _vm.formIsDisabled,
      "label": "Comentários adicionais",
      "placeholder": "Utilize este espaço para pedidos, dúvidas, entre outros.",
      "is-required": false
    },
    model: {
      value: _vm.formData.obs,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "obs", $$v);
      },
      expression: "formData.obs"
    }
  })], 1), _vm.showPregnancyWarning ? _c('div', {
    staticClass: "c-s row"
  }, [_vm._m(2)]) : _vm._e()])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-label text-label-small"
  }, [_vm._v(" Fique atento às informações fornecidas para que as passagens possam ser emitidas corretamente. Não serão realizadas reemissões de passagens"), _c('br')]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-label text-label-small"
  }, [_vm._v(" Forneça a seguir informações do seu contato de emergência. "), _c('span', {
    staticClass: "font-bold"
  }, [_vm._v("Importante: esta pessoa não deverá estar viajando com você.")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-lg"
  }, [_c('div', {
    staticClass: "text-label text-label-small disclaimer-gestante"
  }, [_c('div', {
    staticClass: "text-label"
  }, [_vm._v("IMPORTANTE")]), _vm._v(" Gestantes que completarem 23 semanas de gravidez até o término do cruzeiro (11 janeiro de 2025), deverão apresentar atestado médico de aptidão para viajar. Por razões de saúde e segurança, o Transportador não poderá transportar Passageiras completando 24 semanas de gestação ou mais na data do embarque. ")])]);
}]

export { render, staticRenderFns }