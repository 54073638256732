import {notification} from "../notification";

const fileExtensionRedExp = /\.(png|jpe?g|gif|pdf)$/

const MiB = 2 ** 20;

function validarFile(file) {
    if (!(file instanceof File)) {
        return false;
    }
    if (!fileExtensionRedExp.test(file.name)) {
        notification.error('Verifique se o arquivo escolhido é imagem ou PDF');
        console.log({'filename is wrong: ': file.name})
        return false;
    }
    if (file.size > 10 * MiB) {
        notification.error('Verifique se o arquivo escolhido tem no máximo 10 MB');
        return false;
    }
    return true;

}

export const validFile = (data) => validarFile(data);
