<template>
  <form @submit.prevent="() => dispatchSubmit()">
    <div>
      <div class="row">
        <div class="col-lg-12 a-f">
          <div class="principal">
            <div class="infos-ticket">
              <div class="categoria-ticket">
                <img class="ticket-img" src="@/assets/ticket.png" alt="cupom">
                <div class="detalhes-ingresso">
                  <div class="titulo-ingresso">
                    <span>{{ ticket.name }}</span>
                  </div>
                  <div class="valor-ingresso">
                    <span>{{ ticket.amount_ticket | currency }}</span>
                  </div>
                </div>
              </div>
              <div class="processamento">
                <div class="detalhes-processamento">
                  <div class="processado">
                    <span>Processado por</span>
                  </div>
                  <div class="gateway-img">
                    <img class="img-processador" src="@/assets/mag-financas.png" alt="">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="divisor"></div>
      <div class="text-label">Informações do participante</div>
      <div class="text-label text-label-small">
        Confirme os dados do participante abaixo:
      </div>
      <div class="row">
        <IText
            is-disabled
            v-model="inputData.name" ref="name" class="col-lg" label="Nome completo"/>
        <ICpf
            is-disabled
            v-model="inputData.cpf" ref="cpf" class="col-lg-4" label="CPF"/>
      </div>
      <div class="row">
        <ITel
            is-disabled
            v-model="inputData.phone" ref="phone" class="col-lg-4" label="Telefone"/>
        <IEmail
            is-disabled
            v-model="inputData.email" ref="email" class="col-lg" label="E-mail"/>
      </div>

      <div class="cupom-box">
        <button
            type="button"
            class="btn t2 btn-show-cupom"
            @click="() => toogleMostrarCupomForm()"
        >
          <img src="@/assets/voucher-de-desconto.png" alt="desconto">
          Tem um cupom de desconto?
        </button>
        <div :class="{'show-form-cupom': cupomData.showCupom}" class="form-cupom">
          <form name="cupom" @submit.prevent="() => cupomSubmitHandle()">
            <div class="row">
              <IText
                  v-model="cupomData.codText"
                  ref="name"
                  class="col-lg input-cupom"
                  label="Cupom"
                  :is-required="false"
              />
              <div class="col-lg-3 action-cupom">
                <VueButtonSpinner
                    type="submit"
                    class="btn t1 btn-spin"
                    :disabled="cupomData.onRequest"
                    :is-loading="cupomData.onRequest"
                >Aplicar
                </VueButtonSpinner>
                <!--                <button
                                    type="submit"
                                    class="btn t1"
                                >
                                  Aplicar v2
                                </button>-->
              </div>
            </div>
          </form>
        </div>
        <div
            v-show="cupomData.feedback.message"
            class="cupom-alert"
            :class="{'fbc-success': cupomData.feedback.isSuccess, 'fbc-invalid': cupomData.feedback.isInvalid, 'fbc-old': cupomData.feedback.isOld}"
        >
          <span v-show="cupomData.feedback.isSuccess">Cupom aplicado com sucesso!</span>
          <span v-show="cupomData.feedback.isInvalid">Cupom inválido!</span>
          <span v-show="cupomData.feedback.isOld">Limite de uso do cupom atingido!</span>
        </div>
      </div>

      <div :hidden="!showFormPagamento">
        <div class="text-label">Escola a sua forma de pagamento</div>

        <div class="row">
          <div class="info-pagamento">
            <div class="payment-options">
            <span class="button-payment tablinks" v-show="inputData.showMAG"
                  :class="{'active': activePayment === 'carteiramag'}" @click="openPayment('carteiramag')">
              <img src="@/assets/carteiramag.svg" alt="">
              <span class="title-payment">Carteira MAG</span>
            </span>
              <span class="button-payment tablinks" :class="{'active': activePayment === 'creditcard'}"
                    @click="openPayment('creditcard')" id="defaultOpen">
              <svg width="23px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" aria-labelledby="title"
                   aria-describedby="desc" role="img" xmlns:xlink="http://www.w3.org/1999/xlink">
                <path data-name="layer2"
                      d="M62 16a3 3 0 0 0-3-3H5a3 3 0 0 0-3 3v7h60zM2 23v25a3 3 0 0 0 3 3h54a3 3 0 0 0 3-3V23"
                      fill="none"
                      stroke="#202020" stroke-miterlimit="10" stroke-width="2" stroke-linejoin="round"
                      stroke-linecap="round"></path>
                <path data-name="layer1" fill="none" stroke="#202020" stroke-miterlimit="10" stroke-width="2"
                      d="M10 31h20m18 0h6m-44 8h8" stroke-linejoin="round" stroke-linecap="round"></path>
              </svg>
              <span class="title-payment">Cartão de crédito</span>
            </span>
              <span class="button-payment tablinks" :class="{'active': activePayment === 'pix'}"
                    @click="openPayment('pix')">
              <svg width="23px" height="22" viewBox="0 0 22 23" preserveAspectRatio="xMidYMin" provider=""
                   fill="rgba(145, 145, 145, 0.77)" data-v-ec74aa6c="" data-v-41327139="">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M5.19223 5.24323C6.06969 5.24323 6.89487 5.58498 7.51525 6.20516L10.8818 9.57225C11.1243 9.8147 11.5202 9.81575 11.7633 9.57193L15.1175 6.21736C15.738 5.59718 16.5632 5.25554 17.4407 5.25554H17.8447L13.5842 0.995107C12.2574 -0.331702 10.1063 -0.331702 8.77948 0.995107L4.53135 5.24323H5.19223ZM17.4406 17.108C16.5632 17.108 15.738 16.7664 15.1176 16.1462L11.7632 12.792C11.5278 12.5558 11.1173 12.5565 10.8819 12.792L7.51531 16.1585C6.89482 16.7786 6.06964 17.1202 5.19219 17.1202H4.5312L8.77943 21.3686C10.1062 22.6953 12.2574 22.6953 13.5842 21.3686L17.8447 17.108H17.4406ZM18.794 6.20484L21.3686 8.77947C22.6954 10.1062 22.6954 12.2573 21.3686 13.5842L18.7941 16.1587C18.7373 16.1359 18.6761 16.1218 18.6112 16.1218H17.4407C16.8354 16.1218 16.243 15.8764 15.8154 15.4484L12.4611 12.0945C11.8532 11.4859 10.7925 11.4862 10.184 12.0942L6.81744 15.4607C6.38976 15.8886 5.79746 16.134 5.19222 16.134H3.75286C3.69154 16.134 3.634 16.1486 3.57983 16.169L0.995108 13.5842C-0.331703 12.2573 -0.331703 10.1062 0.995108 8.77947L3.57994 6.19464C3.63411 6.21504 3.69154 6.22956 3.75286 6.22956H5.19222C5.79746 6.22956 6.38976 6.47496 6.81744 6.90285L10.1843 10.2697C10.4982 10.5833 10.9103 10.7404 11.3227 10.7404C11.7349 10.7404 12.1473 10.5833 12.4611 10.2694L15.8154 6.91505C16.243 6.48716 16.8354 6.24176 17.4407 6.24176H18.6112C18.676 6.24176 18.7373 6.22756 18.794 6.20484Z"
                      data-v-ec74aa6c=""></path>
              </svg>
              <span class="title-payment">PIX</span>
            </span>

            </div>
            <div class="option-payment">
              <div id="creditcard" class="tabpayment" v-show="activePayment === 'creditcard'">
                <div class="creditcard-area">
                  <form id="cc-form" class="creditcard-inputs" action="">
                    <div class="input-group">
                      <label class="label">Número do cartão *</label>
                      <input v-model="formDataPayment.ccNumber" class="input-group" type="text" name="number"
                             id="cc-number" placeholder="Digite somente números">
                    </div>
                    <div class="input-group">
                      <label class="label">Nome do titular *</label>
                      <input v-model="formDataPayment.ccName" class="input-group" type="text" name="name" id="cc-name"
                             placeholder="Digite o nome impresso no cartão">
                    </div>
                    <div class="data-cartao">
                      <div class="input-group">
                        <label class="label">Data de expiração *</label>
                        <input v-model="formDataPayment.ccExpiry" placeholder="MM/AAAA" id="cc-expiration"
                               name="expiry">
                      </div>
                      <div class="input-group">
                        <label class="label">CVV *</label>
                        <input v-model="formDataPayment.ccCVV" type="text" name="cvc" id="cc-cvv" placeholder="CVV">
                      </div>
                    </div>
                    <div class="col-lg-5">
                      <i-doc-cpf-cnpj
                          ref="inputDataPaymentCcCPF"
                          v-model="formDataPayment.ccCPF"
                          label="CPF/CNPJ do titular"
                          is-required
                      />
                    </div>
                    <div class="input-group">
                      <label class="label">Selecione o número de parcelas *</label>
                      <select v-model="formDataPayment.parcelas" class="select-style input-group">
                        <option value="1">1x à vista</option>
                        <option value="2">2x Parcelas</option>
                        <option value="3">3x Parcelas</option>
                      </select>
                    </div>
                  </form>
                  <div class="creditcard-view">
                    <div class="card-wrapper">
                    </div>
                  </div>
                </div>
              </div>
              <div id="pix" class="tabpayment" v-show="activePayment === 'pix'">
                <div v-if="pixEtapa.showForm" class="pix-payment-box">
                  <form @submit.prevent="() => formPixSubmitHandle()">
                    <div class="row">
                      <IText
                          class="col-12"
                          v-model="formDataPayment.pixName"
                          label="Nome completo do titular"
                          is-required
                      />
                      <div class="col-lg-5">
                        <i-doc-cpf-cnpj
                            ref="inputPaymentPixDoc"
                            v-model="formDataPayment.pixDoc"
                            label="CPF/CNPJ do titular"
                            is-required
                        />
                      </div>
                    </div>
                    <VueButtonSpinner
                        type="submit"
                        class="btn t1 btn-spin"
                        :disabled="pixEtapa.onRequest"
                        :is-loading="pixEtapa.onRequest"
                    >Enviar
                    </VueButtonSpinner>
                  </form>
                </div>
                <div v-else class="pix-payment-box">
                  <div class="title-pp">
                    Escaneie este código para pagar
                  </div>
                  <img class="img-pp" :src="pixPaymentData.imgSrc" alt="qr-code">
                  <div class="instruction-list-pp">
                    <div>
                      <span>Acesse seu Internet Banking ou app de pagamentos.</span>
                      <span>Escolha pagar via Pix.</span>
                      <span>Escaneie o código mostrado:</span>
                    </div>
                  </div>
                  <hr>
                  <div class="title-pp">
                    Ou copie este código QR para fazer o pagamento
                  </div>
                  <div class="subtitle-pp">
                    Escolha pagar via Pix pelo seu Internet Banking ou app de pagamentos. Depois, cole o seguinte
                    código:
                  </div>
                  <div class="pix-code-box">
                    {{ pixPaymentData.codigo }}
                  </div>
                  <div class="action-pp">
                    <button
                        type="button"
                        class="btn t1"
                        @click="() => copyPixCodeClickHandle()"
                    >
                      Copiar código
                    </button>
                  </div>
                </div>
              </div>
              <div id="carteiramag" class="tabpayment" v-show="activePayment === 'carteiramag'">
                <div class="carteiramag-box">
                  <div class="">
                    <label class="label">Selecione o número de parcelas *</label>
                    <select v-model="formDataPayment.parcelas" class="select-style input-group">
                      <option value="1">1x à vista</option>
                      <option value="2">2x Parcelas</option>
                      <option value="3">3x Parcelas</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="text-label">Detalhes da compra</div>
      <div class="row">
        <div class="titulo-compra">
          <div class="ticket-compra">
            <p class="label-form">{{ ticket.name }}</p>
            <p class="label-form">Descontos</p>
            <p class="label-form">Total</p>
          </div>
          <div class="valor-compra">
            <p class="label-form">{{ ticket.amount_ticket | currency }}</p>
            <p class="label-form">{{ discountTicket.absoluteValue | currency }}</p>
            <p class="label-form">{{ discountTicket.finalValue | currency }}</p>
          </div>
        </div>
      </div>
      <div class="row" v-show="activePayment !== 'pix'">
        <div class="btn-comprar">
          <VueButtonSpinner
              type="submit"
              class="botao-comprar btn-spin"
              :disabled="isCheckoutRunning"
              :is-loading="isCheckoutRunning"
          >Comprar agora
          </VueButtonSpinner>
        </div>
      </div>
      <div class="row">
        <footer class="footer">
          <p>Precisa de ajuda? <a>Entre em contato</a></p>
          <p>Ao clicar em 'Comprar agora', eu concordo <br/>(i) que a MAG Finanças está processando este pedido; <br/>(ii)
            com os
            Termos de Compra <br/>(iii) que li e estou ciente da Política de Privacidade e <br/>(iv) que sou
            maior de idade ou autorizado e acompanhado por um tutor legal. </p>
        </footer>
      </div>
    </div>
  </form>
</template>

<script>
import VueButtonSpinner from 'vue-button-spinner';
import IText from "../inputs/i-text";
import ITel from "../inputs/i-tel";
import IEmail from "../inputs/i-email";
import ICpf from "../inputs/i-cpf";
import * as Card from "card";
import {getTicket} from "@/services/api/ticket";
import {notification} from "@/services/notification";
import {validCupom} from "@/services/api/cupom";
import {submitCheckout} from "../../services/api/checkout";
import IDocCpfCnpj from "../inputs/i-doc-cpf-cnpj";
import {gerarPix, validPixPayment} from "../../services/api/pix";

let cardObj;

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export default {
  name: "form-pagamento",
  components: {IDocCpfCnpj, ICpf, IEmail, ITel, IText, VueButtonSpinner},
  data: () => ({
    inputData: {
      id: null,
      name: null,
      phone: null,
      email: null,
      cpf: null,
      isOnline: false,
      showMAG: false,
    },
    ticket: {
      id: null,
      name: '',
      amount_ticket: 0,
    },
    pixPaymentData: {
      imgSrc: null,
      codigo: null,
      paymentId: null,
    },
    pixEtapa: {
      showForm: true,
      onRequest: false,
    },
    cupomData: {
      codText: '',
      onRequest: false,
      id: null,
      discountPercent: null,
      showCupom: false,
      feedback: {
        message: null,
        isSuccess: false,
        isInvalid: false,
        isOld: false,
      },
    },
    formDataPayment: {
      ccNumber: null,
      ccName: null,
      ccExpiry: null,
      ccCVV: null,
      ccCPF: null,
      pixName: null,
      pixDoc: null,
      parcelas: null,
    },
    isCheckoutRunning: false,
    formIsDisabled: false,
    activePayment: '',
  }),
  mounted() {
    cardObj = new Card({
      form: "form#cc-form",
      container: ".card-wrapper",
      formSelectors: {
        numberInput: "input#cc-number",
        expiryInput: "input#cc-expiration",
        cvcInput: "input#cc-cvv",
        nameInput: "input#cc-name"
      },
      width: 270,
      formatting: true,
      placeholders: {
        number: "•••• •••• •••• ••••",
        name: "Nome Completo",
        expiry: "••/••",
        cvc: "•••"
      }
    });
  },
  methods: {
    async getTickets() {
      const identResp = await getTicket(this.inputData.isOnline ? '1' : '0');
      if (!identResp.ok) {
        return;
      }
      this.ticket.id = identResp.resp.id
      this.ticket.name = identResp.resp.name
      this.ticket.amount_ticket = identResp.resp.amount_ticket
    },
    toogleMostrarCupomForm() {
      this.cupomData.showCupom = !this.cupomData.showCupom;
    },
    cupomSubmitHandle() {
      if (this.cupomData.onRequest || !this.cupomData.codText) {
        return;
      }
      this.cupomData.feedback.message = null;
      this.cupomData.feedback.isSuccess = false;
      this.cupomData.feedback.isInvalid = false;
      this.cupomData.feedback.isOld = false;
      this.cupomData.onRequest = true;
      validCupom(this.cupomData.codText)
          .then(({cupomId, cupomDiscount}) => {
            this.cupomData.id = cupomId;
            this.cupomData.discountPercent = cupomDiscount;
            this.cupomData.feedback.message = "OK";
            this.cupomData.feedback.isSuccess = true;
          })
          .catch(reason => {
            notification.error(reason.message ?? 'Não foi possível validar o cupom');
            this.cupomData.feedback.message = "Fail";
            this.cupomData.feedback.isInvalid = true;
          })
          .finally(() => this.cupomData.onRequest = false)
    },
    openPayment(type) {
      this.activePayment = type;
    },
    getValue() {
      return {};
    },
    isFormCarteiraValid() {
      const inputs = [
        this.formDataPayment.parcelas,
        this.inputData.showMAG
      ];
      return inputs.every(i => Boolean(i));
    },
    isFormPixValid() {
      const inputs = [
        this.formDataPayment.pixName,
        this.$refs.inputPaymentPixDoc.isValid(),
      ];
      return inputs.every(i => Boolean(i));
    },
    isFormCredCardValid() {
      const inputs = [
        cardObj.cardType,
        this.formDataPayment.parcelas,
        (this.formDataPayment.ccNumber ?? '').length >= 3,
        (this.formDataPayment.ccName ?? '').length >= 3,
        (this.formDataPayment.ccExpiry ?? '').length >= 3,
        (this.formDataPayment.ccCVV ?? '').length >= 3,
        this.$refs.inputDataPaymentCcCPF.isValid(),
      ];
      return inputs.every(i => Boolean(i));
    },
    isValid() {
      if (!this.showFormPagamento) {
        return true;
      }
      if (this.activePayment === 'carteiramag') {
        return this.isFormCarteiraValid()
      }
      if (this.activePayment === 'pix') {
        return this.isFormPixValid()
      }
      if (this.activePayment === 'creditcard') {
        return this.isFormCredCardValid()
      }
      return false;
    },
    copyPixCodeClickHandle() {
      navigator.clipboard.writeText(this.pixPaymentData.codigo).then(() => {
        notification.info("Código para pagamento copiado com sucesso");
      })
    },
    async formPixSubmitHandle() {
      if (!this.isFormPixValid() || this.pixEtapa.onRequest) {
        return
      }
      this.pixEtapa.onRequest = true;
      try {
        const data = await gerarPix({
          participanteId: this.inputData.id,
          ticketId: this.ticket.id,
          cupomId: this.cupomData.id,
          name: this.formDataPayment.pixName,
          doc: this.formDataPayment.pixDoc,
        });
        this.pixEtapa.showForm = false
        this.pixPaymentData.codigo = data.codigoContent;
        this.pixPaymentData.imgSrc = data.imageData;
        this.pixPaymentData.paymentId = data.paymentId;
        await this.validPixPayment();
        this.$emit('submit');
      } catch (reason) {
        notification.error(reason.cause ?? 'Não foi possível realizar a Comprar');
      }
      this.pixEtapa.onRequest = false
    },
    async validPixPayment() {
      let keepCheckPayment = true;
      do {
        const paymentResult = await validPixPayment(this.pixPaymentData.paymentId)
        if (paymentResult.checked) {
          keepCheckPayment = false;
        } else {
          await sleep(15000);
        }
      } while (keepCheckPayment);
    },
    setDisabled(isDisabled) {
      this.formIsDisabled = isDisabled;
    },
    async dispatchSubmit() {
      if (!this.isValid()) {
        return;
      }
      this.isCheckoutRunning = true;

      try {
        if (this.activePayment === 'pix') {
          await this.validPixPayment();
        } else {
          await this.comumCheckout();
        }

        this.$emit('submit');
      } catch (reason) {
        notification.error(reason.cause ?? 'Não foi possível realizar a Comprar');
        this.isCheckoutRunning = false;
      }
    },
    comumCheckout() {
      const typePagamento = {
        isCarteira: false,
        isPix: false,
        isCredCard: false,
      };
      if (this.activePayment === 'carteiramag') {
        typePagamento.isCarteira = true;
      }
      if (this.activePayment === 'pix') {
        typePagamento.isPix = true;
      }
      if (this.activePayment === 'creditcard') {
        typePagamento.isCredCard = true;
      }

      return submitCheckout({
        participanteId: this.inputData.id,
        type: typePagamento,
        cupomId: this.cupomData.id,
        cupomCode: this.cupomData.codText,
        ticketId: this.ticket.id,
        ticketAmount: this.ticket.amount_ticket,
        ticketTotal: this.discountTicket.finalValue,
        discount: this.discountTicket.absoluteValue,
        parcelasQtd: this.formDataPayment.parcelas,
        cardSecurityCode: this.formDataPayment.ccCVV,
        cardNumber: this.formDataPayment.ccNumber,
        cardName: this.formDataPayment.ccName,
        cardExpDate: this.formDataPayment.ccExpiry,
        cardCPF: this.formDataPayment.ccCPF,
        cardBrand: cardObj?.cardType,
      });
    },
    initValue({id, name, phone, email, cpf, isOnline, showMAG,}) {
      this.inputData.name = name;
      this.inputData.phone = phone;
      this.inputData.email = email;
      this.inputData.cpf = cpf;
      this.inputData.isOnline = isOnline;
      this.inputData.showMAG = showMAG;
      this.inputData.id = id;

      this.getTickets();
    },
  },
  filters: {
    currency(input) {
      const num = Number(input);
      if (input != num) {
        return '';
      }
      return num.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'});
    }
  },
  computed: {
    showFormPagamento() {
      return this.cupomData.discountPercent !== 1;
    },
    discountTicket() {
      let absoluteValue = 0;
      let finalValue = this.ticket.amount_ticket;
      if (this.cupomData.discountPercent) {
        absoluteValue = this.ticket.amount_ticket * this.cupomData.discountPercent;
        if (absoluteValue > finalValue) {
          absoluteValue = finalValue;
        }
        finalValue -= absoluteValue;
      }
      return {
        absoluteValue,
        finalValue,
      };
    }
  }
}
</script>

<style scoped>
.i-file {
  margin-bottom: 1rem;
  max-width: 520px;
}

.c-s {
  margin-top: 2.5rem;
}

.principal {

}

.infos-ticket {
  display: flex;
  width: 100%;
  height: fit-content;
  justify-content: space-between;
  align-items: center;
  padding: 40px 60px;
}

.categoria-ticket {
  display: flex;
  width: 70%;
  align-items: center;
}

.ticket-img {
  width: 60px;
  height: 60px;
}

.detalhes-ingresso {
  align-items: center;
  box-sizing: border-box;
  padding: 5px 10px 5px 20px;
  width: 100%;
}

.titulo-ingresso span {
  font-weight: 500;
  color: rgb(54, 54, 54);
}

.valor-ingresso {
  font-weight: 700;
  font-size: 24px;
  color: #0079c2;
}

.processamento {
  width: 30%;
  justify-content: flex-end;
  align-items: center;
  align-content: center;
  display: flex;
}

.processado {
  font-size: 11px;
  margin-bottom: 5px;
}

.gateway-img {
  display: flex;
  justify-content: center;
}

.img-processador {
  width: 80px;
}

.divisor {
  border: 1px solid rgba(236, 236, 236, 0.774);
  margin: 2rem 0;
}

.info-usuario {
  width: 100%;
}

.form-user {
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 40px;
}

.input-style {
  width: 100%;
  height: 36px;
  border-radius: 5px;
  border: 1px solid #c2c2c27c;
  margin-top: 5px;
  margin-bottom: 20px;
}

.select-style {
  width: 100%;
  height: 36px;
  border-radius: 5px;
  border: 1px solid #c2c2c27c;
  background-color: #fff;
  margin-top: 5px;
  margin-bottom: 20px;
}

.label-form {
  font-size: 12px
}

.coluna {
  display: flex;
  grid-gap: 20px;
}

.coluna1 {
  width: 60%;

}

.coluna2 {
  width: 40%;
}

.cupom-area {
  background-color: #fff;
  color: #444;
  cursor: pointer;
  height: 46px;
  width: 100%;
  text-align: left;
  border: 1px solid #c2c2c27c;
  outline: none;
  transition: 0.4s;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.cupom-area:hover {
  background-color: rgba(245, 245, 245, 0.527);
}

.cupom-img {
  width: 25px;
  padding: 10 10;
}

.info-pagamento {
  /* width: 100%; */
}

.payment-options {
  /* margin-left: 20px;
  margin-right: 20px; */
  margin-top: 10px;
  display: flex;
  column-gap: 8px;
}

.button-payment {
  flex-basis: 100%;
  background-color: #fff;
  cursor: pointer;
  height: 70px;
  border: 1px solid #c2c2c27c;
  outline: none;
  transition: 0.4s;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-payment.active {
  background-color: #0186c60a;
  border: 1px solid #0386c5;
}

.credit-card:hover {
  background-color: rgba(241, 241, 241, 0.589);
}

.title-payment {
  padding: 5px;
}

.option-payment {
  border-radius: 8px;
  border: 1px solid rgba(221, 221, 221, 0.774);
  margin: 20px 0;
}

.inserir-cupom {
  display: flex;
  column-gap: 20px;
  margin-top: 10px;
  display: none;
}

.codigo-cupom {
  width: 70%;
}

.aplicar-cupom {
  width: 30%;
}

.botao-aplicar {
  width: 100%;
  height: 36px;
  border-radius: 5px;
  border: 1px solid #c2c2c27c;
  margin-top: 5px;
  margin-bottom: 20px;
}

.tabpayment {
  display: block;
  animation: fadeEffect 1s;
}

.creditcard-area {
  display: flex;
  padding: 20px 20px 0 20px;
}

.creditcard-inputs {
  width: 50%;
  margin: 0;
}

.data-cartao {
  display: flex;
  column-gap: 20px;
}

.input-data {
  width: 33.3%;
}

.creditcard-view {
  width: 50%;
}

.parcelas {
  margin-top: -20px;
  padding: 0 20px;
}

.detalhes-compra {
  padding: 0 20px;
  width: 100%;
  margin-top: 20px;
  font-weight: 600;
}

.titulo-compra {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 20px 0 20px;
}

.btn-comprar {
  width: 100%;
  padding: 0 20px;
  margin: 20px 0;
}

button.botao-comprar {
  width: 100%;
  padding: 14px 0 10px 14px;
  background-color: #009d43;
  border: 0;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.2s all;
  height: 45px;
}

.botao-comprar:hover {
  background-color: #078c40;
}

.botao-comprar:active {
  transform: scale(0.98);
}

.footer {
  padding: 0 20;
  font-size: 11px;
  color: #5c5c5c;
}


@media (max-width: 480px) {
  .principal {

  }

  .infos-ticket {
    padding: 20px 10px 20px 20px;
  }

  .valor-ingresso {
    font-size: 14px;
  }

  .titulo-ingresso {
    font-size: 12px;
  }

  .ticket-img {
    width: 35px;
    height: 35px;
  }

  .img-processador {
    width: 60px;
  }

  .processado {
    font-size: 8px;
  }

  .creditcard-area {
    display: block;
  }

  .creditcard-inputs {
    width: 100%;
  }

  .input-data {
    width: 50%;
  }

  .creditcard-view {
    width: 100%;
  }

  .title-payment {
    font-size: 11px;
  }

  .card-wrapper {
    margin: 0 !important;
  }
}
</style>

<style scoped lang="scss">
.pix-payment-box {
  padding: 20px;

  .title-pp {
    font-weight: 500;
    line-height: 1.22;
    font-size: 17px;
    color: #4F4F4F;
  }

  img.img-pp {
    max-width: 420px;
    width: fit-content;
    margin: auto;
  }

  .instruction-list-pp {
    display: flex;
    flex-direction: column;
    margin-left: 36px;
    counter-reset: steps-pp;

    span {
      font-size: 14px;
      color: currentcolor;
      display: block;
      margin-bottom: 5px;

      &::before {
        content: counter(steps-pp) '.';
        font-weight: 400;
        margin-right: 0.7ch;
        counter-increment: steps-pp;
      }
    }
  }

  hr {
    border-color: #cbcbcb;
    margin: 8px 0;
  }

  .subtitle-pp {
    font-size: 15px;
    margin-top: 8px;
  }

  .pix-code-box {
    margin-top: 10px;
    padding: 8px 14px;
    border: 1px solid #DEDEDE;
    border-radius: 5px;
    font-size: 14px;
    line-height: 1.3;
    word-break: break-all;
  }

  .action-pp {
    margin-top: 12px;
  }
}

.cupom-box {
  .btn-show-cupom {
    background-color: transparent;
    border: 1px solid #c7c8c9;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    padding-left: 15px;

    img {
      width: 1.5rem;
      margin-right: 2ch;
    }
  }

  .form-cupom.show-form-cupom {
    margin-top: 8px;
    opacity: 1;
    max-height: fit-content;
    transition: opacity .3s ease-in;
  }

  .form-cupom {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
  }

  .action-cupom {
    display: flex;
    align-items: flex-end;

    button {
      width: 100%;
    }
  }

  .input-cupom {
    margin-bottom: 0;
  }

  .cupom-alert {
    margin-top: 0.75rem;
    border-radius: 5px;
    padding: 1rem 2rem;
    font-weight: 500;

    &.fbc-success {
      background-color: #00eb8c;
    }

    &.fbc-invalid {
      background-color: #f74060;
    }

    &.fbc-old {
      background-color: #ffa555;
    }
  }
}

.carteiramag-box {
  padding: 8px 20px 0;
}
</style>
