export const defaultProps = {
    label: String,
    isDisabled: Boolean,
    placeholder: String,
    isRequired: {
        type: Boolean,
        default: true,
    },
    value: null,
}
