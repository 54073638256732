<template>
  <div>
    <form @submit.prevent="dispatchSubmit">
      <div class="row">
        <div class="col-lg-12">
          <div class="alt-label label required">
            Levarei um acompanhante para o cruzeiro? Selecione uma das opções para continuar:
          </div>
        </div>
        <div class="col-lg-4 a-f">
          <IConfirm
              :is-disabled="formIsDisabled"
              v-model="enableCompanion"
              wo-label
          />
        </div>
      </div>
      <div v-show="enableCompanion">
        <div class="disclaim-f2">
          <div class="l1">INFORMAÇÕES DE ACOMPANHANTE</div>
          <div class="l2">Nos campos a seguir, preencha as informações referentes ao seu acompanhante do
            <span class="font-bold">CRUZEIRO</span>.
          </div>
        </div>
        <div class="row">
          <IText
            :is-disabled="formIsDisabled"
            v-model="formData.name" ref="name" class="col-lg" label="Nome"/>
          <IText
            :is-disabled="formIsDisabled"
            v-model="formData.last_name" ref="last_name" class="col-lg" label="Último sobrenome"/>
          <IText
            :is-disabled="formIsDisabled"
            v-model="formData.badge_name" ref="badge_name" class="col-lg" label="Nome e Sobrenome para crachá"/>
        </div>
        <div class="row">
          <ITelCc
            :is-disabled="formIsDisabled"
            @country-code="updateCoutryCode"
            placeholder="Numero de celular" 
            v-model="formData.phone" ref="phone" class="col-lg-4" label="Número de celular" />
          <IEmail
            :is-disabled="formIsDisabled"
            v-model="formData.email" ref="email" class="col-lg" label="E-mail"/>
        </div>
        <div class="row">
          <IGender
            :is-disabled="formIsDisabled"
            v-model="formData.gender" ref="gender" class="col-lg-3" label="Sexo"/>
          <ICountry
            :is-disabled="formIsDisabled"
            v-model="formData.nationality" ref="nationality" class="col-lg-3" label="Nacionalidade"/>
          <ICpf
            :is-disabled="formIsDisabled"
            v-model="formData.cpf" ref="cpf" class="col-lg-3" label="CPF"/>
          <IText
            class="col-lg-3"
            label="Número do passaporte"
            :is-disabled="formIsDisabled"
            v-model="formData.passport_doc"
            ref="passport_doc"
          />
        </div>
        <div class="row">
          <IDate
              :is-disabled="formIsDisabled"
              v-model="formData.birth_date" ref="birth_date" class="col-lg-3" label="Data de Nascimento" placeholder="dd/mm/aaaa" :min-age="18"/>
          <IText
              :is-disabled="formIsDisabled"
              v-model="formData.identification_doc" ref="identification_doc" class="col-lg-3" label="Número de Identidade"/>
          <IDate
              :is-disabled="formIsDisabled"
              v-model="formData.doc_emission" ref="doc_emission" class="col-lg-3" label="Data de emissão" placeholder="dd/mm/aaaa"/>
          <IDate
              :is-disabled="formIsDisabled"
              v-model="formData.doc_validity" ref="doc_validity" class="col-lg-3" label="Data validade" placeholder="dd/mm/aaaa" :validate-current-date="true"/>
        </div>
        <div class="row">
          <ICountry
            :is-disabled="formIsDisabled"
            v-model="formData.doc_country" ref="doc_country" class="col-lg" label="País de emissão do documento"/>
          <IText
            :is-disabled="formIsDisabled"
            v-model="formData.birth_city" ref="birth_city" class="col-lg" label="Cidade de Nascimento"/>
        </div>
        <div class="row">
          <ICep
              :is-disabled="formIsDisabled"
              v-model="formData.zip_code" ref="zip_code" class="col-lg-2" label="CEP"
              @update-address="(data) => updateAddress(data)"
          />
          <IText
              :is-disabled="formIsDisabled"
              v-model="formData.address" ref="address" class="col-lg" label="Endereço completo"/>
          <IText
              :is-disabled="formIsDisabled"
              v-model="formData.city" ref="city" class="col-lg-4" label="Cidade de residência"/>
          <IUf
            :is-disabled="formIsDisabled"
            v-model="formData.state" ref="state" class="custom-col-uf" label="UF"/>
        </div>
        <div class="row">
          <ICountry
            :is-disabled="formIsDisabled"
            v-model="formData.country" ref="country" class="col-lg" label="País"/>
          <IText
            :is-disabled="formIsDisabled"
            v-model="formData.address_complement" ref="address_complement" class="col-lg" label="Complemento"/>
        </div>
        <div class="row">
          <IConfirm
              :is-disabled="formIsDisabled"
              v-model="formData.has_food_restriction" ref="has_food_restriction" class="col-lg-12"
              label="Restrição medicamentosa, alimentar, substâncias ou algo que precisamos ter ciência:"/>
          <IText v-show="formData.has_food_restriction"
                 :is-disabled="formIsDisabled"
                 v-model="formData.food_restriction" ref="food_restriction"
                 class="col-lg" label="Especifique sua restrição"/>
        </div>
        <div class="c-s text-label">VESTUÁRIO</div>
        <div class="row">
          <ITshirt
            :is-disabled="formIsDisabled"
            v-model="formData.polo_tshirt" ref="polo_tshirt" class="col-lg" label="Tamanho de camisa polo"/>
          <IShoe
            :is-disabled="formIsDisabled"
            v-model="formData.shoe_size" ref="shoe_size" class="col-lg" label="Número de chinelo"/>
        </div>
        <div>
          <div class="row">
            <div class="col-lg-6">
              <div class="text-label">DOCUMENTOS</div>
              <IFile
                  :is-disabled="formIsDisabled"
                  v-model="formData.rg_file" ref="rg_file" class="i-file"
              >
                Anexe aqui uma cópia do <span class="font-bold">documento de identificação com foto do seu acompanhante <small style="font-weight: bold">(RG, CNH, OAB ou RNE)</small></span>
              </IFile>
              <IFile
                  :is-disabled="formIsDisabled"
                  v-model="formData.passport_file" ref="passport_file" class="i-file"
              >
                Anexe aqui uma cópia do <span class="font-bold">passaporte do seu acompanhante (páginas 1,2 e 3)</span>
              </IFile>
            </div>
            <div class="col-lg">
              <div class="text-label">VACINAÇÃO</div>
              <IFile
                  :is-disabled="formIsDisabled"
                  v-model="formData.covid_file" ref="covid_file" class="i-file"
              >
                <b class="font-bold">Certificado Internacional de Vacinação
                ou Profilaxia (CIVP) para Febre Amarela do seu acompanhante</b>
              </IFile>
            </div>
            <div class="text-label text-label-small text-red-alert">É obrigatório que o passaporte tenha pelo menos seis meses de validade a partir da data de embarque, ou seja, válido até no mínimo julho de 2025.</div>
          </div>
        </div>
        <div class="row c-s">
          <div class="col-lg-7">
            <IConfirm label="O seu acompanhante precisará de passagens aéreas para chegar até o evento no Rio de Janeiro?" v-model="enableFly" ref="enableFly" :is-disabled="formIsDisabled"/>
          </div>
        </div>
        <div class="row" v-show="enableFly">
          <div class="text-label">TRANSPORTE AÉREO</div>
          <div class="text-label text-label-small">
            Fique atento às informações fornecidas para que as passagens possam ser emitidas corretamente. Não serão realizadas reemissões de passagens<br />
          </div>
          <IDateTransp
              :is-disabled="formIsDisabled"
              v-model="formData.boarding_date" ref="boarding_date" class="col-lg-4" label="Data de chegada ao Rio de Janeiro" placeholder="dd/mm/aaaa"/>
          <IText
              class="col-lg-8"
              label="Aeroporto de embarque"
              :is-disabled="formIsDisabled"
              ref="departure_airport"
              v-model="formData.departure_airport"
          />
          <IDateTransp
              :is-disabled="formIsDisabled"
              :returnDate="formData.boarding_date"
              v-model="formData.return_date" ref="return_date" class="col-lg-4" label="Data de retorno para sua cidade de origem" placeholder="dd/mm/aaaa"/>
          <IText
              class="col-lg-8"
              label="Aeroporto de retorno"
              :is-disabled="formIsDisabled"
              ref="return_airport"
              v-model="formData.return_airport"
          />
        </div>
        <div class="c-s text-label">CONTATO DE EMERGÊNCIA</div>
        <div class="text-label text-label-small">
          Forneça a seguir informações do seu contato de emergência. <span class="font-bold">Importante: esta pessoa não deverá estar viajando com você.</span>
        </div>
        <div class="row">
          <IText
              :is-disabled="formIsDisabled"
              v-model="formData.contact.name" ref="contact_name" class="col-lg" label="Nome completo"/>
          <ITelCc
              :is-disabled="formIsDisabled"
              @country-code="updateECoutryCode"
              placeholder="Numero de celular" 
              v-model="formData.contact.phone" ref="contact_phone" class="col-lg-4" label="Número de celular"/>
          <IText
              :is-disabled="formIsDisabled"
              v-model="formData.contact.type" ref="contact_type" class="col-lg-3" label="Grau de parentesco"/>
        </div>
        <div class="row">
          <ITextarea
              :is-disabled="formIsDisabled"
              v-model="formData.obs"
              ref="obs"
              class="col-lg"
              label="Comentários adicionais"
              placeholder="Utilize este espaço para pedidos, dúvidas, entre outros." :is-required="false"
          />
        </div>
        <div class="c-s row" v-if="showPregnancyWarning">
          <div class="col-lg">
            <div class="text-label text-label-small disclaimer-gestante">
              <div class="text-label">IMPORTANTE</div>
              Gestantes que completarem 23 semanas de gravidez até o término do cruzeiro (11 janeiro de 2025), deverão apresentar atestado médico de aptidão para
    viajar. Por razões de saúde e segurança, o Transportador não poderá transportar Passageiras completando 24 semanas de gestação ou mais na data do embarque.
            </div>
          </div>
        </div>
      </div>
      <div class="row" style="margin-top: 24px">
        <div class="col-lg-12">
          <div class="alt-label label required">
            {{ labelTravelCompain }}
          </div>
        </div>
        <IConfirm
            class="col-lg-4"
            :is-disabled="formIsDisabled"
            v-model="enableTravelCompain"
            ref="sameCompanion"
            wo-label
        />
      </div>

      <div v-show="sameCompanion === false">
        <div class="disclaim-f2">
          <div class="l1">INFORMAÇÕES DE ACOMPANHANTE</div>
          <div class="l2">Nos campos a seguir, preencha as informações referentes ao seu acompanhante da
            <span class="font-bold">VIAGEM</span>.
          </div>
        </div>
        <div class="row">
          <IText
            :is-disabled="formIsDisabled"
            v-model="formDataTravel.name" ref="travel_name" class="col-lg" label="Nome"/>
          <IText
            :is-disabled="formIsDisabled"
            v-model="formDataTravel.last_name" ref="travel_last_name" class="col-lg" label="Último sobrenome"/>
          <IText
            :is-disabled="formIsDisabled"
            v-model="formDataTravel.badge_name" ref="travel_badge_name" class="col-lg" label="Nome e Sobrenome para crachá"/>
        </div>
        <div class="row">
          <ITelCc
            :is-disabled="formIsDisabled"
            @country-code="updateTCoutryCode"
            placeholder="Numero de celular" 
            v-model="formDataTravel.phone" ref="travel_phone" class="col-lg-4" label="Número de celular" />
          <IEmail
            :is-disabled="formIsDisabled"
            v-model="formDataTravel.email" ref="travel_email" class="col-lg" label="E-mail"/>
        </div>
        <div class="row">
          <IGender
            :is-disabled="formIsDisabled"
            v-model="formDataTravel.gender" ref="travel_gender" class="col-lg-3" label="Sexo"/>
          <ICountry
            :is-disabled="formIsDisabled"
            v-model="formDataTravel.nationality" ref="travel_nationality" class="col-lg-3" label="Nacionalidade"/>
          <ICpf
            :is-disabled="formIsDisabled"
            v-model="formDataTravel.cpf" ref="travel_cpf" class="col-lg-3" label="CPF"/>
          <IText
            class="col-lg-3"
            label="Número do passaporte"
            :is-disabled="formIsDisabled"
            v-model="formDataTravel.passport_doc"
            ref="travel_passport_doc"
          />
        </div>
        <div class="row">
          <IDate
              :is-disabled="formIsDisabled"
              v-model="formDataTravel.birth_date" ref="travel_birth_date" class="col-lg-3" label="Data de Nascimento" :min-age="18"/>
          <IText
              :is-disabled="formIsDisabled"
              v-model="formDataTravel.identification_doc" ref="travel_identification_doc" class="col-lg-3" label="Número de Identidade"/>
          <IDate
              :is-disabled="formIsDisabled"
              v-model="formDataTravel.doc_emission" ref="travel_doc_emission" class="col-lg-3" label="Data de emissão"/>
          <IDate
              :is-disabled="formIsDisabled"
              v-model="formDataTravel.doc_validity" ref="travel_doc_validity" class="col-lg-3" label="Data de validade"/>
        </div>
        <div class="row">
          <ICountry
            :is-disabled="formIsDisabled"
            v-model="formDataTravel.doc_country" ref="travel_doc_country" class="col-lg" label="País de emissão do documento"/>
          <IText
            :is-disabled="formIsDisabled"
            v-model="formDataTravel.birth_city" ref="travel_birth_city" class="col-lg" label="Cidade de Nascimento"/>
        </div>
        <div class="row">
          <ICep
              :is-disabled="formIsDisabled"
              v-model="formDataTravel.zip_code" ref="travel_zip_code" class="col-lg-2" label="CEP"
              @update-address="(data) => updateAddress(data)"
          />
          <IText
              :is-disabled="formIsDisabled"
              v-model="formDataTravel.address" ref="travel_address" class="col-lg" label="Endereço completo"/>
          <IText
              :is-disabled="formIsDisabled"
              v-model="formDataTravel.city" ref="travel_city" class="col-lg-4" label="Cidade de residência"/>
          <IUf
            :is-disabled="formIsDisabled"
            v-model="formDataTravel.state" ref="travel_state" class="custom-col-uf" label="UF"/>
        </div>
        <div class="row">
          <ICountry
            :is-disabled="formIsDisabled"
            v-model="formDataTravel.country" ref="travel_country" class="col-lg" label="País"/>
          <IText
            :is-disabled="formIsDisabled"
            v-model="formDataTravel.address_complement" ref="travel_address_complement" class="col-lg" label="Complemento"/>
        </div>
        <div class="row">
          <IConfirm
              :is-disabled="formIsDisabled"
              v-model="formDataTravel.has_food_restriction" ref="travel_has_food_restriction" class="col-lg-12"
              label="Restrição medicamentosa, alimentar, substâncias ou algo que precisamos ter ciência:"/>
          <IText v-show="formDataTravel.has_food_restriction"
                 :is-disabled="formIsDisabled"
                 v-model="formDataTravel.food_restriction" ref="travel_food_restriction"
                 class="col-lg" label="Especifique sua restrição"/>
        </div>
        <div class="c-s text-label">VESTUÁRIO</div>
        <div class="row">
          <ITshirt
            :is-disabled="formIsDisabled"
            v-model="formDataTravel.polo_tshirt" ref="travel_polo_tshirt" class="col-lg" label="Tamanho de camisa polo"/>
          <IShoe
            :is-disabled="formIsDisabled"
            v-model="formDataTravel.shoe_size" ref="travel_shoe_size" class="col-lg" label="Tamanho de calçado"/>
        </div>
        <div>
          <div class="row">
            <div class="col-lg-6">
              <div class="text-label">DOCUMENTOS</div>
              <IFile
                  :is-disabled="formIsDisabled"
                  v-model="formDataTravel.rg_file" ref="travel_rg_file" class="i-file"
              >
                Anexe aqui uma cópia do <span class="font-bold">documento de identificação com foto do seu acompanhante <small style="font-weight: bold">(RG, CNH, OAB ou RNE)</small></span>
              </IFile>
              <IFile
                  :is-disabled="formIsDisabled"
                  v-model="formDataTravel.passport_file" ref="travel_passport_file" class="i-file"
              >
                Anexe aqui uma cópia do <span class="font-bold">passaporte do seu acompanhante (páginas 1,2 e 3)</span>
              </IFile>
            </div>
            <div class="col-lg">
              <div class="text-label">VACINAÇÃO</div>
              <IFile
                  :is-disabled="formIsDisabled"
                  v-model="formDataTravel.covid_file" ref="travel_covid_file" class="i-file"
              >
                <b class="font-bold">Certificado Internacional de Vacinação
                ou Profilaxia (CIVP) para Febre Amarela do seu acompanhante</b>
              </IFile>
            </div>
            <div class="text-label text-label-small text-red-alert">É obrigatório que o passaporte tenha pelo menos seis meses de validade a partir da data de embarque, ou seja, válido até no mínimo julho de 2025.</div>
          </div>
        </div>
        <div class="row c-s">
          <div class="col-lg-7">
            <IConfirm label="O seu acompanhante precisará de passagens aéreas para chegar até o evento no Rio de Janeiro?" v-model="eventEnableFly" ref="travel_enableFly" :is-disabled="formIsDisabled"/>
          </div>
        </div>
        <div class="row" v-show="eventEnableFly">
          <div class="text-label">TRANSPORTE AÉREO</div>
          <div class="text-label text-label-small">
            Fique atento às informações fornecidas para que as passagens possam ser emitidas corretamente. Não serão realizadas reemissões de passagens<br />
          </div>
          <IDateTransp
              :is-disabled="formIsDisabled"
              v-model="formDataTravel.boarding_date" ref="travel_boarding_date" class="col-lg-4" label="Data de chegada ao Rio de Janeiro"/>
          <IText
              class="col-lg-8"
              label="Aeroporto de embarque"
              :is-disabled="formIsDisabled"
              ref="travel_departure_airport"
              v-model="formDataTravel.departure_airport"
          />
          <IDateTransp
              :is-disabled="formIsDisabled"
              :returnDate="formData.boarding_date"
              v-model="formDataTravel.return_date" ref="travel_return_date" class="col-lg-4" label="Data de retorno para sua cidade de origem"/>
          <IText
              class="col-lg-8"
              label="Aeroporto de retorno para sua cidade"
              :is-disabled="formIsDisabled"
              ref="travel_return_airport"
              v-model="formDataTravel.return_airport"
          />
        </div>
        <div class="c-s text-label">CONTATO DE EMERGÊNCIA</div>
        <div class="text-label text-label-small">
          Forneça a seguir informações do seu contato de emergência. <span class="font-bold">Importante: esta pessoa não deverá estar viajando com você.</span>
        </div>
        <div class="row">
          <IText
              :is-disabled="formIsDisabled"
              v-model="formDataTravel.contact.name" ref="travel_contact_name" class="col-lg" label="Nome completo"/>
          <ITelCc
              :is-disabled="formIsDisabled"
              @country-code="updateTECoutryCode"
              placeholder="Numero de celular" 
              v-model="formDataTravel.contact.phone" ref="travel_contact_phone" class="col-lg-4" label="Número de celular"/>
          <IText
              :is-disabled="formIsDisabled"
              v-model="formDataTravel.contact.type" ref="travel_contact_type" class="col-lg-3" label="Grau de parentesco"/>
        </div>
        <div class="row">
          <ITextarea
              :is-disabled="formIsDisabled"
              v-model="formDataTravel.obs"
              ref="travel_obs"
              class="col-lg"
              label="Comentários adicionais"
              placeholder="Utilize este espaço para pedidos, dúvidas, entre outros." :is-required="false"
          />
        </div>
        <div class="c-s row" v-if="showPregnancyWarningT">
          <div class="col-lg">
            <div class="text-label text-label-small disclaimer-gestante">
              <div class="text-label">IMPORTANTE</div>
              Gestantes que completarem 23 semanas de gravidez até o término do cruzeiro (11 janeiro de 2025), deverão apresentar atestado médico de aptidão para
    viajar. Por razões de saúde e segurança, o Transportador não poderá transportar Passageiras completando 24 semanas de gestação ou mais na data do embarque.
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import IText from "../inputs/i-text";
import ITelCc from "../inputs/i-tel-cc";
import IEmail from "../inputs/i-email";
import ICpf from "../inputs/i-cpf";
import IDate from "../inputs/i-date";
import IDateTransp from "../inputs/i-datetransp";
import ICep from "../inputs/i-cep";
import IUf from "../inputs/i-uf";
import ITshirt from "../inputs/i-shirt";
import IShoe from "../inputs/i-shoe";
import ICountry from "../inputs/i-country";
import IConfirm from "../inputs/i-confirm";
import IGender from "../inputs/i-gender";
import IFile from "../inputs/i-file";
import ITextarea from "../inputs/i-textarea";

export default {
  name: "form-f4-e2",
  components: {ITextarea, IFile, IConfirm, IUf, ICep, IDate, IDateTransp, ICpf, IEmail, ITelCc, IText, ITshirt, ICountry, IShoe, IGender},
  data: () => ({
    formData: {
      name: null,
      last_name: null,
      badge_name: null,
      phone: null,
      email: null,
      nationality: null,
      gender: null,
      cpf: null,
      birth_date: null,
      identification_doc: null,
      doc_emission: null,
      doc_validity: null,
      doc_country: null,
      birth_city: null,
      passport_doc: null,
      zip_code: null,
      state: null,
      city: null,
      address: null,
      country: null,
      address_complement: null,
      food_restriction: null,
      has_food_restriction: null,
      rg_file: null,
      passport_file: null,
      covid_file: null,
      polo_tshirt: null,
      shoe_size: null,
      boarding_date: null,
      return_date: null,
      departure_airport: null,
      return_airport: null,
      contact: {
        type: null,
        name: null,
        phone: null,
      },
      obs: null,
    },
    formDataTravel: {
      name: null,
      last_name: null,
      badge_name: null,
      phone: null,
      email: null,
      nationality: null,
      gender: null,
      cpf: null,
      birth_date: null,
      identification_doc: null,
      doc_emission: null,
      doc_validity: null,
      doc_country: null,
      birth_city: null,
      passport_doc: null,
      zip_code: null,
      state: null,
      city: null,
      address: null,
      country: null,
      address_complement: null,
      food_restriction: null,
      has_food_restriction: null,
      rg_file: null,
      passport_file: null,
      covid_file: null,
      polo_tshirt: null,
      shoe_size: null,
      boarding_date: null,
      return_date: null,
      departure_airport: null,
      return_airport: null,
      contact: {
        type: null,
        name: null,
        phone: null,
      },
      obs: null,
    },
    formIsDisabled: false,
    enableCompanion: null,
    enableTravelCompain: null,
    enableFly: null,
    coutryCode: null,
    ecoutryCode: null,
    tcoutryCode: null,
    tecoutryCode: null,
    eventEnableFly: null,
  }),
  methods: {
    getValue() {
      let shallowCopy;
      if (this.enableCompanion) {
        shallowCopy = {...this.formData};
        delete shallowCopy.has_food_restriction;
        if (!this.formData.has_food_restriction) {
          delete shallowCopy.food_restriction;
        }
        shallowCopy.phone = `+${this.coutryCode}${this.formData.phone}`;
        shallowCopy.contact.phone = `+${this.ecoutryCode}${this.formData.contact.phone}`;
        shallowCopy.enableFly = this.enableFly;
      }

      if (!this.sameCompanion) {
        shallowCopy.travelCompanion = {...this.formDataTravel};
        delete shallowCopy.travelCompanion.has_food_restriction;
        if (!this.formDataTravel.has_food_restriction) {
          delete shallowCopy.travelCompanion.food_restriction;
        }
        shallowCopy.travelCompanion.phone = `+${this.tcoutryCode}${this.formDataTravel.phone}`;
        shallowCopy.travelCompanion.contact.phone = `+${this.tecoutryCode}${this.formDataTravel.contact.phone}`;
        shallowCopy.travelCompanion.enableFly = this.eventEnableFly;
      }

      return shallowCopy;
    },
    isValid() {
      if (this.enableCompanion === false) {
        return true;
      }
      const normalRef = this.getNormalRef();
      if (this.formData.has_food_restriction) {
        normalRef.push(this.$refs.food_restriction);
      }
      if (this.enableFly) {
        normalRef.push(
            this.$refs.return_date,
            this.$refs.boarding_date,
            this.$refs.departure_airport,
            this.$refs.return_airport,
        );
      }
      if (!this.sameCompanion) {
        normalRef.push(
          this.$refs.travel_name,
          this.$refs.travel_last_name,
          this.$refs.travel_badge_name,
          this.$refs.travel_phone,
          this.$refs.travel_email,
          this.$refs.travel_cpf,
          this.$refs.travel_birth_date,
          this.$refs.travel_identification_doc,
          this.$refs.travel_doc_emission,
          this.$refs.travel_doc_validity,
          this.$refs.travel_doc_country,
          this.$refs.travel_birth_city,
          this.$refs.travel_passport_doc,
          this.$refs.travel_zip_code,
          this.$refs.travel_state,
          this.$refs.travel_city,
          this.$refs.travel_address,
          this.$refs.travel_country,
          this.$refs.travel_address_complement,
          this.$refs.travel_has_food_restriction,
          this.$refs.travel_rg_file,
          this.$refs.travel_passport_file,
          this.$refs.travel_covid_file,
          this.$refs.travel_polo_tshirt,
          this.$refs.travel_shoe_size,
          this.$refs.travel_contact_name,
          this.$refs.travel_contact_phone,
          this.$refs.travel_contact_type,
          this.$refs.travel_obs,
          this.$refs.travel_enableFly,
        );
        if (this.formDataTravel.has_food_restriction) {
          normalRef.push(this.$refs.travel_food_restriction);
        }
        if (this.eventEnableFly) {
          normalRef.push(
              this.$refs.travel_return_date,
              this.$refs.travel_boarding_date,
              this.$refs.travel_departure_airport,
              this.$refs.travel_return_airport,
          );
        }
      }

      return normalRef.reduce((last, ref) => ref?.isValid() && last, true);
    },
    setValue() {
    },
    setDisabled(isDisabled) {
      this.formIsDisabled = isDisabled;
    },
    getNormalRef() {
      return [
        this.$refs.name,
        this.$refs.last_name,
        this.$refs.badge_name,
        this.$refs.phone,
        this.$refs.email,
        this.$refs.cpf,
        this.$refs.birth_date,
        this.$refs.identification_doc,
        this.$refs.doc_emission,
        this.$refs.doc_validity,
        this.$refs.doc_country,
        this.$refs.birth_city,
        this.$refs.passport_doc,
        this.$refs.zip_code,
        this.$refs.state,
        this.$refs.city,
        this.$refs.address,
        this.$refs.country,
        this.$refs.address_complement,
        this.$refs.has_food_restriction,
        this.$refs.rg_file,
        this.$refs.passport_file,
        this.$refs.covid_file,
        this.$refs.polo_tshirt,
        this.$refs.shoe_size,
        this.$refs.contact_name,
        this.$refs.contact_phone,
        this.$refs.contact_type,
        this.$refs.obs,
        this.$refs.enableFly,
        this.$refs.sameCompanion,
      ];
    },
    dispatchSubmit() {
      if (this.isValid()) {
        this.$emit('submit');
      }
    },
    updateAddress(addressData) {
      this.formData.state = addressData.state;
      this.formData.city = addressData.city;
      this.formData.address = addressData.address;
      if (addressData) {
        this.formData.country = 'Brasil';
      }
    },
    updateAddressTravelCompain(addressData) {
      this.formDataTravel.state = addressData.state;
      this.formDataTravel.city = addressData.city;
      this.formDataTravel.address = addressData.address;
      if (addressData) {
        this.formDataTravel.country = 'Brasil';
      }
    },
    updateCoutryCode(code) {
      this.coutryCode = code
    },
    updateECoutryCode(code) {
      this.ecoutryCode = code
    },
    updateTCoutryCode(code) {
      this.tcoutryCode = code
    },
    updateTECoutryCode(code) {
      this.tecoutryCode = code
    }
  },
  computed: {
    labelTravelCompain() {
      if (this.enableCompanion) {
        return "O acompanhante será o mesmo para a viagem de premiação? Selecione uma das opções para continuar:";
      }
      return "Levarei um acompanhante para a viagem? Selecione uma das opções para continuar:";
    },
    sameCompanion() {
      if (this.enableCompanion) {
        return this.enableTravelCompain;
      }
      return !this.enableTravelCompain;
    },
    age() {
      if (!this.formData.birth_date) return null;
      const birthDate = new Date(this.formData.birth_date);
      const ageDiff = Date.now() - birthDate.getTime();
      const ageDate = new Date(ageDiff);
      return Math.abs(ageDate.getUTCFullYear() - 1970);
    },
    ageT() {
      if (!this.formDataTravel.birth_date) return null;
      const birthDate = new Date(this.formDataTravel.birth_date);
      const ageDiff = Date.now() - birthDate.getTime();
      const ageDate = new Date(ageDiff);
      return Math.abs(ageDate.getUTCFullYear() - 1970);
    },
    showPregnancyWarning() {
      return this.formData.gender === 'Feminino' && this.age !== null && this.age < 50;
    },
    showPregnancyWarningT() {
      return this.formDataTravel.gender === 'Feminino' && this.ageT !== null && this.ageT < 50;
    }
  },
}
</script>

<style scoped lang="scss">
@import "../../styles/color";

.i-file {
  margin-bottom: 1rem;
  max-width: 520px;
}

.c-s {
  margin-top: 2.5rem;
}

.a-f {
  margin-bottom: 20px;
}

.disclaim-f2 {
  color: $black4;
  margin-bottom: 25px;

  .l1 {
    font-size: 18px;
    font-weight: 500;
  }

  .l2 {
    font-size: 14px;
    font-weight: 500;
  }
}
.disclaimer-gestante {
  background: #ff00001f;
  padding: 0.75rem;
  border-radius: 5px;
  color: #000;
}
.text-red-alert {
  color: #f00;
  margin-top: 10px;
}
</style>
