<template>
  <div class="form-check">
    <input class="form-check-input" type="checkbox" :id="uid" v-model="inputValue">
    <label class="form-check-label" :for="uid">
      <slot>{{ label }}</slot>
    </label>
  </div>
</template>

<script>
export default {
  name: "i-checkbox",
  data: () => ({
    uid: "check_" + Math.random().toString(36).slice(-5)
  }),
  props: {
    label: String,
    value: Boolean,
  },
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('input', Boolean(v));
      },
    },
  },
}
</script>

<style lang="scss">
</style>
