var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return function () {
          return _vm.dispatchSubmit();
        }.apply(null, arguments);
      }
    }
  }, [_c('div', [_c('div', {
    staticClass: "row"
  }, [_vm._m(0), _c('div', {
    staticClass: "col-lg-12 a-f"
  }, [_c('IOnlinePresencial', {
    attrs: {
      "is-disabled": _vm.formIsDisabled,
      "wo-label": ""
    },
    on: {
      "input": function input(v) {
        return _vm.$emit('change-online-presencial', v);
      }
    },
    model: {
      value: _vm.formData.isOnline,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "isOnline", $$v);
      },
      expression: "formData.isOnline"
    }
  })], 1)]), _c('div', {
    staticClass: "row"
  }, [_c('IText', {
    ref: "name",
    staticClass: "col-lg",
    attrs: {
      "is-disabled": !!_vm.originalName || _vm.formIsDisabled,
      "label": "Nome completo"
    },
    model: {
      value: _vm.formData.name,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "name", $$v);
      },
      expression: "formData.name"
    }
  }), _vm.formData.isOnline === false ? _c('IText', {
    ref: "badge_name",
    staticClass: "col-lg",
    attrs: {
      "is-disabled": _vm.formIsDisabled,
      "label": "Nome para crachá"
    },
    model: {
      value: _vm.formData.badge_name,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "badge_name", $$v);
      },
      expression: "formData.badge_name"
    }
  }) : _vm._e()], 1), _c('div', {
    staticClass: "row"
  }, [_c('ITel', {
    ref: "phone",
    staticClass: "col-lg-4",
    attrs: {
      "is-disabled": _vm.formIsDisabled,
      "label": "Telefone"
    },
    model: {
      value: _vm.formData.phone,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "phone", $$v);
      },
      expression: "formData.phone"
    }
  }), _c('IEmail', {
    ref: "email",
    staticClass: "col-lg",
    attrs: {
      "is-disabled": _vm.formIsDisabled,
      "label": "E-mail"
    },
    model: {
      value: _vm.formData.email,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "email", $$v);
      },
      expression: "formData.email"
    }
  })], 1), _c('div', {
    staticClass: "row"
  }, [_c('ICpf', {
    ref: "cpf",
    staticClass: "col-lg-4",
    attrs: {
      "is-disabled": _vm.formIsDisabled,
      "label": "CPF"
    },
    model: {
      value: _vm.formData.cpf,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "cpf", $$v);
      },
      expression: "formData.cpf"
    }
  }), _c('IDate', {
    ref: "birth_date",
    staticClass: "col-lg-3",
    attrs: {
      "is-disabled": _vm.formIsDisabled,
      "label": "Data de Nascimento"
    },
    model: {
      value: _vm.formData.birth_date,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "birth_date", $$v);
      },
      expression: "formData.birth_date"
    }
  }), _c('IText', {
    ref: "identification_doc",
    staticClass: "col-lg-5",
    attrs: {
      "is-disabled": _vm.formIsDisabled,
      "label": "Número de Identidade"
    },
    model: {
      value: _vm.formData.identification_doc,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "identification_doc", $$v);
      },
      expression: "formData.identification_doc"
    }
  })], 1), _c('div', {
    staticClass: "row"
  }, [_c('ICep', {
    ref: "zip_code",
    staticClass: "col-lg-2",
    attrs: {
      "is-disabled": _vm.formIsDisabled,
      "label": "CEP"
    },
    on: {
      "update-address": function updateAddress(data) {
        return _vm.updateAddress(data);
      }
    },
    model: {
      value: _vm.formData.zip_code,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "zip_code", $$v);
      },
      expression: "formData.zip_code"
    }
  }), _c('IUf', {
    ref: "state",
    staticClass: "custom-col-uf",
    attrs: {
      "is-disabled": _vm.formIsDisabled,
      "label": "UF"
    },
    model: {
      value: _vm.formData.state,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "state", $$v);
      },
      expression: "formData.state"
    }
  }), _c('IText', {
    ref: "city",
    staticClass: "col-lg-4",
    attrs: {
      "is-disabled": _vm.formIsDisabled,
      "label": "Cidade"
    },
    model: {
      value: _vm.formData.city,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "city", $$v);
      },
      expression: "formData.city"
    }
  }), _c('IText', {
    ref: "address",
    staticClass: "col-lg",
    attrs: {
      "is-disabled": _vm.formIsDisabled,
      "label": "Endereço"
    },
    model: {
      value: _vm.formData.address,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "address", $$v);
      },
      expression: "formData.address"
    }
  })], 1), _c('div', {
    staticClass: "row"
  }, [_c('IText', {
    ref: "address_complement",
    staticClass: "col-lg",
    attrs: {
      "is-disabled": _vm.formIsDisabled,
      "label": "Complemento"
    },
    model: {
      value: _vm.formData.address_complement,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "address_complement", $$v);
      },
      expression: "formData.address_complement"
    }
  })], 1), _c('div', {
    staticClass: "row"
  }, [_c('IText', {
    ref: "number_susep",
    staticClass: "col-3",
    attrs: {
      "is-disabled": _vm.formIsDisabled,
      "label": "Número da SUSEP",
      "is-required": false
    },
    model: {
      value: _vm.formData.number_susep,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "number_susep", $$v);
      },
      expression: "formData.number_susep"
    }
  }), _c('IText', {
    ref: "corretora",
    staticClass: "col-lg",
    attrs: {
      "is-disabled": _vm.formIsDisabled,
      "label": "Corretora",
      "is-required": false
    },
    model: {
      value: _vm.formData.corretora,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "corretora", $$v);
      },
      expression: "formData.corretora"
    }
  })], 1), _vm.formData.isOnline === false ? _c('div', [_c('div', {
    staticClass: "row"
  }, [_c('IConfirm', {
    ref: "has_food_restriction",
    staticClass: "col-lg-4",
    attrs: {
      "is-disabled": _vm.formIsDisabled,
      "label": "Restrição alimentar?"
    },
    model: {
      value: _vm.formData.has_food_restriction,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "has_food_restriction", $$v);
      },
      expression: "formData.has_food_restriction"
    }
  }), _c('IText', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.formData.has_food_restriction,
      expression: "formData.has_food_restriction"
    }],
    ref: "food_restriction",
    staticClass: "col-lg",
    attrs: {
      "is-disabled": _vm.formIsDisabled,
      "label": "Especifique sua restrição"
    },
    model: {
      value: _vm.formData.food_restriction,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "food_restriction", $$v);
      },
      expression: "formData.food_restriction"
    }
  })], 1), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-6"
  }, [_c('div', {
    staticClass: "text-label"
  }, [_vm._v("DOCUMENTOS")]), _c('IFile', {
    ref: "rg_file",
    staticClass: "i-file col-lg",
    attrs: {
      "is-disabled": _vm.formIsDisabled
    },
    model: {
      value: _vm.formData.rg_file,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "rg_file", $$v);
      },
      expression: "formData.rg_file"
    }
  }, [_vm._v(" Anexe aqui uma cópia do seu "), _c('span', {
    staticClass: "font-bold"
  }, [_vm._v("documento de identificação com foto")])])], 1)]), _c('div', {
    staticClass: "c-s text-label"
  }, [_vm._v("CONTATO DE EMERGÊNCIA")]), _c('div', {
    staticClass: "text-label text-label-small"
  }, [_vm._v(" Forneça a seguir informações do seu contato de emergência. ")]), _c('div', {
    staticClass: "row"
  }, [_c('IText', {
    ref: "contact_name",
    staticClass: "col-lg",
    attrs: {
      "is-disabled": _vm.formIsDisabled,
      "label": "Nome completo"
    },
    model: {
      value: _vm.formData.contact.name,
      callback: function callback($$v) {
        _vm.$set(_vm.formData.contact, "name", $$v);
      },
      expression: "formData.contact.name"
    }
  }), _c('ITel', {
    ref: "contact_phone",
    staticClass: "custom-col-tel",
    attrs: {
      "is-disabled": _vm.formIsDisabled,
      "label": "Telefone"
    },
    model: {
      value: _vm.formData.contact.phone,
      callback: function callback($$v) {
        _vm.$set(_vm.formData.contact, "phone", $$v);
      },
      expression: "formData.contact.phone"
    }
  }), _c('IText', {
    ref: "contact_type",
    staticClass: "col-lg-3",
    attrs: {
      "is-disabled": _vm.formIsDisabled,
      "label": "Grau de parentesco"
    },
    model: {
      value: _vm.formData.contact.type,
      callback: function callback($$v) {
        _vm.$set(_vm.formData.contact, "type", $$v);
      },
      expression: "formData.contact.type"
    }
  })], 1), _c('div', {
    staticClass: "row"
  }, [_c('ITextarea', {
    ref: "obs",
    staticClass: "col-lg",
    attrs: {
      "is-disabled": _vm.formIsDisabled,
      "label": "Comentários adicionais",
      "placeholder": "Utilize este espaço para pedidos, dúvidas, entre outros.",
      "is-required": false
    },
    model: {
      value: _vm.formData.obs,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "obs", $$v);
      },
      expression: "formData.obs"
    }
  })], 1)]) : _vm._e()])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-lg-12"
  }, [_c('div', {
    staticClass: "alt-label label required"
  }, [_vm._v(" Olá será um prazer para nós receber você no MAGNEXT 2024. Para iniciarmos, escolha a modalidade que você participará do nosso evento: ")])]);
}]

export { render, staticRenderFns }