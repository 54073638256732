<template>
  <form @submit.prevent="() => dispatchSubmit()">
    <div>
      <div class="row">
        <div class="col-lg-12">
          <div class="alt-label label required">
            Olá, <strong>{{ displayName }}</strong> <br />
            Será um prazer para nós receber você no MAGNEXT 2024. Para iniciarmos, escolha a modalidade que você participará do nosso evento: 
          </div>
        </div>
        <div class="col-lg-12 a-f">
          <IOnlinePresencial
              :is-disabled="formIsDisabled"
              v-model="isOnline"
              wo-label
              @input="v => $emit('change-online-presencial', v)"
          />
        </div>
      </div>
      <div v-if="isOnline">
        <div class="row">
          <IText
              :is-disabled="formIsDisabled"
              v-model="formData.name" ref="name" class="col-lg" label="Nome completo"/>
        </div>
        <div class="row">
          <ITel
              :is-disabled="formIsDisabled"
              v-model="formData.phone" ref="phone" class="col-lg-4" label="Telefone"/>
          <IEmail
              is-disabled
              v-model="formData.email" ref="email" class="col-lg" label="E-mail"/>
        </div>
        <div class="row">
          <ICpf
              :is-disabled="formIsDisabled"
              v-model="formData.cpf" ref="cpf" class="col-lg-4" label="CPF"/>
          <IDate
              :is-disabled="formIsDisabled"
              v-model="formData.birth_date" ref="birth_date" class="col-lg-3" label="Data de Nascimento"/>
          <IText
              :is-disabled="formIsDisabled"
              v-model="formData.identification_doc" ref="identification_doc" class="col-lg-5" label="Número de Identidade"/>
        </div>
        <div class="row">
          <ICep
              :is-disabled="formIsDisabled"
              v-model="formData.zip_code" ref="zip_code" class="col-lg-2" label="CEP"
              @update-address="(data) => updateAddress(data)"
          />
          <IUf
              :is-disabled="formIsDisabled"
              v-model="formData.state" ref="state" class="custom-col-uf" label="UF"/>
          <IText
              :is-disabled="formIsDisabled"
              v-model="formData.city" ref="city" class="col-lg-4" label="Cidade"/>
          <IText
              :is-disabled="formIsDisabled"
              v-model="formData.address" ref="address" class="col-lg" label="Endereço"/>
        </div>
        <div class="row">
          <IText
              :is-disabled="formIsDisabled"
              v-model="formData.address_complement" ref="address_complement" class="col-lg" label="Complemento"/>
        </div>
        <div class="row">
          <IText
              :is-disabled="formIsDisabled"
              v-model="formData.number_susep" ref="number_susep" class="col-3" label="Número da SUSEP" :is-required="false"/>
          <IText
              :is-disabled="formIsDisabled"
              v-model="formData.corretora" ref="corretora" class="col-lg" label="Corretora" :is-required="false"/>
        </div>
      </div>

      <div v-if="isOnline === false">
        <div class="row">
          <IText
              :is-disabled="formIsDisabled"
              v-model="formData2.name" ref="presential_name" class="col-lg" label="Nome completo"/>
          <IText
              :is-disabled="formIsDisabled"
              v-model="formData2.badge_name" ref="presential_badge_name" class="col-lg" label="Nome para crachá"/>
        </div>
        <div class="row">
          <ITel
              :is-disabled="formIsDisabled"
              v-model="formData2.phone" ref="presential_phone" class="col-lg-4" label="Telefone"/>
          <IEmail
              is-disabled
              v-model="formData2.email" ref="presential_email" class="col-lg" label="E-mail"/>
        </div>
        <div class="row">
          <ICpf
              :is-disabled="formIsDisabled"
              v-model="formData2.cpf" ref="presential_cpf" class="col-lg-4" label="CPF"/>
          <IDate
              :is-disabled="formIsDisabled"
              v-model="formData2.birth_date" ref="presential_birth_date" class="col-lg-3" label="Data de Nascimento"/>
          <IText
              :is-disabled="formIsDisabled"
              v-model="formData2.identification_doc" ref="presential_identification_doc" class="col-lg-5" label="Número de Identidade"/>
        </div>
        <div class="row">
          <ICep
              :is-disabled="formIsDisabled"
              v-model="formData2.zip_code" ref="presential_zip_code" class="col-lg-2" label="CEP"
              @update-address="(data) => updateAddressPresential(data)"
          />
          <IUf
              :is-disabled="formIsDisabled"
              v-model="formData2.state" ref="presential_state" class="custom-col-uf" label="UF"/>
          <IText
              :is-disabled="formIsDisabled"
              v-model="formData2.city" ref="presential_city" class="col-lg-4" label="Cidade"/>
          <IText
              :is-disabled="formIsDisabled"
              v-model="formData2.address" ref="presential_address" class="col-lg" label="Endereço"/>
        </div>
        <div class="row">
          <IText
              :is-disabled="formIsDisabled"
              v-model="formData2.address_complement" ref="presential_address_complement" class="col-lg" label="Complemento"/>
        </div>
        <div class="row">
          <IText
              :is-disabled="formIsDisabled"
              v-model="formData2.number_susep" ref="presential_number_susep" class="col-3" label="Número da SUSEP" :is-required="false"/>
          <IText
              :is-disabled="formIsDisabled"
              v-model="formData2.corretora" ref="presential_corretora" class="col-lg" label="Corretora" :is-required="false"/>
        </div>
        <div class="row">
          <IConfirm
              :is-disabled="formIsDisabled"
              v-model="formData2.has_food_restriction" ref="presential_has_food_restriction" class="col-lg-4"
              label="Restrição alimentar?"/>
          <IText v-show="formData2.has_food_restriction"
                :is-disabled="formIsDisabled"
                v-model="formData2.food_restriction" ref="presential_food_restriction"
                class="col-lg" label="Especifique sua restrição"/>
        </div>

        <div class="row">
          <div class="col-lg-6">
            <div class="text-label">DOCUMENTOS</div>
            <IFile
                :is-disabled="formIsDisabled"
                v-model="formData2.rg_file" ref="presential_rg_file" class="i-file col-lg"
            >
              Anexe aqui uma cópia do seu
              <span class="font-bold">documento de identificação com foto</span>
            </IFile>
          </div>
        </div>


        <div class="c-s text-label">CONTATO DE EMERGÊNCIA</div>
        <div class="text-label text-label-small">
          Forneça a seguir informações do seu contato de emergência.
        </div>
        <div class="row">
          <IText
              :is-disabled="formIsDisabled"
              v-model="formData2.contact.name" ref="presential_contact_name" class="col-lg" label="Nome completo"/>
          <ITel
              :is-disabled="formIsDisabled"
              v-model="formData2.contact.phone" ref="presential_contact_phone" class="custom-col-tel" label="Telefone"/>
          <IText
              :is-disabled="formIsDisabled"
              v-model="formData2.contact.type" ref="presential_contact_type" class="col-lg-3" label="Grau de parentesco"/>
        </div>
        <div class="row">
          <ITextarea
              :is-disabled="formIsDisabled"
              v-model="formData2.obs"
              ref="presential_obs"
              class="col-lg"
              label="Comentários adicionais"
              placeholder="Utilize este espaço para pedidos, dúvidas, entre outros." :is-required="false"
          />
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import IText from "../inputs/i-text";
import ITel from "../inputs/i-tel";
import IEmail from "../inputs/i-email";
import ICpf from "../inputs/i-cpf";
import IDate from "../inputs/i-date";
import ICep from "../inputs/i-cep";
import IUf from "../inputs/i-uf";
import IConfirm from "../inputs/i-confirm";
import IOnlinePresencial from "../inputs/i-online-presencial";
import IFile from "../inputs/i-file";
import ITextarea from "../inputs/i-textarea";
// import UiDisclaim from "../ui-disclaim";

export default {
  name: "form-f9",
  components: {IConfirm, IFile, ITextarea, IOnlinePresencial, IUf, ICep, IDate, ICpf, IEmail, ITel, IText},
  data: () => ({
    displayName: null,
    formData: {
      name: null,
      phone: null,
      email: null,
      cpf: null,
      birth_date: null,
      identification_doc: null,
      zip_code: null,
      state: null,
      city: null,
      address: null,
      address_complement: null,
      number_susep: null,
      corretora: null,
    },
    formData2: {
      name: null,
      badge_name: null,
      phone: null,
      email: null,
      cpf: null,
      birth_date: null,
      identification_doc: null,
      zip_code: null,
      state: null,
      city: null,
      address: null,
      address_complement: null,
      food_restriction: null,
      has_food_restriction: null,
      rg_file: null,
      number_susep: null,
      corretora: null,
      contact: {
        type: null,
        name: null,
        phone: null,
      },
      obs: null,
    },
    formIsDisabled: false,
    isOnline: null,
  }),
  methods: {
    getValue() {
      let shallowCopy;
      shallowCopy = {...this.formData};
      shallowCopy.isOnline = this.isOnline;

      if (this.isOnline) {
        return shallowCopy;
      }

      let shallowCopy2;
      shallowCopy2 = {...this.formData2};
      shallowCopy2.isOnline = this.isOnline;

      if (!this.formData2.has_food_restriction) {
        delete shallowCopy2.food_restriction;
      }
      return shallowCopy2;
    },
    isValid() {
      const normalRef = this.getNormalRef();
      if (!this.isOnline) { 
        const normalRefP = [];
        normalRefP.push(
            this.$refs.presential_name,
            this.$refs.presential_badge_name,
            this.$refs.presential_phone,
            this.$refs.presential_email,
            this.$refs.presential_cpf,
            this.$refs.presential_birth_date,
            this.$refs.presential_identification_doc,
            this.$refs.presential_zip_code,
            this.$refs.presential_state,
            this.$refs.presential_city,
            this.$refs.presential_address,
            this.$refs.presential_address_complement,
            this.$refs.presential_number_susep,
            this.$refs.presential_corretora,
            this.$refs.presential_rg_file,
            this.$refs.presential_has_food_restriction,
            this.$refs.presential_contact_name,
            this.$refs.presential_contact_phone,
            this.$refs.presential_contact_type,
            this.$refs.presential_obs,
        );
        if (this.formData2.has_food_restriction) {
          normalRefP.push(this.$refs.presential_food_restriction);
        }
        return normalRefP.reduce((last, ref) => ref?.isValid() && last, true);
      }

      return normalRef.reduce((last, ref) => ref?.isValid() && last, true);
    },
    setNameEmail(name, email) {
      this.displayName = name;
      // this.formData.name = name;
      this.formData.email = email;
      // this.formData2.name = name;
      this.formData2.email = email;
    },
    setDisabled(isDisabled) {
      this.formIsDisabled = isDisabled;
    },
    getNormalRef() {
      return [
        this.$refs.name,
        this.$refs.phone,
        this.$refs.email,
        this.$refs.cpf,
        this.$refs.birth_date,
        this.$refs.identification_doc,
        this.$refs.zip_code,
        this.$refs.state,
        this.$refs.city,
        this.$refs.address,
        this.$refs.address_complement,
        this.$refs.number_susep,
        this.$refs.corretora,
      ];
    },
    dispatchSubmit() {
      if (this.isValid()) {
        this.$emit('submit');
      }
    },
    updateAddress(addressData) {
      this.formData.state = addressData.state;
      this.formData.city = addressData.city;
      this.formData.address = addressData.address;
    },
    updateAddressPresential(addressData) {
      this.formData2.state = addressData.state;
      this.formData2.city = addressData.city;
      this.formData2.address = addressData.address;
    },
  },
}
</script>

<style scoped>
.i-file {
  margin-bottom: 1rem;
  max-width: 520px;
}

.c-s {
  margin-top: 2.5rem;
}
</style>
