<template>
  <div>
    <form @submit.prevent="dispatchSubmit">
      <div class="row">
        <div class="col-lg-12">
          <div class="alt-label label required">
            Levarei um acompanhante para o evento? Selecione uma das opções para continuar:
          </div>
        </div>
        <div class="col-lg-5 a-f">
          <IConfirm
              :is-disabled="formIsDisabled"
              v-model="enableCompanion"
              wo-label
          />
        </div>
      </div>
      <div v-show="enableCompanion">
        <div class="row">
          <IText
              :is-disabled="formIsDisabled"
              v-model="formData.name" ref="name" class="col-lg" label="Nome completo"/>
        </div>
        <div class="row">
          <ITel
              :is-disabled="formIsDisabled"
              v-model="formData.phone" ref="phone" class="col-lg-4" label="Telefone"/>
          <IEmail
              :is-disabled="formIsDisabled"
              v-model="formData.email" ref="email" class="col-lg" label="E-mail"/>
        </div>
        <div class="row">
          <ICpf
              :is-disabled="formIsDisabled"
              v-model="formData.cpf" ref="cpf" class="col-lg-4" label="CPF"/>
          <IDate
              :is-disabled="formIsDisabled"
              v-model="formData.birth_date" ref="birth_date" class="col-lg-3" label="Data de Nascimento"/>
          <IText
              :is-disabled="formIsDisabled"
              v-model="formData.identification_doc" ref="identification_doc" class="col-lg-5" label="Número de Identidade"/>
        </div>
        <div class="row">
          <ICep
              :is-disabled="formIsDisabled"
              v-model="formData.zip_code" ref="zip_code" class="col-lg-2" label="CEP"
              @update-address="(data) => updateAddress(data)"
          />
          <IUf
              :is-disabled="formIsDisabled"
              v-model="formData.state" ref="state" class="custom-col-uf" label="UF"/>
          <IText
              :is-disabled="formIsDisabled"
              v-model="formData.city" ref="city" class="col-lg-4" label="Cidade"/>
          <IText
              :is-disabled="formIsDisabled"
              v-model="formData.address" ref="address" class="col-lg" label="Endereço"/>
        </div>
        <div class="row">
          <IText
              :is-disabled="formIsDisabled"
              v-model="formData.address_complement" ref="address_complement" class="col-lg" label="Complemento"/>
        </div>
        <div class="row">
          <IConfirm
              :is-disabled="formIsDisabled"
              v-model="formData.has_food_restriction" ref="has_food_restriction" class="col-lg-4"
              label="Restrição alimentar?"/>
          <IText v-show="formData.has_food_restriction"
                 :is-disabled="formIsDisabled"
                 v-model="formData.food_restriction" ref="food_restriction"
                 class="col-lg" label="Especifique sua restrição"/>
        </div>


        <div>
          <div class="text-label">TRANSPORTE AÉREO</div>
          <div class="text-label text-label-small">
            Fique atento às informações fornecidas para que as passagens possam ser emitidas corretamente. Não serão
            realizadas reemissões de passagens.
          </div>
          <div class="row">
            <div class="col-lg-4">
              <IConfirm label="Precisa de transporte aéreo?" v-model="enableFly" ref="enableFly"
                        :is-disabled="formIsDisabled"/>
            </div>
            <IText
                v-show="enableFly"
                class="col-lg-4"
                label="Ida - Aeroporto de embarque"
                :is-disabled="formIsDisabled"
                ref="departure_airport"
                v-model="formData.departure_airport"
            />

            <IText
                v-show="enableFly"
                class="col-lg-4"
                label="Retorno - Aeroporto de destino"
                :is-disabled="formIsDisabled"
                ref="return_airport"
                v-model="formData.return_airport"
            />
          </div>

        </div>

        <div>
          <div class="row">
            <div class="col-lg-6">
              <div class="text-label">DOCUMENTOS</div>
              <IFile
                  :is-disabled="formIsDisabled"
                  v-model="formData.embarque_file" ref="embarque_file" class="i-file"
              >
                Anexe aqui uma cópia do
                <span class="font-bold">documento de identificação com foto utilizado pelo seu acompanhante</span>
              </IFile>
            </div>
            <!-- <div class="col-lg">
              <div class="text-label">
                VACINAÇÃO
                <UiDisclaim
                    class="ml-1ch"
                    message="Conforme as exigências do Decreto Rio nº 49335, é exigida a comprovação de vacinação completa contra COVID-19, para o acesso e a permanência em estabelecimentos e locais de uso coletivo."
                />
              </div>
              <IFile
                  :is-disabled="formIsDisabled"
                  v-model="formData.covid_file" ref="covid_file" class="i-file"
              >
                Anexe aqui uma cópia do
                <b class="font-bold">Certificado de Vacinação (COVID-19) do seu acompanhante</b>
              </IFile>
            </div> -->
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import IText from "../inputs/i-text";
import ITel from "../inputs/i-tel";
import IEmail from "../inputs/i-email";
import ICpf from "../inputs/i-cpf";
import IDate from "../inputs/i-date";
import ICep from "../inputs/i-cep";
import IUf from "../inputs/i-uf";
import IConfirm from "../inputs/i-confirm";
import IFile from "../inputs/i-file";
// import UiDisclaim from "../ui-disclaim";

export default {
  name: "form-f7-e2",
  components: {IFile, IConfirm, IUf, ICep, IDate, ICpf, IEmail, ITel, IText},
  data: () => ({
    formData: {
      name: null,
      phone: null,
      email: null,
      cpf: null,
      birth_date: null,
      identification_doc: null,
      zip_code: null,
      state: null,
      city: null,
      address: null,
      address_complement: null,
      food_restriction: null,
      has_food_restriction: null,
      embarque_file: null,
      // covid_file: null,
      departure_airport: null,
      return_airport: null,
    },
    formIsDisabled: false,
    enableCompanion: null,
    enableFly: null,
  }),
  methods: {
    getValue() {
      if (this.enableCompanion === false) {
        return null;
      }
      const shallowCopy = {...this.formData, contact: {...this.formData.contact}};
      delete shallowCopy.has_food_restriction;
      if (!this.formData.has_food_restriction) {
        delete shallowCopy.food_restriction;
      }
      shallowCopy.enableFly = this.enableFly;
      return shallowCopy;
    },
    isValid() {
      if (this.enableCompanion === false) {
        return true;
      }
      const normalRef = this.getNormalRef();
      if (this.formData.has_food_restriction) {
        normalRef.push(this.$refs.food_restriction);
      }
      if (this.enableFly) {
        normalRef.push(
            this.$refs.return_airport,
            this.$refs.departure_airport,
        );
      }
      return normalRef.reduce((last, ref) => ref?.isValid() && last, true);
    },
    setValue() {
    },
    setDisabled(isDisabled) {
      this.formIsDisabled = isDisabled;
    },
    getNormalRef() {
      return [
        this.$refs.name,
        this.$refs.phone,
        this.$refs.email,
        this.$refs.cpf,
        this.$refs.birth_date,
        this.$refs.identification_doc,
        this.$refs.zip_code,
        this.$refs.state,
        this.$refs.city,
        this.$refs.address,
        this.$refs.address_complement,
        this.$refs.has_food_restriction,
        this.$refs.embarque_file,
        // this.$refs.covid_file,
        this.$refs.enableFly,
      ];
    },
    dispatchSubmit() {
      if (this.isValid()) {
        this.$emit('submit');
      }
    },
    updateAddress(addressData) {
      this.formData.state = addressData.state;
      this.formData.city = addressData.city;
      this.formData.address = addressData.address;
    },
  },
}
</script>

<style scoped>
.i-file {
  margin-bottom: 1rem;
  max-width: 520px;
}

.c-s {
  margin-top: 2.5rem;
}

.a-f {
  margin-bottom: 20px;
}
</style>
