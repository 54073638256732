<template>
  <div class="input-group" :class="{'input-group-invalid': isInvalid, 'input-group-disabled': isDisabled}">
    <label>
      <span class="label" :class="{'required': isRequired}"><slot>{{ label }}</slot></span>
      <input
          v-mask="mask"
          type="text"
          inputmode="numeric"
          :disabled="isDisabled"
          :placeholder="placeholder"
          v-model="inputValue"
          @blur="() => validate()"
          @input="() => inputHandle()"
      >
      <div v-if="isInvalid" class="error-message">{{ errorMessage }}</div>
    </label>
  </div>
</template>

<script>
import {VueMaskDirective} from 'v-mask'
import {defaultProps} from "./defaultProps";
import {validDate} from "../../services/validation/validDate";

export default {
  name: "i-dateen",
  props: {
    ...defaultProps,
    minAge: {
      type: Number,
      default: null,
    },
    validateCurrentDate: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    mask: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
    inputValue: null,
    isInvalid: false,
    hasTouched: false,
  }),
  directives: {
    mask: VueMaskDirective
  },
  computed: {
    errorMessage() {
      if (this.isInvalid && !validDate(this.inputValue)) {
        return "invalid date";
      }
      if (this.isInvalid && this.minAge && !this.isOldEnough()) {
        return `You must be at least ${this.minAge} years old`;
      }
    if (this.isInvalid && !this.isBeforeToday()) {
        return "Expired expiration date!";
      }
      return "";
    },
  },
  methods: {
    inputHandle() {
      this.hasTouched = true;
      this.$emit('input', this.inputValue);
    },
    validate() {
      const isBeforeToday = this.validateCurrentDate ? this.isBeforeToday() : true;  // Verifica se deve validar contra a data atual
      this.isInvalid = (!validDate(this.inputValue) && this.hasTouched)
          || (this.hasTouched && this.inputValue === null)
          || (this.isRequired && !this.inputValue)
          || (this.minAge && !this.isOldEnough())
          || (this.hasTouched && !isBeforeToday);
    },
    clean() {
      this.inputValue = null;
      this.isInvalid = false;
      this.hasTouched = false;
    },
    isValid() {
      this.validate();
      return !this.isInvalid;
    },
    isOldEnough() {
      if (!this.inputValue || !this.minAge) {
        return true;
      }
      const birthDate = new Date(this.inputValue.split('/').reverse().join('-'));
      const today = new Date('2025-01-08');
      const age = today.getFullYear() - birthDate.getFullYear();
      const m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        return age > this.minAge;
      }
      return age >= this.minAge;
    },
    isBeforeToday() {
      if (!this.inputValue) return true;
      const inputDate = new Date(this.inputValue.split('/').reverse().join('-'));
      const cutoffDate = new Date('2025-01-08');
      return inputDate > cutoffDate;
    }
  },
  watch: {
    value: {
      handler(v) {
        this.inputValue = v;
      },
      immediate: true,
    },
  },
}
</script>

<style scoped>
.error-message {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}
</style>
