<template>
  <div class="input-group" :class="{'input-group-invalid': isInvalid, 'input-group-disabled': isDisabled}">
    <label>
      <span class="label" :class="{'required': isRequired}"><slot>{{ label }}</slot></span>
      <input
          type="text"
          :disabled="isDisabled"
          :placeholder="placeholder"
          v-model="inputValue"
          @blur="() => validate()"
          @input="() => inputHandle()"
          maxlength="2"
          :list="datalistId"
      >
      <datalist :id="datalistId">
        <option value="SP"/>
        <option value="RJ"/>
        <option value="MG"/>
        <option value="RS"/>
        <option value="PR"/>
        <option value="SC"/>
        <option value="BA"/>
        <option value="DF"/>
        <option value="GO"/>
        <option value="PE"/>
        <option value="PA"/>
        <option value="CE"/>
        <option value="ES"/>
        <option value="MT"/>
        <option value="AM"/>
        <option value="MS"/>
        <option value="MA"/>
        <option value="RN"/>
        <option value="PB"/>
        <option value="AL"/>
        <option value="PI"/>
        <option value="SE"/>
        <option value="RO"/>
        <option value="TO"/>
        <option value="AP"/>
        <option value="AC"/>
        <option value="RR"/>
      </datalist>
    </label>
  </div>
</template>

<script>
import {defaultProps} from "./defaultProps";
import {validUF} from "../../services/validation/validUF";

export default {
  name: "i-uf",
  props: {...defaultProps},
  data: () => ({
    inputValue: null,
    isInvalid: false,
    hasTouched: false,
    datalistId: 'datalist' + Math.random().toString(36).slice(-6),
  }),
  methods: {
    inputHandle() {
      this.hasTouched = true;
      this.$emit('input', this.inputValue);
    },
    validate() {
      this.isInvalid = (!validUF(this.inputValue) && this.hasTouched)
          || (this.hasTouched && this.inputValue === null)
          || (this.isRequired && !this.inputValue);
    },
    clean() {
      this.inputValue = null;
      this.isInvalid = false;
      this.hasTouched = false;
    },
    isValid() {
      this.validate();
      return !this.isInvalid;
    },
  },
  watch: {
    value: {
      handler(v) {
        this.inputValue = v;
      },
      immediate: true,
    },
  },
}
</script>

<style scoped>

</style>
