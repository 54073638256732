var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "input-group",
    class: {
      'input-group-invalid': _vm.isInvalid,
      'input-group-disabled': _vm.isDisabled
    }
  }, [_c('div', [_c('span', {
    staticClass: "label",
    class: {
      'required': _vm.isRequired
    }
  }, [_vm._t("default", function () {
    return [_vm._v(_vm._s(_vm.label))];
  })], 2), _c('VueTelInput', {
    attrs: {
      "disabled": _vm.isDisabled,
      "placeholder": _vm.placeholder,
      "inputOptions": _vm.options
    },
    on: {
      "blur": function blur() {
        return _vm.validate();
      },
      "input": function input(number, phoneData) {
        return _vm.inputHandle(number, phoneData);
      }
    },
    model: {
      value: _vm.inputValue,
      callback: function callback($$v) {
        _vm.inputValue = $$v;
      },
      expression: "inputValue"
    }
  })], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }