var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return function () {
          return _vm.dispatchSubmit();
        }.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-6 a-f"
  }, [_c('IConfirm', {
    attrs: {
      "is-disabled": _vm.formIsDisabled,
      "label": "Do you will bring a companion? Select one of the options to continue:"
    },
    model: {
      value: _vm.enableCompanion,
      callback: function callback($$v) {
        _vm.enableCompanion = $$v;
      },
      expression: "enableCompanion"
    }
  })], 1)]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.enableCompanion,
      expression: "enableCompanion"
    }]
  }, [_c('div', {
    staticClass: "row"
  }, [_c('IText', {
    ref: "name",
    staticClass: "col-lg",
    attrs: {
      "is-disabled": _vm.formIsDisabled,
      "label": "Name"
    },
    model: {
      value: _vm.formData.name,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "name", $$v);
      },
      expression: "formData.name"
    }
  }), _c('IText', {
    ref: "last_name",
    staticClass: "col-lg",
    attrs: {
      "is-disabled": _vm.formIsDisabled,
      "label": "Last Name"
    },
    model: {
      value: _vm.formData.last_name,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "last_name", $$v);
      },
      expression: "formData.last_name"
    }
  }), _c('IText', {
    ref: "badge_name",
    staticClass: "col-lg",
    attrs: {
      "is-disabled": _vm.formIsDisabled,
      "label": "Name and Surname for badge"
    },
    model: {
      value: _vm.formData.badge_name,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "badge_name", $$v);
      },
      expression: "formData.badge_name"
    }
  })], 1), _c('div', {
    staticClass: "row"
  }, [_c('ITelCcE', {
    ref: "phone",
    staticClass: "col-lg-4",
    attrs: {
      "is-disabled": _vm.formIsDisabled,
      "label": "Phone number",
      "is-required": false
    },
    on: {
      "country-code": _vm.updateCoutryCode
    },
    model: {
      value: _vm.formData.phone,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "phone", $$v);
      },
      expression: "formData.phone"
    }
  }), _c('IEmail', {
    ref: "email",
    staticClass: "col-lg",
    attrs: {
      "is-disabled": _vm.formIsDisabled,
      "label": "E-mail"
    },
    model: {
      value: _vm.formData.email,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "email", $$v);
      },
      expression: "formData.email"
    }
  })], 1), _c('div', {
    staticClass: "row"
  }, [_c('IGenderEN', {
    ref: "gender",
    staticClass: "col-lg-3",
    attrs: {
      "is-disabled": _vm.formIsDisabled,
      "label": "Gender"
    },
    model: {
      value: _vm.formData.gender,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "gender", $$v);
      },
      expression: "formData.gender"
    }
  }), _c('ICountryEN', {
    ref: "nationality",
    staticClass: "col-lg",
    attrs: {
      "is-disabled": _vm.formIsDisabled,
      "label": "Nationality"
    },
    model: {
      value: _vm.formData.nationality,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "nationality", $$v);
      },
      expression: "formData.nationality"
    }
  })], 1), _c('div', {
    staticClass: "row"
  }, [_c('IText', {
    ref: "passport_doc",
    staticClass: "col-lg-3",
    attrs: {
      "is-disabled": _vm.formIsDisabled,
      "label": "Passport number"
    },
    model: {
      value: _vm.formData.passport_doc,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "passport_doc", $$v);
      },
      expression: "formData.passport_doc"
    }
  }), _c('IDateEN', {
    ref: "doc_emission",
    staticClass: "col-lg-3",
    attrs: {
      "is-disabled": _vm.formIsDisabled,
      "label": "Date of issue",
      "placeholder": "DD/MM/YYYY"
    },
    model: {
      value: _vm.formData.doc_emission,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "doc_emission", $$v);
      },
      expression: "formData.doc_emission"
    }
  }), _c('IDateEN', {
    ref: "doc_validity",
    staticClass: "col-lg-3",
    attrs: {
      "is-disabled": _vm.formIsDisabled,
      "label": "Expiration Date",
      "placeholder": "DD/MM/YYYY",
      "validate-current-date": true
    },
    model: {
      value: _vm.formData.doc_validity,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "doc_validity", $$v);
      },
      expression: "formData.doc_validity"
    }
  }), _c('IDateEN', {
    ref: "birth_date",
    staticClass: "col-lg-3",
    attrs: {
      "is-disabled": _vm.formIsDisabled,
      "label": "Birth date",
      "placeholder": "DD/MM/YYYY",
      "min-age": 18
    },
    model: {
      value: _vm.formData.birth_date,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "birth_date", $$v);
      },
      expression: "formData.birth_date"
    }
  })], 1), _c('div', {
    staticClass: "row"
  }, [_c('ICountryEN', {
    ref: "doc_country",
    staticClass: "col-lg",
    attrs: {
      "is-disabled": _vm.formIsDisabled,
      "label": "Country of issuing the document"
    },
    model: {
      value: _vm.formData.doc_country,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "doc_country", $$v);
      },
      expression: "formData.doc_country"
    }
  }), _c('IText', {
    ref: "birth_city",
    staticClass: "col-lg",
    attrs: {
      "is-disabled": _vm.formIsDisabled,
      "label": "City of Birth"
    },
    model: {
      value: _vm.formData.birth_city,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "birth_city", $$v);
      },
      expression: "formData.birth_city"
    }
  })], 1), _c('div', {
    staticClass: "row"
  }, [_c('IText', {
    ref: "zip_code",
    staticClass: "col-lg-3",
    attrs: {
      "is-disabled": _vm.formIsDisabled,
      "label": "Zip Code"
    },
    model: {
      value: _vm.formData.zip_code,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "zip_code", $$v);
      },
      expression: "formData.zip_code"
    }
  }), _c('IText', {
    ref: "address",
    staticClass: "col-lg",
    attrs: {
      "is-disabled": _vm.formIsDisabled,
      "label": "Full address"
    },
    model: {
      value: _vm.formData.address,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "address", $$v);
      },
      expression: "formData.address"
    }
  }), _c('IText', {
    ref: "city",
    staticClass: "col-lg-4",
    attrs: {
      "is-disabled": _vm.formIsDisabled,
      "label": "City of residence"
    },
    model: {
      value: _vm.formData.city,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "city", $$v);
      },
      expression: "formData.city"
    }
  })], 1), _c('div', {
    staticClass: "row"
  }, [_c('IText', {
    ref: "state",
    staticClass: "col-lg",
    attrs: {
      "is-disabled": _vm.formIsDisabled,
      "label": "State"
    },
    model: {
      value: _vm.formData.state,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "state", $$v);
      },
      expression: "formData.state"
    }
  }), _c('ICountryEN', {
    ref: "country",
    staticClass: "col-lg",
    attrs: {
      "is-disabled": _vm.formIsDisabled,
      "label": "Country"
    },
    model: {
      value: _vm.formData.country,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "country", $$v);
      },
      expression: "formData.country"
    }
  })], 1), _c('div', {
    staticClass: "row"
  }, [_c('IConfirm', {
    ref: "has_food_restriction",
    staticClass: "col-lg-12",
    attrs: {
      "is-disabled": _vm.formIsDisabled,
      "label": "Restrictions on medication, food, substances or something we need to be aware of:"
    },
    model: {
      value: _vm.formData.has_food_restriction,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "has_food_restriction", $$v);
      },
      expression: "formData.has_food_restriction"
    }
  }), _c('IText', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.formData.has_food_restriction,
      expression: "formData.has_food_restriction"
    }],
    ref: "food_restriction",
    staticClass: "col-lg",
    attrs: {
      "is-disabled": _vm.formIsDisabled,
      "label": "Which your restrictions"
    },
    model: {
      value: _vm.formData.food_restriction,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "food_restriction", $$v);
      },
      expression: "formData.food_restriction"
    }
  })], 1), _vm._m(0), _c('div', {
    staticClass: "row"
  }, [_c('ITshirtEN', {
    ref: "polo_tshirt",
    staticClass: "col-lg",
    attrs: {
      "is-disabled": _vm.formIsDisabled,
      "label": "Polo t-shirt size"
    },
    model: {
      value: _vm.formData.polo_tshirt,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "polo_tshirt", $$v);
      },
      expression: "formData.polo_tshirt"
    }
  }), _c('IShoeEN', {
    ref: "shoe_size",
    staticClass: "col-lg",
    attrs: {
      "is-disabled": _vm.formIsDisabled,
      "label": "Shoes size"
    },
    model: {
      value: _vm.formData.shoe_size,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "shoe_size", $$v);
      },
      expression: "formData.shoe_size"
    }
  })], 1), _c('div', [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-6"
  }, [_c('div', {
    staticClass: "text-label"
  }, [_vm._v("DOCUMENTS")]), _c('IFile', {
    ref: "passport_file",
    staticClass: "i-file",
    attrs: {
      "is-disabled": _vm.formIsDisabled
    },
    model: {
      value: _vm.formData.passport_file,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "passport_file", $$v);
      },
      expression: "formData.passport_file"
    }
  }, [_vm._v(" Attach a copy of your "), _c('span', {
    staticClass: "font-bold"
  }, [_vm._v("passport "), _c('br'), _vm._v("(pages 1,2 and 3)")]), _vm._v(" here ")])], 1)])]), _c('div', {
    staticClass: "row c-s"
  }, [_c('div', {
    staticClass: "col-lg-7"
  }, [_c('IConfirm', {
    ref: "enableFly",
    attrs: {
      "label": "Will yout companion need a plane ticket to get to the event?",
      "is-disabled": _vm.formIsDisabled
    },
    model: {
      value: _vm.enableFly,
      callback: function callback($$v) {
        _vm.enableFly = $$v;
      },
      expression: "enableFly"
    }
  })], 1)]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.enableFly,
      expression: "enableFly"
    }],
    staticClass: "row"
  }, [_c('div', {
    staticClass: "text-label"
  }, [_vm._v("AIR TRANSPORT")]), _vm._m(1), _c('IDateTranspEN', {
    ref: "boarding_date",
    staticClass: "col-lg-4",
    attrs: {
      "is-disabled": _vm.formIsDisabled,
      "label": "Date of arrival in Rio de Janeiro"
    },
    model: {
      value: _vm.formData.boarding_date,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "boarding_date", $$v);
      },
      expression: "formData.boarding_date"
    }
  }), _c('IText', {
    ref: "departure_airport",
    staticClass: "col-lg-8",
    attrs: {
      "label": "Boarding airport",
      "is-disabled": _vm.formIsDisabled
    },
    model: {
      value: _vm.formData.departure_airport,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "departure_airport", $$v);
      },
      expression: "formData.departure_airport"
    }
  }), _c('IDateTranspEN', {
    ref: "return_date",
    staticClass: "col-lg-4",
    attrs: {
      "is-disabled": _vm.formIsDisabled,
      "returnDate": _vm.formData.boarding_date,
      "label": "Return date to your city of origin"
    },
    model: {
      value: _vm.formData.return_date,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "return_date", $$v);
      },
      expression: "formData.return_date"
    }
  }), _c('IText', {
    ref: "return_airport",
    staticClass: "col-lg-8",
    attrs: {
      "label": "Return airport",
      "is-disabled": _vm.formIsDisabled
    },
    model: {
      value: _vm.formData.return_airport,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "return_airport", $$v);
      },
      expression: "formData.return_airport"
    }
  })], 1), _c('div', {
    staticClass: "c-s text-label"
  }, [_vm._v("EMERGENCY CONTACT")]), _vm._m(2), _c('div', {
    staticClass: "row"
  }, [_c('IText', {
    ref: "contact_name",
    staticClass: "col-lg",
    attrs: {
      "is-disabled": _vm.formIsDisabled,
      "label": "Full name"
    },
    model: {
      value: _vm.formData.contact.name,
      callback: function callback($$v) {
        _vm.$set(_vm.formData.contact, "name", $$v);
      },
      expression: "formData.contact.name"
    }
  }), _c('ITelCcE', {
    ref: "contact_phone",
    staticClass: "col-lg-4",
    attrs: {
      "is-disabled": _vm.formIsDisabled,
      "label": "Phone number"
    },
    on: {
      "country-code": _vm.updateECoutryCode
    },
    model: {
      value: _vm.formData.contact.phone,
      callback: function callback($$v) {
        _vm.$set(_vm.formData.contact, "phone", $$v);
      },
      expression: "formData.contact.phone"
    }
  }), _c('IText', {
    ref: "contact_type",
    staticClass: "col-lg-3",
    attrs: {
      "is-disabled": _vm.formIsDisabled,
      "label": "Relation"
    },
    model: {
      value: _vm.formData.contact.type,
      callback: function callback($$v) {
        _vm.$set(_vm.formData.contact, "type", $$v);
      },
      expression: "formData.contact.type"
    }
  })], 1), _c('div', {
    staticClass: "row"
  }, [_c('ITextarea', {
    ref: "obs",
    staticClass: "col-lg",
    attrs: {
      "is-disabled": _vm.formIsDisabled,
      "label": "Additional comments",
      "placeholder": "Use this space for special requests, doubts, and others.",
      "is-required": false
    },
    model: {
      value: _vm.formData.obs,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "obs", $$v);
      },
      expression: "formData.obs"
    }
  })], 1), _vm.showPregnancyWarning ? _c('div', {
    staticClass: "c-s row"
  }, [_vm._m(3)]) : _vm._e()])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "flex",
    staticStyle: {
      "display": "flex",
      "align-items": "center"
    }
  }, [_c('div', {
    staticClass: "c-s text-label"
  }, [_vm._v("CLOTHING")]), _c('img', {
    staticStyle: {
      "margin": "20px 0 0 10px"
    },
    attrs: {
      "src": require("@/assets/330426.png"),
      "width": "30",
      "height": "30"
    }
  })]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-label text-label-small"
  }, [_vm._v(" Please pay attention to the information provided so that tickets can be issued correctly. Tickets will not be reissued."), _c('br')]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-label text-label-small"
  }, [_vm._v(" We kindly ask for the details of an emergency contact. "), _c('span', {
    staticClass: "font-bold"
  }, [_vm._v("Important: this person must not be travelling with you.")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-lg"
  }, [_c('div', {
    staticClass: "text-label text-label-small disclaimer-gestante"
  }, [_c('div', {
    staticClass: "text-label"
  }, [_vm._v("IMPORTANT")]), _vm._v(" Pregnant women who complete 23 weeks of pregnancy by the end of the cruise (January 11, 2025) must present a medical certificate of fitness to travel. For health and safety reasons, the Carrier may not transport Passengers who are 24 weeks pregnant or more on the date of boarding. ")])]);
}]

export { render, staticRenderFns }