import {makeRegisterFormDecline} from "./makeFormData/formDecline";
import fetchDecline from "./fetch/fetchDecline";

async function apiFetch(id, form1Data) {
    let formData
    formData = makeRegisterFormDecline(id, form1Data);
    if (!formData) {
        throw {cod: 'VPN7D2VT'};
    }
    let resp;
    resp = await fetchDecline(formData);

    if (!resp.ok) {
        let errRespBody;
        try {
            errRespBody = await resp.json();
        } catch {
            throw {cod: 'DY6YRVUV', errMsg: errRespBody.message};
        }
        if (errRespBody.errors && errRespBody.errors.email[0] === "O campo email já está sendo utilizado.") {
            throw {cod: 'EUE65DJC', errMsg: "O e-mail cadastrado já encontra-se em uso, confira sua caixa de e-mails"};
        }
        if (errRespBody.errors && errRespBody.errors.cpf[0] === "O campo CPF já está sendo utilizado.") {
            throw {cod: 'EUE65DJC', errMsg: "O CPF cadastrado já encontra-se em uso"};
        }
        throw {cod: 'EUE65DJC', errMsg: errRespBody.message};
    }

    const respBody = await resp.json();


    if (!respBody.data && !respBody.user) {
        throw {cod: '5KJM357A'};
    }
    return respBody.data;
    // return formData.get('name');
}


export async function decline(id, form1Data) {
    if (!form1Data) {
        throw '5KJM357A';
    }

    return await apiFetch(id, form1Data);
}
