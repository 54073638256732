var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return function () {
          return _vm.dispatchSubmit();
        }.apply(null, arguments);
      }
    }
  }, [_c('div', [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-12 a-f"
  }, [_c('div', {
    staticClass: "principal"
  }, [_c('div', {
    staticClass: "infos-ticket"
  }, [_c('div', {
    staticClass: "categoria-ticket"
  }, [_c('img', {
    staticClass: "ticket-img",
    attrs: {
      "src": require("@/assets/ticket.png"),
      "alt": "cupom"
    }
  }), _c('div', {
    staticClass: "detalhes-ingresso"
  }, [_c('div', {
    staticClass: "titulo-ingresso"
  }, [_c('span', [_vm._v(_vm._s(_vm.ticket.name))])]), _c('div', {
    staticClass: "valor-ingresso"
  }, [_c('span', [_vm._v(_vm._s(_vm._f("currency")(_vm.ticket.amount_ticket)))])])])]), _vm._m(0)])])])]), _c('div', {
    staticClass: "divisor"
  }), _c('div', {
    staticClass: "text-label"
  }, [_vm._v("Informações do participante")]), _c('div', {
    staticClass: "text-label text-label-small"
  }, [_vm._v(" Confirme os dados do participante abaixo: ")]), _c('div', {
    staticClass: "row"
  }, [_c('IText', {
    ref: "name",
    staticClass: "col-lg",
    attrs: {
      "is-disabled": "",
      "label": "Nome completo"
    },
    model: {
      value: _vm.inputData.name,
      callback: function callback($$v) {
        _vm.$set(_vm.inputData, "name", $$v);
      },
      expression: "inputData.name"
    }
  }), _c('ICpf', {
    ref: "cpf",
    staticClass: "col-lg-4",
    attrs: {
      "is-disabled": "",
      "label": "CPF"
    },
    model: {
      value: _vm.inputData.cpf,
      callback: function callback($$v) {
        _vm.$set(_vm.inputData, "cpf", $$v);
      },
      expression: "inputData.cpf"
    }
  })], 1), _c('div', {
    staticClass: "row"
  }, [_c('ITel', {
    ref: "phone",
    staticClass: "col-lg-4",
    attrs: {
      "is-disabled": "",
      "label": "Telefone"
    },
    model: {
      value: _vm.inputData.phone,
      callback: function callback($$v) {
        _vm.$set(_vm.inputData, "phone", $$v);
      },
      expression: "inputData.phone"
    }
  }), _c('IEmail', {
    ref: "email",
    staticClass: "col-lg",
    attrs: {
      "is-disabled": "",
      "label": "E-mail"
    },
    model: {
      value: _vm.inputData.email,
      callback: function callback($$v) {
        _vm.$set(_vm.inputData, "email", $$v);
      },
      expression: "inputData.email"
    }
  })], 1), _c('div', {
    staticClass: "cupom-box"
  }, [_c('button', {
    staticClass: "btn t2 btn-show-cupom",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click() {
        return _vm.toogleMostrarCupomForm();
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/voucher-de-desconto.png"),
      "alt": "desconto"
    }
  }), _vm._v(" Tem um cupom de desconto? ")]), _c('div', {
    staticClass: "form-cupom",
    class: {
      'show-form-cupom': _vm.cupomData.showCupom
    }
  }, [_c('form', {
    attrs: {
      "name": "cupom"
    },
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return function () {
          return _vm.cupomSubmitHandle();
        }.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "row"
  }, [_c('IText', {
    ref: "name",
    staticClass: "col-lg input-cupom",
    attrs: {
      "label": "Cupom",
      "is-required": false
    },
    model: {
      value: _vm.cupomData.codText,
      callback: function callback($$v) {
        _vm.$set(_vm.cupomData, "codText", $$v);
      },
      expression: "cupomData.codText"
    }
  }), _c('div', {
    staticClass: "col-lg-3 action-cupom"
  }, [_c('VueButtonSpinner', {
    staticClass: "btn t1 btn-spin",
    attrs: {
      "type": "submit",
      "disabled": _vm.cupomData.onRequest,
      "is-loading": _vm.cupomData.onRequest
    }
  }, [_vm._v("Aplicar ")])], 1)], 1)])]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.cupomData.feedback.message,
      expression: "cupomData.feedback.message"
    }],
    staticClass: "cupom-alert",
    class: {
      'fbc-success': _vm.cupomData.feedback.isSuccess,
      'fbc-invalid': _vm.cupomData.feedback.isInvalid,
      'fbc-old': _vm.cupomData.feedback.isOld
    }
  }, [_c('span', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.cupomData.feedback.isSuccess,
      expression: "cupomData.feedback.isSuccess"
    }]
  }, [_vm._v("Cupom aplicado com sucesso!")]), _c('span', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.cupomData.feedback.isInvalid,
      expression: "cupomData.feedback.isInvalid"
    }]
  }, [_vm._v("Cupom inválido!")]), _c('span', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.cupomData.feedback.isOld,
      expression: "cupomData.feedback.isOld"
    }]
  }, [_vm._v("Limite de uso do cupom atingido!")])])]), _c('div', {
    attrs: {
      "hidden": !_vm.showFormPagamento
    }
  }, [_c('div', {
    staticClass: "text-label"
  }, [_vm._v("Escola a sua forma de pagamento")]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "info-pagamento"
  }, [_c('div', {
    staticClass: "payment-options"
  }, [_c('span', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.inputData.showMAG,
      expression: "inputData.showMAG"
    }],
    staticClass: "button-payment tablinks",
    class: {
      'active': _vm.activePayment === 'carteiramag'
    },
    on: {
      "click": function click($event) {
        return _vm.openPayment('carteiramag');
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/carteiramag.svg"),
      "alt": ""
    }
  }), _c('span', {
    staticClass: "title-payment"
  }, [_vm._v("Carteira MAG")])]), _c('span', {
    staticClass: "button-payment tablinks",
    class: {
      'active': _vm.activePayment === 'creditcard'
    },
    attrs: {
      "id": "defaultOpen"
    },
    on: {
      "click": function click($event) {
        return _vm.openPayment('creditcard');
      }
    }
  }, [_c('svg', {
    attrs: {
      "width": "23px",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 64 64",
      "aria-labelledby": "title",
      "aria-describedby": "desc",
      "role": "img",
      "xmlns:xlink": "http://www.w3.org/1999/xlink"
    }
  }, [_c('path', {
    attrs: {
      "data-name": "layer2",
      "d": "M62 16a3 3 0 0 0-3-3H5a3 3 0 0 0-3 3v7h60zM2 23v25a3 3 0 0 0 3 3h54a3 3 0 0 0 3-3V23",
      "fill": "none",
      "stroke": "#202020",
      "stroke-miterlimit": "10",
      "stroke-width": "2",
      "stroke-linejoin": "round",
      "stroke-linecap": "round"
    }
  }), _c('path', {
    attrs: {
      "data-name": "layer1",
      "fill": "none",
      "stroke": "#202020",
      "stroke-miterlimit": "10",
      "stroke-width": "2",
      "d": "M10 31h20m18 0h6m-44 8h8",
      "stroke-linejoin": "round",
      "stroke-linecap": "round"
    }
  })]), _c('span', {
    staticClass: "title-payment"
  }, [_vm._v("Cartão de crédito")])]), _c('span', {
    staticClass: "button-payment tablinks",
    class: {
      'active': _vm.activePayment === 'pix'
    },
    on: {
      "click": function click($event) {
        return _vm.openPayment('pix');
      }
    }
  }, [_c('svg', {
    attrs: {
      "width": "23px",
      "height": "22",
      "viewBox": "0 0 22 23",
      "preserveAspectRatio": "xMidYMin",
      "provider": "",
      "fill": "rgba(145, 145, 145, 0.77)",
      "data-v-ec74aa6c": "",
      "data-v-41327139": ""
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M5.19223 5.24323C6.06969 5.24323 6.89487 5.58498 7.51525 6.20516L10.8818 9.57225C11.1243 9.8147 11.5202 9.81575 11.7633 9.57193L15.1175 6.21736C15.738 5.59718 16.5632 5.25554 17.4407 5.25554H17.8447L13.5842 0.995107C12.2574 -0.331702 10.1063 -0.331702 8.77948 0.995107L4.53135 5.24323H5.19223ZM17.4406 17.108C16.5632 17.108 15.738 16.7664 15.1176 16.1462L11.7632 12.792C11.5278 12.5558 11.1173 12.5565 10.8819 12.792L7.51531 16.1585C6.89482 16.7786 6.06964 17.1202 5.19219 17.1202H4.5312L8.77943 21.3686C10.1062 22.6953 12.2574 22.6953 13.5842 21.3686L17.8447 17.108H17.4406ZM18.794 6.20484L21.3686 8.77947C22.6954 10.1062 22.6954 12.2573 21.3686 13.5842L18.7941 16.1587C18.7373 16.1359 18.6761 16.1218 18.6112 16.1218H17.4407C16.8354 16.1218 16.243 15.8764 15.8154 15.4484L12.4611 12.0945C11.8532 11.4859 10.7925 11.4862 10.184 12.0942L6.81744 15.4607C6.38976 15.8886 5.79746 16.134 5.19222 16.134H3.75286C3.69154 16.134 3.634 16.1486 3.57983 16.169L0.995108 13.5842C-0.331703 12.2573 -0.331703 10.1062 0.995108 8.77947L3.57994 6.19464C3.63411 6.21504 3.69154 6.22956 3.75286 6.22956H5.19222C5.79746 6.22956 6.38976 6.47496 6.81744 6.90285L10.1843 10.2697C10.4982 10.5833 10.9103 10.7404 11.3227 10.7404C11.7349 10.7404 12.1473 10.5833 12.4611 10.2694L15.8154 6.91505C16.243 6.48716 16.8354 6.24176 17.4407 6.24176H18.6112C18.676 6.24176 18.7373 6.22756 18.794 6.20484Z",
      "data-v-ec74aa6c": ""
    }
  })]), _c('span', {
    staticClass: "title-payment"
  }, [_vm._v("PIX")])])]), _c('div', {
    staticClass: "option-payment"
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.activePayment === 'creditcard',
      expression: "activePayment === 'creditcard'"
    }],
    staticClass: "tabpayment",
    attrs: {
      "id": "creditcard"
    }
  }, [_c('div', {
    staticClass: "creditcard-area"
  }, [_c('form', {
    staticClass: "creditcard-inputs",
    attrs: {
      "id": "cc-form",
      "action": ""
    }
  }, [_c('div', {
    staticClass: "input-group"
  }, [_c('label', {
    staticClass: "label"
  }, [_vm._v("Número do cartão *")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formDataPayment.ccNumber,
      expression: "formDataPayment.ccNumber"
    }],
    staticClass: "input-group",
    attrs: {
      "type": "text",
      "name": "number",
      "id": "cc-number",
      "placeholder": "Digite somente números"
    },
    domProps: {
      "value": _vm.formDataPayment.ccNumber
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.formDataPayment, "ccNumber", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "input-group"
  }, [_c('label', {
    staticClass: "label"
  }, [_vm._v("Nome do titular *")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formDataPayment.ccName,
      expression: "formDataPayment.ccName"
    }],
    staticClass: "input-group",
    attrs: {
      "type": "text",
      "name": "name",
      "id": "cc-name",
      "placeholder": "Digite o nome impresso no cartão"
    },
    domProps: {
      "value": _vm.formDataPayment.ccName
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.formDataPayment, "ccName", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "data-cartao"
  }, [_c('div', {
    staticClass: "input-group"
  }, [_c('label', {
    staticClass: "label"
  }, [_vm._v("Data de expiração *")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formDataPayment.ccExpiry,
      expression: "formDataPayment.ccExpiry"
    }],
    attrs: {
      "placeholder": "MM/AAAA",
      "id": "cc-expiration",
      "name": "expiry"
    },
    domProps: {
      "value": _vm.formDataPayment.ccExpiry
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.formDataPayment, "ccExpiry", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "input-group"
  }, [_c('label', {
    staticClass: "label"
  }, [_vm._v("CVV *")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formDataPayment.ccCVV,
      expression: "formDataPayment.ccCVV"
    }],
    attrs: {
      "type": "text",
      "name": "cvc",
      "id": "cc-cvv",
      "placeholder": "CVV"
    },
    domProps: {
      "value": _vm.formDataPayment.ccCVV
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.formDataPayment, "ccCVV", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "col-lg-5"
  }, [_c('i-doc-cpf-cnpj', {
    ref: "inputDataPaymentCcCPF",
    attrs: {
      "label": "CPF/CNPJ do titular",
      "is-required": ""
    },
    model: {
      value: _vm.formDataPayment.ccCPF,
      callback: function callback($$v) {
        _vm.$set(_vm.formDataPayment, "ccCPF", $$v);
      },
      expression: "formDataPayment.ccCPF"
    }
  })], 1), _c('div', {
    staticClass: "input-group"
  }, [_c('label', {
    staticClass: "label"
  }, [_vm._v("Selecione o número de parcelas *")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formDataPayment.parcelas,
      expression: "formDataPayment.parcelas"
    }],
    staticClass: "select-style input-group",
    on: {
      "change": function change($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });

        _vm.$set(_vm.formDataPayment, "parcelas", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": "1"
    }
  }, [_vm._v("1x à vista")]), _c('option', {
    attrs: {
      "value": "2"
    }
  }, [_vm._v("2x Parcelas")]), _c('option', {
    attrs: {
      "value": "3"
    }
  }, [_vm._v("3x Parcelas")])])])]), _vm._m(1)])]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.activePayment === 'pix',
      expression: "activePayment === 'pix'"
    }],
    staticClass: "tabpayment",
    attrs: {
      "id": "pix"
    }
  }, [_vm.pixEtapa.showForm ? _c('div', {
    staticClass: "pix-payment-box"
  }, [_c('form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return function () {
          return _vm.formPixSubmitHandle();
        }.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "row"
  }, [_c('IText', {
    staticClass: "col-12",
    attrs: {
      "label": "Nome completo do titular",
      "is-required": ""
    },
    model: {
      value: _vm.formDataPayment.pixName,
      callback: function callback($$v) {
        _vm.$set(_vm.formDataPayment, "pixName", $$v);
      },
      expression: "formDataPayment.pixName"
    }
  }), _c('div', {
    staticClass: "col-lg-5"
  }, [_c('i-doc-cpf-cnpj', {
    ref: "inputPaymentPixDoc",
    attrs: {
      "label": "CPF/CNPJ do titular",
      "is-required": ""
    },
    model: {
      value: _vm.formDataPayment.pixDoc,
      callback: function callback($$v) {
        _vm.$set(_vm.formDataPayment, "pixDoc", $$v);
      },
      expression: "formDataPayment.pixDoc"
    }
  })], 1)], 1), _c('VueButtonSpinner', {
    staticClass: "btn t1 btn-spin",
    attrs: {
      "type": "submit",
      "disabled": _vm.pixEtapa.onRequest,
      "is-loading": _vm.pixEtapa.onRequest
    }
  }, [_vm._v("Enviar ")])], 1)]) : _c('div', {
    staticClass: "pix-payment-box"
  }, [_c('div', {
    staticClass: "title-pp"
  }, [_vm._v(" Escaneie este código para pagar ")]), _c('img', {
    staticClass: "img-pp",
    attrs: {
      "src": _vm.pixPaymentData.imgSrc,
      "alt": "qr-code"
    }
  }), _vm._m(2), _c('hr'), _c('div', {
    staticClass: "title-pp"
  }, [_vm._v(" Ou copie este código QR para fazer o pagamento ")]), _c('div', {
    staticClass: "subtitle-pp"
  }, [_vm._v(" Escolha pagar via Pix pelo seu Internet Banking ou app de pagamentos. Depois, cole o seguinte código: ")]), _c('div', {
    staticClass: "pix-code-box"
  }, [_vm._v(" " + _vm._s(_vm.pixPaymentData.codigo) + " ")]), _c('div', {
    staticClass: "action-pp"
  }, [_c('button', {
    staticClass: "btn t1",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click() {
        return _vm.copyPixCodeClickHandle();
      }
    }
  }, [_vm._v(" Copiar código ")])])])]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.activePayment === 'carteiramag',
      expression: "activePayment === 'carteiramag'"
    }],
    staticClass: "tabpayment",
    attrs: {
      "id": "carteiramag"
    }
  }, [_c('div', {
    staticClass: "carteiramag-box"
  }, [_c('div', {}, [_c('label', {
    staticClass: "label"
  }, [_vm._v("Selecione o número de parcelas *")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formDataPayment.parcelas,
      expression: "formDataPayment.parcelas"
    }],
    staticClass: "select-style input-group",
    on: {
      "change": function change($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });

        _vm.$set(_vm.formDataPayment, "parcelas", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": "1"
    }
  }, [_vm._v("1x à vista")]), _c('option', {
    attrs: {
      "value": "2"
    }
  }, [_vm._v("2x Parcelas")]), _c('option', {
    attrs: {
      "value": "3"
    }
  }, [_vm._v("3x Parcelas")])])])])])])])])]), _c('div', {
    staticClass: "text-label"
  }, [_vm._v("Detalhes da compra")]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "titulo-compra"
  }, [_c('div', {
    staticClass: "ticket-compra"
  }, [_c('p', {
    staticClass: "label-form"
  }, [_vm._v(_vm._s(_vm.ticket.name))]), _c('p', {
    staticClass: "label-form"
  }, [_vm._v("Descontos")]), _c('p', {
    staticClass: "label-form"
  }, [_vm._v("Total")])]), _c('div', {
    staticClass: "valor-compra"
  }, [_c('p', {
    staticClass: "label-form"
  }, [_vm._v(_vm._s(_vm._f("currency")(_vm.ticket.amount_ticket)))]), _c('p', {
    staticClass: "label-form"
  }, [_vm._v(_vm._s(_vm._f("currency")(_vm.discountTicket.absoluteValue)))]), _c('p', {
    staticClass: "label-form"
  }, [_vm._v(_vm._s(_vm._f("currency")(_vm.discountTicket.finalValue)))])])])]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.activePayment !== 'pix',
      expression: "activePayment !== 'pix'"
    }],
    staticClass: "row"
  }, [_c('div', {
    staticClass: "btn-comprar"
  }, [_c('VueButtonSpinner', {
    staticClass: "botao-comprar btn-spin",
    attrs: {
      "type": "submit",
      "disabled": _vm.isCheckoutRunning,
      "is-loading": _vm.isCheckoutRunning
    }
  }, [_vm._v("Comprar agora ")])], 1)]), _vm._m(3)])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "processamento"
  }, [_c('div', {
    staticClass: "detalhes-processamento"
  }, [_c('div', {
    staticClass: "processado"
  }, [_c('span', [_vm._v("Processado por")])]), _c('div', {
    staticClass: "gateway-img"
  }, [_c('img', {
    staticClass: "img-processador",
    attrs: {
      "src": require("@/assets/mag-financas.png"),
      "alt": ""
    }
  })])])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "creditcard-view"
  }, [_c('div', {
    staticClass: "card-wrapper"
  })]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "instruction-list-pp"
  }, [_c('div', [_c('span', [_vm._v("Acesse seu Internet Banking ou app de pagamentos.")]), _c('span', [_vm._v("Escolha pagar via Pix.")]), _c('span', [_vm._v("Escaneie o código mostrado:")])])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row"
  }, [_c('footer', {
    staticClass: "footer"
  }, [_c('p', [_vm._v("Precisa de ajuda? "), _c('a', [_vm._v("Entre em contato")])]), _c('p', [_vm._v("Ao clicar em 'Comprar agora', eu concordo "), _c('br'), _vm._v("(i) que a MAG Finanças está processando este pedido; "), _c('br'), _vm._v("(ii) com os Termos de Compra "), _c('br'), _vm._v("(iii) que li e estou ciente da Política de Privacidade e "), _c('br'), _vm._v("(iv) que sou maior de idade ou autorizado e acompanhado por um tutor legal. ")])])]);
}]

export { render, staticRenderFns }