<template>
  <div>
    <form @submit.prevent="() => dispatchSubmit()">
      <div class="disclaim">
        Por favor, para prosseguir informe o seu endereço de e-mail
        <div class="legenda-l2">
          Please enter your email address to proceed
        </div>
      </div>
      <IEmail placeholder="E-mail" v-model="dataform.email" woLabel ref="email" />
      <!-- <ICpf placeholder="CPF" v-model="dataform.cpf" woLabel ref="cpf"/> -->
    </form>
  </div>
</template>

<script>
import IEmail from "../inputs/i-email";
// import ICpf from "../inputs/i-cpf";
// import FormPagamento from "./form-pagamento";

export default {
  name: "f-identificacao",
  components: {
    IEmail
  },
  data: () => ({
    dataform: {
      email: null,
      // cpf: null,
    }
  }),
  methods: {
    getData() {
      return this.dataform.email;
    },
    isValid() {
      return this.$refs.email.isValid();
    },
    dispatchSubmit() {
      if (!this.isValid()) {
        return;
      }
      this.$emit('submit');
    },
  },
}
</script>

<style scoped lang="scss">
@import "../../styles/color";

.disclaim {
  color: $black3;
  font-size: 16px;
  display: block;
  margin-bottom: 12px;
  @media (max-width: 812px) {
    font-size: 14px;
  }
}

.legenda-l2 {
  font-size: 0.95em;
  opacity: 0.8;
}
</style>
