import fetchPaymentPix from "./fetch/fetchPaymentPix";
import fetchCheckPix from "./fetch/fetchCheckPix";

export async function gerarPix (input) {
    const resp = await fetchPaymentPix(
        input.participanteId,
        input.ticketId,
        input.cupomId,
        input.name,
        input.doc,
    );

    if (!resp.ok) {
        throw {cod: '1GKTE50H'};
    }

    const respBody = await resp.json();
    if (!respBody.success) {
        throw {cod: '9OLC248E'};
    }

    return {
        imageData: `data:${respBody.generated_image.mime_type};base64,${respBody.generated_image.image_content}`,
        codigoContent: respBody.text_content,
        paymentId: respBody.reconciliation_id,
    };
}

export async function validPixPayment(paymentId) {
    const resp = await fetchCheckPix(paymentId);

    const respBody = await resp.json();

    return {
        checked: Boolean(respBody.data?.status),
    };
}