var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return function () {
          return _vm.dispatchSubmit();
        }.apply(null, arguments);
      }
    }
  }, [_vm._m(0), _c('IEmail', {
    ref: "email",
    attrs: {
      "placeholder": "E-mail",
      "woLabel": ""
    },
    model: {
      value: _vm.dataform.email,
      callback: function callback($$v) {
        _vm.$set(_vm.dataform, "email", $$v);
      },
      expression: "dataform.email"
    }
  })], 1)]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "disclaim"
  }, [_vm._v(" Por favor, para prosseguir informe o seu endereço de e-mail "), _c('div', {
    staticClass: "legenda-l2"
  }, [_vm._v(" Please enter your email address to proceed ")])]);
}]

export { render, staticRenderFns }