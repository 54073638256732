import fetchCheckout from "./fetch/fetchCheckout";

function getTypePayment(isCarteira, isCredCard, isPix) {
    if (isCarteira) {
        return '1';
    }
    if (isCredCard) {
        return '2';
    }
    if (isPix) {
        return '3'
    }
    return '';
}

export async function submitCheckout(
    {
        participanteId,
        type: {isCarteira, isCredCard, isPix},
        cupomId,
        cupomCode,
        ticketId,
        ticketAmount,
        ticketTotal,
        discount,
        parcelasQtd,
        cardNumber,
        cardName,
        cardExpDate,
        cardSecurityCode,
        cardCPF,
        cardBrand,
    }
) {
    const postData = {
        id: participanteId,
        type_payment: getTypePayment(isCarteira, isCredCard, isPix),
        ticket_id: ticketId,
        cupom_id: cupomId,
        cupom: cupomCode,
        ticket_amount: ticketAmount,
        discount: discount,
        total_ticket: ticketTotal,
        payment: {
            qty_quota: isPix ? 1 : parcelasQtd,
            card_number: cardNumber,
            card_name: cardName,
            card_expiring_date: cardExpDate,
            card_cpf: cardCPF,
            card_security: cardSecurityCode,
            card_brand: cardBrand,
        },
    }
    const resp = await fetchCheckout(postData);
    if (!resp.ok) {
        let body;
        try {
            body = await resp.json()
        } catch {
            throw {cod: '4SRQ563H'}
        }
        throw {cod: '908RNVAM', cause: body.message};
    }
    return {ok: true};
}