<template>
  <form @submit.prevent="() => dispatchSubmit()">
    <div>
      <div class="row">
        <IText
            is-disabled
            v-model="formData.name" ref="name" class="col-lg" label="Nome completo"/>
      </div>
      <div class="row">
        <ITel
            :is-disabled="formIsDisabled"
            v-model="formData.phone" ref="phone" class="col-lg-4" label="Telefone"/>
        <IEmail
            is-disabled
            v-model="formData.email" ref="email" class="col-lg" label="E-mail"/>
      </div>
      <div class="row">
        <ICpf
            :is-disabled="formIsDisabled"
            v-model="formData.cpf" ref="cpf" class="col-lg-4" label="CPF"/>
        <IDate
            :is-disabled="formIsDisabled"
            v-model="formData.birth_date" ref="birth_date" class="col-lg-3" label="Data de Nascimento"/>
        <IText
            :is-disabled="formIsDisabled"
            v-model="formData.identification_doc" ref="identification_doc" class="col-lg-5" label="Número de Identidade"/>
      </div>
      <div class="row">
        <ICep
            :is-disabled="formIsDisabled"
            v-model="formData.zip_code" ref="zip_code" class="col-lg-2" label="CEP"
            @update-address="(data) => updateAddress(data)"
        />
        <IUf
            :is-disabled="formIsDisabled"
            v-model="formData.state" ref="state" class="custom-col-uf" label="UF"/>
        <IText
            :is-disabled="formIsDisabled"
            v-model="formData.city" ref="city" class="col-lg-4" label="Cidade"/>
        <IText
            :is-disabled="formIsDisabled"
            v-model="formData.address" ref="address" class="col-lg" label="Endereço"/>
      </div>
      <div class="row">
        <IText
            :is-disabled="formIsDisabled"
            v-model="formData.address_complement" ref="address_complement" class="col-lg" label="Complemento"/>
      </div>
      <div class="row">
        <IText
            :is-disabled="formIsDisabled"
            v-model="formData.mag_estabelecimento" ref="mag_estabelecimento" class="col-lg" label="Unidade"/>
      </div>
    </div>
  </form>
</template>

<script>
import IText from "../inputs/i-text";
import ITel from "../inputs/i-tel";
import IEmail from "../inputs/i-email";
import ICpf from "../inputs/i-cpf";
import IDate from "../inputs/i-date";
import ICep from "../inputs/i-cep";
import IUf from "../inputs/i-uf";

export default {
  name: "form-f12",
  components: {IUf, ICep, IDate, ICpf, IEmail, ITel, IText},
  data: () => ({
    formData: {
      name: null,
      phone: null,
      email: null,
      cpf: null,
      birth_date: null,
      identification_doc: null,
      zip_code: null,
      state: null,
      city: null,
      address: null,
      address_complement: null,
      mag_estabelecimento: null,
    },
    formIsDisabled: false,
  }),
  methods: {
    getValue() {
      let shallowCopy;
      shallowCopy = {...this.formData};

      return shallowCopy;
    },
    isValid() {
      const normalRef = this.getNormalRef();
      return normalRef.reduce((last, ref) => ref?.isValid() && last, true);
    },
    setNameEmail(name, email) {
      this.formData.name = name;
      this.formData.email = email;
    },
    setDisabled(isDisabled) {
      this.formIsDisabled = isDisabled;
    },
    getNormalRef() {
      return [
        this.$refs.name,
        this.$refs.phone,
        this.$refs.email,
        this.$refs.cpf,
        this.$refs.birth_date,
        this.$refs.identification_doc,
        this.$refs.zip_code,
        this.$refs.state,
        this.$refs.city,
        this.$refs.address,
        this.$refs.address_complement,
        this.$refs.mag_estabelecimento,
      ];
    },
    dispatchSubmit() {
      if (this.isValid()) {
        this.$emit('submit');
      }
    },
    updateAddress(addressData) {
      this.formData.state = addressData.state;
      this.formData.city = addressData.city;
      this.formData.address = addressData.address;
    },
  },
}
</script>

<style scoped>
.i-file {
  margin-bottom: 1rem;
  max-width: 520px;
}

.c-s {
  margin-top: 2.5rem;
}
</style>
