import fetchCupom from "./fetch/fetchCupom";

export async function validCupom(cupom) {
    const resp = await fetchCupom({cupom});
    if (!resp.ok) {
        const respBody = await resp.json();
        throw {cod: '4G51C3MP', respFail: true, message: respBody.message}
    }

    const respBody = await resp.json();
    if (respBody.message) {
        throw {respFail: true, message: respBody.message, cod: '19NCRQKS'};
    }

    return {
        cupomId: respBody.id,
        cupomDiscount: respBody.discount / 100,
    }
}