<template>
  <div class="body">
    <div class="header">
      <div style="display: flex;justify-content: center;">
        <img src="../assets/mag-cruise-logo.png" alt="Logo" class="logo" style="">
      </div>
      <div class="subtitle-he" style="">
        <a style="font-weight: 500;">8 a 11 de janeiro de 2025 - MSC ORCHESTRA </a>
      </div>
    </div>
    <div class="content">
      <div class="headline">
        {{ $t('') }}
      </div>
      <div class="form-container">
        <RouterView/>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "BaseLayout",
}
</script>

<style scoped lang="scss">
@import "../styles/color";

.body {
  background-color: $bg1;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.subtitle-he {
  color: #fff;
  font-size: 22.5px;
  text-align: center;
}

.header {
  $h: 164px;
  height: $h;
  flex: 0 0 164px;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.13);
  background: url('../assets/mag-cruise-bg.jpg');
  background-position: center;
  background-size: 100% 100%;
  align-content: center;
  display: grid;
  // place-items: center;

  img.logo {
    max-height: $h - 100px;
    object-fit: contain;
    @media (max-width: 812px) {
      max-height: $h - 134px;
    }
  }

  @media (max-width: 812px) {
    $mobile-h: 80px; // Ajuste a altura conforme necessário
    height: $mobile-h;
    flex: 0 0 $mobile-h;

    .subtitle-he {
      font-size: 10.5px;
    }
    
    img.logo {
      max-height: $mobile-h - 50px; // Ajuste a altura conforme necessário
    }
  }
}

.content {
  width: 100%;
  max-width: 1286px;
  margin: 69px auto 100px;
  --padding-h: 28px;
  @media (max-width: 992px) {
    --padding-h: 14px;
  }
  padding-inline: var(--padding-h);
}

.headline {
  display: flex;
  align-content: center;
  color: $black1;
  font-weight: 500;
  font-size: 20px;

  img {
    $size: 61px;
    width: $size;
    height: $size;
    object-fit: cover;
  }
}

.form-container {
  margin-top: 16px;
}
</style>

<style lang="scss">
@import "../styles/color";

body {
  background-color: $bg1;
}
</style>
