export default function fetchPaymentPix(
    id,
    ticket_id,
    cupom_id,
    name,
    doc,
) {
    return fetch(process.env.VUE_APP_API_ORIGIN + '/api/paymentPix', {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify({
            id,
            type_payment: '3',
            ticket_id,
            cupom_id,
            name,
            doc
        }),
    });
}