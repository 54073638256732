<template>
  <div>
    <div class="input-group" :class="{'input-group-invalid': isInvalid, 'input-group-disabled': isDisabled}">
      <div>
        <span class="label" :class="{'required': isRequired}"><slot>{{ label }}</slot></span>
        <VueTelInput
            :disabled="isDisabled"
            :placeholder="placeholder"
            :inputOptions="options"
            v-model="inputValue"
            @blur="() => validate()"
            @input="(number, phoneData) => inputHandle(number, phoneData)"
        />
        <!--      <input-->
        <!--          v-mask="mask"-->
        <!--          type="tel"-->
        <!--          :disabled="isDisabled"-->
        <!--          :placeholder="placeholder"-->
        <!--          v-model="inputValue"-->
        <!--          @blur="() => validate()"-->
        <!--          @input="() => inputHandle()"-->
        <!--      >-->
      </div>
    </div>
    <!--    <VueTelInput-->
    <!--        :disabled="isDisabled"-->
    <!--        :placeholder="placeholder"-->
    <!--        v-model="inputValue"-->
    <!--        @blur="() => validate()"-->
    <!--        @input="() => inputHandle()"-->
    <!--    />-->
  </div>

</template>

<script>
import {VueTelInput} from 'vue-tel-input';
import {defaultProps} from "./defaultProps";

export default {
  name: "i-tel-cce",
  props: {
    ...defaultProps
  },
  components: {
    VueTelInput,
  },
  data: () => ({
    options: { placeholder: 'Phone number'},
    telInputData: {number: null, phoneData: null},
    inputValue: null,
    isInvalid: false,
    hasTouched: false,
  }),
  methods: {
    inputHandle(number, phoneData) {
      this.telInputData.number = number;
      this.telInputData.phoneData = phoneData;
      console.log(phoneData)
      this.hasTouched = true;
      this.$emit('input', this.inputValue);
      this.$emit('country-code', phoneData.countryCallingCode)
    },
    validate() {

      this.isInvalid = ((!this.telInputData.phoneData?.valid && this.inputValue) && this.hasTouched)
          || (this.hasTouched && this.inputValue === null && this.isRequired)
          || (this.isRequired && !this.inputValue);
    },
    clean() {
      this.inputValue = null;
      this.isInvalid = false;
      this.hasTouched = false;
    },
    isValid() {
      this.validate();
      return !this.isInvalid;
    },
  },
  watch: {
    value: {
      handler(v) {
        this.inputValue = v;
      },
      immediate: true,
    },
  },
}
</script>

<style scoped>

</style>
