var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "input-group",
    class: {
      'input-group-invalid': _vm.isInvalid,
      'input-group-disabled': _vm.isDisabled
    }
  }, [_c('label', [_c('span', {
    staticClass: "label",
    class: {
      'required': _vm.isRequired
    }
  }, [_vm._t("default", function () {
    return [_vm._v(_vm._s(_vm.label))];
  })], 2), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.inputValue,
      expression: "inputValue"
    }],
    attrs: {
      "type": "text",
      "disabled": _vm.isDisabled,
      "placeholder": _vm.placeholder,
      "maxlength": "2",
      "list": _vm.datalistId
    },
    domProps: {
      "value": _vm.inputValue
    },
    on: {
      "blur": function blur() {
        return _vm.validate();
      },
      "input": [function ($event) {
        if ($event.target.composing) { return; }
        _vm.inputValue = $event.target.value;
      }, function () {
        return _vm.inputHandle();
      }]
    }
  }), _c('datalist', {
    attrs: {
      "id": _vm.datalistId
    }
  }, [_c('option', {
    attrs: {
      "value": "SP"
    }
  }), _c('option', {
    attrs: {
      "value": "RJ"
    }
  }), _c('option', {
    attrs: {
      "value": "MG"
    }
  }), _c('option', {
    attrs: {
      "value": "RS"
    }
  }), _c('option', {
    attrs: {
      "value": "PR"
    }
  }), _c('option', {
    attrs: {
      "value": "SC"
    }
  }), _c('option', {
    attrs: {
      "value": "BA"
    }
  }), _c('option', {
    attrs: {
      "value": "DF"
    }
  }), _c('option', {
    attrs: {
      "value": "GO"
    }
  }), _c('option', {
    attrs: {
      "value": "PE"
    }
  }), _c('option', {
    attrs: {
      "value": "PA"
    }
  }), _c('option', {
    attrs: {
      "value": "CE"
    }
  }), _c('option', {
    attrs: {
      "value": "ES"
    }
  }), _c('option', {
    attrs: {
      "value": "MT"
    }
  }), _c('option', {
    attrs: {
      "value": "AM"
    }
  }), _c('option', {
    attrs: {
      "value": "MS"
    }
  }), _c('option', {
    attrs: {
      "value": "MA"
    }
  }), _c('option', {
    attrs: {
      "value": "RN"
    }
  }), _c('option', {
    attrs: {
      "value": "PB"
    }
  }), _c('option', {
    attrs: {
      "value": "AL"
    }
  }), _c('option', {
    attrs: {
      "value": "PI"
    }
  }), _c('option', {
    attrs: {
      "value": "SE"
    }
  }), _c('option', {
    attrs: {
      "value": "RO"
    }
  }), _c('option', {
    attrs: {
      "value": "TO"
    }
  }), _c('option', {
    attrs: {
      "value": "AP"
    }
  }), _c('option', {
    attrs: {
      "value": "AC"
    }
  }), _c('option', {
    attrs: {
      "value": "RR"
    }
  })])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }