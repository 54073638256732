<template>
  <div class="input-group" :class="{'input-group-invalid': isInvalid, 'input-group-disabled': isDisabled}">
    <label>
      <span class="label" :class="{'required': isRequired}"><slot>{{ label }}</slot></span>
      <input
        v-mask="mask"
        type="text"
        inputmode="numeric"
        :disabled="isDisabled"
        :placeholder="placeholder"
        v-model="inputValue"
        @blur="validate"
        @input="inputHandle"
      >
      <div v-if="isInvalid" class="error-message">{{ errorMessage }}</div>
    </label>
  </div>
</template>

<script>
import { VueMaskDirective } from 'v-mask';
import { defaultProps } from "./defaultProps";
import { validDate } from "../../services/validation/validDate";

export default {
  name: "i-datetransp",
  props: {
    ...defaultProps,
    returnDate: {
      type: String,
      default: null,
    }
  },
  data: () => ({
    mask: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
    inputValue: null,
    isInvalid: false,
    hasTouched: false,
  }),
  directives: {
    mask: VueMaskDirective
  },
  computed: {
    errorMessage() {
      // if (this.isInvalid && !this.isValidDate()) {
      //   return "Datas permitida: 10/01/2024 à 14/01/2024";
      // }
      if (this.isInvalid && this.isAfterReturnDate()) {
        return "Data de retorno não pode ser inferior a data de embarque";
      }
      // Adicione mais mensagens de erro conforme necessário
      return "";
    },
  },
  methods: {
    inputHandle() {
      this.hasTouched = true;
      this.$emit('input', this.inputValue);
    },
    validate() {
      this.isInvalid = (!this.isValidDate() && this.hasTouched)
        || (this.hasTouched && this.inputValue === null)
        || (this.isRequired && !this.inputValue)
        || this.isAfterReturnDate();
    },
    clean() {
      this.inputValue = null;
      this.isInvalid = false;
      this.hasTouched = false;
    },
    isValid() {
      this.validate();
      return !this.isInvalid;
    },
    isValidDate() {
      return validDate(this.inputValue); // && this.isDateInRange(this.inputValue);
    },
    isDateInRange(dateString) {
      if (!dateString) {
        return true;
      }
      const dateFormUSA = dateString.split(" ")[0].split("/").reverse().join('-');
      const date = new Date(dateFormUSA);
      const startDate = new Date('2024-01-10');
      const endDate = new Date('2024-01-14');
      return startDate <= date && date <= endDate;
    },
    isAfterReturnDate() {
      if (!this.inputValue || !this.returnDate) {
        return false;
      }
      const boardingDate = new Date(this.inputValue.split('/').reverse().join('-'));
      const returnDate = new Date(this.returnDate.split('/').reverse().join('-'));
      return boardingDate < returnDate;
    }
  },
  watch: {
    value: {
      handler(v) {
        this.inputValue = v;
      },
      immediate: true,
    },
  },
}
</script>

<style scoped>
.error-message {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}
</style>