<template>
  <div class="input-group" :class="{'input-group-invalid': isInvalid, 'input-group-disabled': isDisabled}">
    <label>
      <span class="label" :class="{'required': isRequired && !woLabel}"><slot>{{ label }}</slot></span>
      <input
          v-mask="['###.###.###-##', '##.###.###/####-##']"
          type="tel"
          :disabled="isDisabled"
          :placeholder="placeholder"
          v-model="inputValue"
          @blur="() => validate()"
          @input="() => inputHandle()"
      >
    </label>
  </div>
</template>

<script>
import {mask} from 'vue-the-mask'
import {defaultProps} from "./defaultProps";
import {validCPF} from "../../services/validation/validCPF";
import {validCNPJ} from "../../services/validation/validCNPJ";

function isCPF(input) {
  return `${input}`.length < 15;
}

export default {
  name: "i-doc-cpf-cnpj",
  props: {...defaultProps},
  directives: {mask},
  data: () => ({
    inputValue: null,
    isInvalid: false,
    hasTouched: false,
    woLabel: Boolean,
  }),
  methods: {
    inputHandle() {
      this.hasTouched = true;
      this.$emit('input', this.inputValue);
    },
    validate() {
      const validInput = isCPF(this.inputValue) ? validCPF(this.inputValue) : validCNPJ(this.inputValue);
      this.isInvalid = (this.hasTouched && !validInput)
          || (this.hasTouched && this.inputValue === null)
          || (this.isRequired && !this.inputValue);
    },
    clean() {
      this.inputValue = null;
      this.isInvalid = false;
      this.hasTouched = false;
    },
    isValid() {
      this.validate();
      return !this.isInvalid;
    },
  },
  watch: {
    value: {
      handler(v) {
        this.inputValue = v;
      },
      immediate: true,
    },
  },
}
</script>

<style scoped>

</style>
