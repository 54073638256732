<template>
  <IForm :has-identificacao="true"/>
</template>

<script>
import IForm from "../components/i-form";

export default {
  name: "home-view",
  components: {IForm}
}
</script>

<style scoped>

</style>
