var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "input-group-file",
    class: {
      'input-group-invalid': _vm.isInvalid,
      'input-group-disabled': _vm.isDisabled
    }
  }, [_c('div', {
    staticClass: "label-box"
  }, [_c('label', {
    staticClass: "icon-btn"
  }, [_c('img', {
    attrs: {
      "src": require("../../assets/icon-upload.png"),
      "alt": "upload"
    }
  }), _vm._v(" " + _vm._s(_vm.$t('Procurar')) + " "), _c('input', {
    ref: "input",
    attrs: {
      "type": "file",
      "accept": "application/pdf,image/jpeg,image/jpg,image/gif,image/png",
      "disabled": _vm.isDisabled,
      "placeholder": _vm.placeholder
    },
    on: {
      "blur": function blur() {
        return _vm.validate();
      },
      "change": function change() {
        return _vm.inputHandle();
      }
    }
  })]), _c('span', {
    staticClass: "label",
    class: {
      'required': _vm.isRequired
    }
  }, [_vm._t("default", function () {
    return [_vm._v(_vm._s(_vm.label))];
  })], 2)]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.hasUpload,
      expression: "hasUpload"
    }],
    staticClass: "upload-show"
  }, [_c('div', {
    staticClass: "name"
  }, [_vm._v(_vm._s(_vm.uploadName))]), _c('button', {
    staticClass: "btn t-remover",
    attrs: {
      "type": "button",
      "disabled": _vm.isDisabled
    },
    on: {
      "click": function click() {
        return _vm.clearUpload();
      }
    }
  }, [_vm._v("Remover ✗")])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }