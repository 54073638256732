import {parseDateToApi} from "../../toolbox/parseDate";

export function makeRegisterForm4(contactId, inputDataForm1, inputDataForm2) {

    let formData = new FormData();
    formData.append('id', contactId);
    formData.append('type', '4');
    formData.append('need_payment', '0');
    formData.append('name', inputDataForm1.name);
    formData.append('last_name', inputDataForm1.last_name);
    formData.append('badge_name', inputDataForm1.badge_name);
    formData.append('gender', inputDataForm1.gender);
    formData.append('nationality', inputDataForm1.nationality);
    formData.append('phone', inputDataForm1.phone);
    formData.append('email', inputDataForm1.email);
    formData.append('cpf', inputDataForm1.cpf);
    formData.append('birth_date', parseDateToApi(inputDataForm1.birth_date));
    formData.append('identification_doc', inputDataForm1.identification_doc);
    formData.append('doc_emission', parseDateToApi(inputDataForm1.doc_emission));
    formData.append('doc_validity', parseDateToApi(inputDataForm1.doc_validity));
    formData.append('doc_country', inputDataForm1.doc_country);
    formData.append('passport_doc', inputDataForm1.passport_doc);
    formData.append('birth_city', inputDataForm1.birth_city);
    formData.append('zip_code', inputDataForm1.zip_code);
    formData.append('state', inputDataForm1.state);
    formData.append('city', inputDataForm1.city);
    formData.append('address', inputDataForm1.address);
    formData.append('country', inputDataForm1.country);
    formData.append('polo_tshirt', inputDataForm1.polo_tshirt);
    formData.append('shoe_size', inputDataForm1.shoe_size);
    formData.append('address_complement', inputDataForm1.address_complement);
    if (inputDataForm1.food_restriction) {
        formData.append('food_restriction', inputDataForm1.food_restriction);
    }
    formData.append('attachments[0][type]', '1');
    formData.append('attachments[0][document]', inputDataForm1.rg_file);
    formData.append('attachments[1][type]', '3');
    formData.append('attachments[1][document]', inputDataForm1.covid_file);
    formData.append('attachments[2][type]', '2');
    formData.append('attachments[2][document]', inputDataForm1.passport_file);

    formData.append('air_transport', inputDataForm1.enableFly ? '1' : '0');
    if (inputDataForm1.enableFly) {
        formData.append('transport[departure_airport]', inputDataForm1.departure_airport);
        formData.append('transport[return_airport]', inputDataForm1.return_airport);
        formData.append('transport[boarding_date]', parseDateToApi(inputDataForm1.boarding_date));
        formData.append('transport[return_date]', parseDateToApi(inputDataForm1.return_date));
    }
    formData.append('contact[type]', inputDataForm1.contact.type);
    formData.append('contact[name]', inputDataForm1.contact.name);
    formData.append('contact[phone]', inputDataForm1.contact.phone);
    if (inputDataForm1.obs) {
        formData.append('obs', inputDataForm1.obs);
    }

    if (!inputDataForm2) {
        return formData;
    }
    formData.append('companion[need_payment]', '0');
    formData.append('companion[name]', inputDataForm2.name);
    formData.append('companion[last_name]', inputDataForm2.last_name);
    formData.append('companion[badge_name]', inputDataForm2.badge_name);
    formData.append('companion[gender]', inputDataForm2.gender);
    formData.append('companion[nationality]', inputDataForm2.nationality);
    formData.append('companion[phone]', inputDataForm2.phone);
    formData.append('companion[email]', inputDataForm2.email);
    formData.append('companion[cpf]', inputDataForm2.cpf);
    formData.append('companion[birth_date]', parseDateToApi(inputDataForm2.birth_date));
    formData.append('companion[identification_doc]', inputDataForm2.identification_doc);
    formData.append('companion[passport_doc]', inputDataForm2.passport_doc);
    formData.append('companion[doc_emission]', parseDateToApi(inputDataForm2.doc_emission));
    formData.append('companion[doc_validity]', parseDateToApi(inputDataForm2.doc_validity));
    formData.append('companion[doc_country]', inputDataForm2.doc_country);
    formData.append('companion[birth_city]', inputDataForm2.birth_city);
    formData.append('companion[zip_code]', inputDataForm2.zip_code);
    formData.append('companion[state]', inputDataForm2.state);
    formData.append('companion[city]', inputDataForm2.city);
    formData.append('companion[address]', inputDataForm2.address);
    formData.append('companion[country]', inputDataForm2.country);
    formData.append('companion[polo_tshirt]', inputDataForm2.polo_tshirt);
    formData.append('companion[shoe_size]', inputDataForm2.shoe_size);
    formData.append('companion[address_complement]', inputDataForm2.address_complement);
    if (inputDataForm2.food_restriction) {
        formData.append('companion[food_restriction]', inputDataForm2.food_restriction);
    }

    formData.append('companion[attachments][0][type]', '1');
    formData.append('companion[attachments][0][document]', inputDataForm2.rg_file);
    formData.append('companion[attachments][1][type]', '3');
    formData.append('companion[attachments][1][document]', inputDataForm2.covid_file);
    formData.append('companion[attachments][2][type]', '2');
    formData.append('companion[attachments][2][document]', inputDataForm2.passport_file);

    formData.append('companion[air_transport]', inputDataForm2.enableFly ? '1' : '0');
    if (inputDataForm2.enableFly) {
        formData.append('companion[transport][departure_airport]', inputDataForm2.departure_airport);
        formData.append('companion[transport][return_airport]', inputDataForm2.return_airport);
        formData.append('companion[transport][boarding_date]', parseDateToApi(inputDataForm2.boarding_date));
        formData.append('companion[transport][return_date]', parseDateToApi(inputDataForm2.return_date));
    }
    formData.append('companion[contact][type]', inputDataForm2.contact.type);
    formData.append('companion[contact][name]', inputDataForm2.contact.name);
    formData.append('companion[contact][phone]', inputDataForm2.contact.phone);
    if (inputDataForm2.obs) {
        formData.append('obs', inputDataForm2.obs);
    }

    formData.append('companion[travel_companion]', !inputDataForm2.travelCompanion ? '1' : '0');

    if (!inputDataForm2.travelCompanion) {
        return formData;
    }

    formData.append('travelCompanion[need_payment]', '0');
    formData.append('travelCompanion[name]', inputDataForm2.travelCompanion.name);
    formData.append('travelCompanion[last_name]', inputDataForm2.travelCompanion.last_name);
    formData.append('travelCompanion[badge_name]', inputDataForm2.travelCompanion.badge_name);
    formData.append('travelCompanion[gender]', inputDataForm2.travelCompanion.gender);
    formData.append('travelCompanion[nationality]', inputDataForm2.travelCompanion.nationality);
    formData.append('travelCompanion[phone]', inputDataForm2.travelCompanion.phone);
    formData.append('travelCompanion[email]', inputDataForm2.travelCompanion.email);
    formData.append('travelCompanion[cpf]', inputDataForm2.travelCompanion.cpf);
    formData.append('travelCompanion[birth_date]', parseDateToApi(inputDataForm2.travelCompanion.birth_date));
    formData.append('travelCompanion[identification_doc]', inputDataForm2.travelCompanion.identification_doc);
    formData.append('travelCompanion[doc_emission]', parseDateToApi(inputDataForm2.travelCompanion.doc_emission));
    formData.append('travelCompanion[doc_validity]', parseDateToApi(inputDataForm2.travelCompanion.doc_validity));
    formData.append('travelCompanion[doc_country]', inputDataForm2.travelCompanion.doc_country);
    formData.append('travelCompanion[passport_doc]', inputDataForm2.travelCompanion.passport_doc);
    formData.append('travelCompanion[birth_city]', inputDataForm2.travelCompanion.birth_city);
    formData.append('travelCompanion[zip_code]', inputDataForm2.travelCompanion.zip_code);
    formData.append('travelCompanion[state]', inputDataForm2.travelCompanion.state);
    formData.append('travelCompanion[city]', inputDataForm2.travelCompanion.city);
    formData.append('travelCompanion[address]', inputDataForm2.travelCompanion.address);
    formData.append('travelCompanion[country]', inputDataForm2.travelCompanion.country);
    formData.append('travelCompanion[polo_tshirt]', inputDataForm2.travelCompanion.polo_tshirt);
    formData.append('travelCompanion[shoe_size]', inputDataForm2.travelCompanion.shoe_size);
    formData.append('travelCompanion[address_complement]', inputDataForm2.travelCompanion.address_complement);

    formData.append('travelCompanion[attachments][0][type]', '1');
    formData.append('travelCompanion[attachments][0][document]', inputDataForm2.travelCompanion.rg_file);
    formData.append('travelCompanion[attachments][1][type]', '3');
    formData.append('travelCompanion[attachments][1][document]', inputDataForm2.travelCompanion.covid_file);
    formData.append('travelCompanion[attachments][2][type]', '2');
    formData.append('travelCompanion[attachments][2][document]', inputDataForm2.travelCompanion.passport_file);

    formData.append('travelCompanion[air_transport]', inputDataForm2.travelCompanion.enableFly ? '1' : '0');
    if (inputDataForm2.travelCompanion.enableFly) {
        formData.append('travelCompanion[transport][departure_airport]', inputDataForm2.travelCompanion.departure_airport);
        formData.append('travelCompanion[transport][return_airport]', inputDataForm2.travelCompanion.return_airport);
        formData.append('travelCompanion[transport][boarding_date]', parseDateToApi(inputDataForm2.travelCompanion.boarding_date));
        formData.append('travelCompanion[transport][return_date]', parseDateToApi(inputDataForm2.travelCompanion.return_date));
    }

    formData.append('travelCompanion[contact][type]', inputDataForm2.travelCompanion.contact.type);
    formData.append('travelCompanion[contact][name]', inputDataForm2.travelCompanion.contact.name);
    formData.append('travelCompanion[contact][phone]', inputDataForm2.travelCompanion.contact.phone);
    if (inputDataForm2.travelCompanion.obs) {
        formData.append('obs', inputDataForm2.travelCompanion.obs);
    }

    return formData;
}
