const country = [
  "Afghanistan", "South Africa", "Albania", "Germany", "Andorra", "Angola", "Antigua and Barbuda",
  "Saudi Arabia", "Algeria", "Argentina", "Armenia", "Australia", "Austria", "Azerbaijan", 
  "Bahamas", "Bangladesh", "Barbados", "Bahrain", "Belarus", "Belgium", "Belize", "Benin", 
  "Bhutan", "Bolivia", "Bosnia and Herzegovina", "Botswana", "Brazil", "Brunei", "Bulgaria", 
  "Burkina Faso", "Burundi", "Cape Verde", "Cambodia", "Cameroon", "Canada", "Qatar", 
  "Kazakhstan", "Chad", "Chile", "China", "Cyprus", "Colombia", "Comoros", "Congo-Brazzaville", 
  "Congo-Kinshasa", "North Korea", "South Korea", "Ivory Coast", "Costa Rica", 
  "Croatia", "Cuba", "Denmark", "Djibouti", "Dominica", "Egypt", "El Salvador", "United Arab Emirates", 
  "Ecuador", "Eritrea", "Slovakia", "Slovenia", "Spain", "United States", "Estonia", 
  "Eswatini", "Ethiopia", "Fiji", "Philippines", "Finland", "France", "Gabon", "Gambia", "Ghana", 
  "Georgia", "Grenada", "Greece", "Guatemala", "Guyana", "Guinea", "Equatorial Guinea", "Guinea-Bissau", 
  "Haiti", "Honduras", "Hungary", "Yemen", "Marshall Islands", "Solomon Islands", "India", 
  "Indonesia", "Iran", "Iraq", "Ireland", "Iceland", "Israel", "Italy", "Jamaica", "Japan", 
  "Jordan", "Kiribati", "Kosovo", "Kuwait", "Laos", "Lesotho", "Latvia", "Lebanon", "Liberia", 
  "Libya", "Liechtenstein", "Lithuania", "Luxembourg", "North Macedonia", "Madagascar", "Malaysia", 
  "Malawi", "Maldives", "Mali", "Malta", "Morocco", "Mauritius", "Mauritania", "Mexico", "Micronesia", 
  "Mozambique", "Moldova", "Monaco", "Mongolia", "Montenegro", "Myanmar", "Namibia", "Nauru", "Nepal", 
  "Nicaragua", "Niger", "Nigeria", "Norway", "New Zealand", "Oman", "Netherlands", "Palau", 
  "Palestine", "Panama", "Papua New Guinea", "Pakistan", "Paraguay", "Peru", "Poland", 
  "Portugal", "Qatar", "Kenya", "Kyrgyzstan", "United Kingdom", "Central African Republic", 
  "Dominican Republic", "Czech Republic", "Romania", "Rwanda", "Russia", "Samoa", "San Marino", 
  "Saint Lucia", "Saint Kitts and Nevis", "Sao Tome and Principe", "Saint Vincent and the Grenadines", 
  "Seychelles", "Senegal", "Sierra Leone", "Serbia", "Singapore", "Syria", "Somalia", "Sri Lanka", 
  "Eswatini", "Sudan", "South Sudan", "Sweden", "Switzerland", "Suriname", "Thailand", "Taiwan", 
  "Tajikistan", "Tanzania", "Timor-Leste", "Togo", "Tonga", "Trinidad and Tobago", "Tunisia", 
  "Turkmenistan", "Turkey", "Tuvalu", "Ukraine", "Uganda", "Uruguay", "Uzbekistan", "Vanuatu", 
  "Vatican", "Venezuela", "Vietnam", "Zambia", "Zimbabwe"
];

export const validCountryEN = (data) => country.includes(data);
