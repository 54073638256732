import {parseDateToApi} from "../../toolbox/parseDate";

export function makeRegisterForm1(contactId, inputData) {
    let formData = new FormData();
    formData.append('id', contactId);
    formData.append('type', '1');
    formData.append('need_payment', '0');
    formData.append('name', inputData.name);
    formData.append('last_name', inputData.last_name);
    formData.append('badge_name', inputData.badge_name);
    formData.append('gender', inputData.gender);
    formData.append('nationality', inputData.nationality);
    formData.append('phone', inputData.phone);
    formData.append('email', inputData.email);
    formData.append('cpf', inputData.cpf);
    formData.append('birth_date', parseDateToApi(inputData.birth_date));
    formData.append('identification_doc', inputData.identification_doc);
    formData.append('doc_emission', parseDateToApi(inputData.doc_emission));
    formData.append('doc_validity', parseDateToApi(inputData.doc_validity));
    formData.append('doc_country', inputData.doc_country);
    formData.append('birth_city', inputData.birth_city);
    formData.append('zip_code', inputData.zip_code);
    formData.append('state', inputData.state);
    formData.append('city', inputData.city);
    formData.append('address', inputData.address);
    formData.append('country', inputData.country);
    formData.append('polo_tshirt', inputData.polo_tshirt);
    formData.append('shoe_size', inputData.shoe_size);
    formData.append('address_complement', inputData.address_complement);
    if (inputData.food_restriction) {
        formData.append('food_restriction', inputData.food_restriction);
    }
    formData.append('attachments[0][type]', '1');
    formData.append('attachments[0][document]', inputData.rg_file);

    formData.append('air_transport', inputData.enableFly ? '1' : '0');
    if (inputData.enableFly) {
        formData.append('transport[departure_airport]', inputData.departure_airport);
        formData.append('transport[return_airport]', inputData.return_airport);
        formData.append('transport[boarding_date]', parseDateToApi(inputData.boarding_date));
        formData.append('transport[return_date]', parseDateToApi(inputData.return_date));
    }

    formData.append('contact[type]', inputData.contact.type);
    formData.append('contact[name]', inputData.contact.name);
    formData.append('contact[phone]', inputData.contact.phone);
    if (inputData.obs) {
        formData.append('obs', inputData.obs);
    }
    return formData;
}
