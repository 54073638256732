import VanillaToasts from 'vanillatoasts';


export const notification = {
    info(text, title = '') {
        VanillaToasts.create({
            title, text,
            type: 'info',
            timeout: 3000
        });
    },
    warning(text, title = '') {
        VanillaToasts.create({
            title, text,
            type: 'warning',
            timeout: 3000
        });
    },
    error(text, title = '') {
        VanillaToasts.create({
            title, text,
            type: 'error',
            timeout: 3000
        });
    }
};
